import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Layout = (props) => {
  const [networkStatus, setNetworkStatus] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    setNetworkStatus(window?.navigator?.onLine);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  // Handle no network
  const handleOnline = useCallback((event) => {
    setNetworkStatus(event.type === "online");
  });

  const handleOffline = useCallback((event) => {
    setNetworkStatus(event.type !== "offline");
  });

  return (
    <>
      {networkStatus ? (
        <div>{props.children}</div>
      ) : (
        <div className="outer_box no_internet_connection">
          <div className="nops_logo">
            <img src="/imgs/AditroLogistics_new_logo.png" alt="" />
          </div>
          <h4>{t("OOPS")}!</h4>
          <p>{t("INTERNET_CONNECTION_BROKEN")}</p>
        </div>
      )}
    </>
  );
};

export default Layout;
