import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import { isWHMUser } from "../../CommomFiles/globalFunctions";

const CommonHeader = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);

  const isSMSCarier = () => {
    return (
      userDetail &&
      Object.keys(userDetail).length > 0 &&
      userDetail?.groups?.includes("SMS_Carrier")
    );
  };
  const isSMSWHM = () => {
    return (
      userDetail && Object.keys(userDetail).length > 0 && isWHMUser(userDetail)
    );
  };

  const isSMSCustomer = () => {
    return (
      userDetail &&
      Object.keys(userDetail).length > 0 &&
      userDetail?.groups?.includes("SMS_Customer")
    );
  };

  return (
    <React.Fragment>
      <div className="dashboard_header">
        <Header user={userDetail?.user} />
        <div className="sub_menu">
          {isSMSCarier() && (
            <>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/slot-booking" ? "active" : ""
                }`}
                onClick={() => navigate("/slot-booking")}
              >
                {t("BOOK_A_SLOT_HEADING")}
              </button>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/carrier/view-booking"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/carrier/view-booking")}
              >
                {t("VIEW_BOOKINGS_HEADING")}
              </button>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/carrier/data" ? "active" : ""
                }`}
                onClick={() => navigate("/carrier/data")}
              >
                {t("MY_DATA")}
              </button>
            </>
          )}
          {isSMSWHM() && (
            <>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/whm/view-booking"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/whm/view-booking")}
              >
                {t("VIEW_BOOKINGS_HEADING")}
              </button>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/slot-planning" ? "active" : ""
                }`}
                onClick={() => navigate("/slot-planning")}
              >
                {t("SLOT_PLANNING_TITLE")}
              </button>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/whm/manage-master-data"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/whm/manage-master-data")}
              >
                {t("MASTER_DATA")}
              </button>
            </>
          )}
          {isSMSCustomer() && (
            <>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/customer/view-booking"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/customer/view-booking")}
              >
                {t("VIEW_BOOKINGS_HEADING")}
              </button>
              <button
                className={`sub_menu_links ${
                  window.location.pathname === "/customer/data" ? "active" : ""
                }`}
                onClick={() => navigate("/customer/data")}
              >
                {t("MY_DATA")}
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CommonHeader;
