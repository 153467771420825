import React, { useEffect, useState } from "react";
import { Auth, I18n, Amplify } from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyForgotPassword,
  AmplifyRequireNewPassword,
} from "@aws-amplify/ui-react";
import { authConfig, awsconfig } from "./config/aws-exports";
import Routing from "./Routing";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveUserData } from "./Store/Actions/userActions";
import Loader from "./components/Loader/Loader";
import Layout from "./components/Dashboard/Layout";
import { loginDict } from "./config/Login-page-trans";
import { useTranslation } from "react-i18next";
import Websocket from "./components/Notification/Websocket";
import { getWHMFacilities, isWHMUser } from "./CommomFiles/globalFunctions";

// Amplify configure for dev
Amplify.configure(awsconfig);
Auth.configure({ oauth: authConfig });
I18n.putVocabularies(loginDict);

const App = () => {
  const [user, setUser] = useState("");
  const [slot, setSlot] = useState("");
  const [isWHM, setIsWHM] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = useSelector((state) => state.common.localLang);
  const userDetail = useSelector((state) => state.user.userDetail);

  useEffect(() => {
    I18n.setLanguage(navigator.language);
    i18n.changeLanguage(lang);
    fetchUserDetail();
  }, []);

  useEffect(() => {
    setUser(userDetail?.user);
    setIsWHM(isWHMUser(userDetail));
  }, [userDetail]);

  const fetchUserDetail = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        const details = {
          user: user,
          groups: groups,
        };
        if (isWHMUser(details)) {
          setIsWHM(true);
          details["facilities"] = getWHMFacilities(groups);
        } else {
          setIsWHM(false);
        }
        dispatch(saveUserData(details));
      })
      .catch(() => {
        console.log("Not signed in");
        localStorage.clear();
      });
  };

  const loginData = (authState, data) => {
    console.log("login data", authState, data);
    if (data && authState === "signedin") {
      const groups =
        data.signInUserSession.accessToken.payload["cognito:groups"];
      const details = {
        user: data,
        groups: groups,
      };
      if (isWHMUser(details)) {
        setIsWHM(true);
        details["facilities"] = getWHMFacilities(groups);
      } else {
        setIsWHM(false);
      }
      console.log("details", details);
      dispatch(saveUserData(details));
    } else if (data && authState === "resettingpassword") {
      setUser(data);
    }
    if (authState) {
      setSlot(authState);
    }
  };

  const loginWithAzure = () => {
    Auth.federatedSignIn({ customProvider: "AditroLogin" });
  };

  return (
    <Layout>
      <AmplifyAuthenticator className="Authenticator">
        <div
          className="authPageAditroSms"
          slot="sign-in"
          style={{
            backgroundImage: `url('/imgs/loginImg.png')`,
          }}
        >
          {/* login page logo */}
          <img
            src="/imgs/AditroLogistics_new_logo.png"
            className="login_page_logo"
            alt="Aditro"
          />
          {slot === "resettingpassword" ? (
            <AmplifyRequireNewPassword
              headerText={t("REQUIRED_NEW_PASSWORD")}
              slot="require-new-password"
              user={user}
              handleAuthStateChange={(authState, data) =>
                loginData(authState, data)
              }
            />
          ) : slot === "forgotpassword" ? (
            <AmplifyForgotPassword
              headerText={t("FORGOT_PASSWORD")}
              slot="forgot-password"
              handleAuthStateChange={(authState, data) =>
                loginData(authState, data)
              }
            />
          ) : (
            <AmplifySignIn
              headerText={t("PAGE_TITLE")}
              hideSignUp
              handleAuthStateChange={(authState, data) =>
                loginData(authState, data)
              }
              className="amp_signin"
            >
              <h5 slot="header-subtitle" style={{ fontWeight: 500 }}>
                {t("ADITRO_EMP_LOGIN")}
              </h5>
              <div slot="federated-buttons">
                <button className="azur_login_btn" onClick={loginWithAzure}>
                  {t("ADITRO_LOGIN")}
                </button>
                <h5 className="login_message">{t("OTHERS_LOGIN")}</h5>
              </div>
            </AmplifySignIn>
          )}
        </div>
        <Loader />
        <Routing />
        <ToastContainer />
        {isWHM && <Websocket />}
      </AmplifyAuthenticator>
    </Layout>
  );
};

export default App;
