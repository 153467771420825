// All apis related to driver and customer would be here

import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

// get driver list
export const _getDriverList = (params, success, error) => {
  instance
    .get(endPoints.driverURL, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};

// get customer list
export const _getCustomerList = (params, success, error) => {
  instance
    .get(endPoints.customerURL, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};

export const _getCustomerBookings = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.slotBookingURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};
