import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  Body,
  Input,
  Dropdown,
  DropdownMenuItem,
} from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../CommomFiles/globalFunctions";
import { addMDCarrierFormSchema } from "../../../CommomFiles/FormsValidationSchema";
import { _modifyCarrierData, _getUserGroups } from "../../../Services/masterDataServices";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";
import { hideLoader, showLoader } from '../../../Store/Actions/commonAction';

const Status = ["Active", "Inactive"];

const EditMDCarriers = ({ close, booking, updateList }) => {
  const [isStatusDropdownOpen, setStatusDropdown] = useState(false);
  const [errors, setErrors] = useState(null);
  const [product, setProduct] = useState(booking);
  const [isGroupDropdownOpen, setGroupDropdown] = useState(false);
  const [userGroupOptions, setUserGroupOptions] = useState([""]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserGroup();

    if(product.groups === undefined){
      const _product = { ...product };
      _product[`${'groups'}`] = [];
      setProduct(_product);
    }
  }, []);

  const modifyBooking = () => {
    product["carrierStatus"] = product?.status;
    delete product["status"];
    dispatch(
      _modifyCarrierData(
        product,
        (res) => {
          const responseData = res.data;
          updateList(responseData[0]);
          showToast(t("EDITING_SUCCESSFUL"), "success");
          close();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const onInputChange = (val, key) => {
    const _product = { ...product };

    if (key === "groups" && _product[`${key}`] !== undefined? !(_product[`${key}`].includes(val)):false) {
      _product[`${key}`].push(val);
    }
    else if (key === "groups" && _product[`${key}`] !== undefined? (_product[`${key}`].includes(val)):false) {
      const index = (_product[`${key}`].indexOf(val));
      if (index > -1) { // only splice array when item is found
        (_product[`${key}`]).splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    else {
      _product[`${key}`] = val;
    }

    setProduct(_product);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await addMDCarrierFormSchema.isValid(product, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      modifyBooking();
    } else {
      // If form is not valid, check which fields are incorrect:
      addMDCarrierFormSchema
        .validate(product, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  //get list of groups
  const getUserGroup = (carrier) => {
    dispatch(showLoader());
    _getUserGroups(
      (res) => {
        if (res.data.length > 0 && res.data !== undefined) {
          res.data.forEach((i) => {
            setUserGroupOptions(userGroupOptions => userGroupOptions.concat(i.group_name));
          })
        }
        dispatch(hideLoader());
      },
      (err) => {
        dispatch(hideLoader());
      }
    )
  };

  return (
    <Dialog
      bottomButtons={
        <MasterDataAddButton title={"SAVE"} onClick={(e) => onSubmit(e)} />
      }
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={t("EDIT_CARRIER")}
      onClose={() => {
        close();
      }}
      style={{ width: "600px" }}
    >
      <Body as="div" className="carrier_input_container" size="Four">
        <Input
          type="text"
          label={t("CARRIER_ID")}
          value={product?.carrierId}
          onChange={(e) => onInputChange(e.target.value, "carrierId")}
          required
          readOnly
          className="carrier_disable_input_control"
          message={errors && t(errors["carrierId"])}
          isInvalid={errors && "carrierId" in errors}
        />

        <Input
          type="text"
          label={t("CARRIER_NAME")}
          value={product?.carrierName}
          onChange={(e) => onInputChange(e.target.value, "carrierName")}
          required
          className="carrier_input_control"
          message={errors && t(errors["carrierName"])}
          isInvalid={errors && "carrierName" in errors}
        />
        <Input
          type="text"
          label={t("CARRIER_CODE")}
          value={product?.carrierCode}
          onChange={(e) => onInputChange(e.target.value, "carrierCode")}
          required
          className="carrier_input_control"
          message={errors && t(errors["carrierCode"])}
          isInvalid={errors && "carrierCode" in errors}
        />
        <div className="m_d_carrier_dropdown_container">
          <Dropdown
            isOpen={isStatusDropdownOpen}
            title={product?.status}
            iconColor="#73808C"
            label={t("STATUS")}
            menuAriaLabel="status"
            className="m_d_carrier_dropdown"
            onSelect={(e) => onInputChange(e, "status")}
            onToggle={() =>
              setStatusDropdown((isStatusDropdownOpen) => !isStatusDropdownOpen)
            }
            isValid={!(errors && "status" in errors)}
            message={errors && t(errors["status"])}
            overflowMenuContainerDirection="right"
          >
            {Status?.map((item, index) => {
              return (
                <DropdownMenuItem
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={product?.status === item}
                  key={item}
                  className="m_d_carrier_dropdown_item"
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div>
        <Input
          type="text"
          label={t("STREET_NAME")}
          value={product?.streetName}
          onChange={(e) => onInputChange(e.target.value, "streetName")}
          required
          className="carrier_input_control"
          message={errors && t(errors["streetName"])}
          isInvalid={errors && "streetName" in errors}
        />
        <Input
          type="text"
          label={t("STREET_NUMBER")}
          value={product?.streetNo}
          onChange={(e) => onInputChange(e.target.value, "streetNo")}
          required
          className="carrier_input_control"
          message={errors && t(errors["streetNo"])}
          isInvalid={errors && "streetNo" in errors}
        />
        <Input
          type="text"
          label={t("POST_CODE")}
          value={product?.postCode}
          onChange={(e) => onInputChange(e.target.value, "postCode")}
          required
          className="carrier_input_control"
          message={errors && t(errors["postCode"])}
          isInvalid={errors && "postCode" in errors}
        />
        <Input
          type="text"
          label={t("CITY")}
          value={product?.city}
          onChange={(e) => onInputChange(e.target.value, "city")}
          required
          className="carrier_input_control"
          message={errors && t(errors["city"])}
          isInvalid={errors && "city" in errors}
        />
        <Input
          type="text"
          label={t("SPOC_NAME")}
          value={product?.spocName}
          onChange={(e) => onInputChange(e.target.value, "spocName")}
          required
          className="carrier_input_control"
          message={errors && t(errors["spocName"])}
          isInvalid={errors && "spocName" in errors}
        />
        <Input
          type="text"
          label={t("SPOC_EMAIL")}
          value={product?.spocEmail}
          onChange={(e) => onInputChange(e.target.value, "spocEmail")}
          required
          className="carrier_input_control"
          message={errors && t(errors["spocEmail"])}
          isInvalid={errors && "spocEmail" in errors}
        />
        <Input
          type="text"
          label={t("SPOC_PHONE_NUMBER")}
          value={product?.spocPhNo}
          onChange={(e) => onInputChange(e.target.value, "spocPhNo")}
          required
          className="carrier_input_control"
          message={errors && t(errors["spocPhNo"])}
          isInvalid={errors && "spocPhNo" in errors}
        />
        <div className="m_d_carrier_dropdown_container drpGroup">
          <Dropdown
            isOpen={isGroupDropdownOpen}
            // title={product?.groups.toString().replace('SMS', " SMS")}
            title={product?.groups}
            iconColor="#73808C"
            label={t("GROUP")}
            menuAriaLabel="groups"
            className="m_d_carrier_dropdown"
            onSelect={(e) => onInputChange(e, "groups")}
            onToggle={() =>
              setGroupDropdown((isGroupDropdownOpen) => !isGroupDropdownOpen)
            }
            isValid={!(errors && "groups" in errors)}
            message={errors && t(errors["groups"])}
            overflowMenuContainerDirection="right"
            isMulti
          >
            {userGroupOptions?.map((item, index) => {
              return (
                <DropdownMenuItem
                  id={index}
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={product?.groups !== undefined ? product?.groups.includes(item) : null}
                  key={item}
                  className="m_d_carrier_dropdown_item"
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div>
      </Body>
    </Dialog>
  );
};

export default EditMDCarriers;
