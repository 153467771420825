import React, { useState } from "react";
import { Input, Button, Dialog, Body } from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { shiftingSlotsFormSchema } from "../../CommomFiles/FormsValidationSchema";
import { status } from "../../CommomFiles/slotPlanningFunction";
import { _shiftingSlots } from "../../Services/slotServices";
import { useDispatch } from "react-redux";
const ShiftingSlots = (props) => {
  const {
    closeModal,
    checkBoxRowId,
    uniqueId,
    checkBoxFlag,
    inboundSlotsData,
    activeDate,
    selectedCellData,
    getShiftingSuccess,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [shiftingDetails, setShiftingDetails] = useState({
    bookingId: null,
    assignedDockId: null,
    action: "SHIFT_SLOT",
    dockNo: "",
    slots: [],
  });
  const [errors, setErrors] = useState(null);

  // Handling onSubmit of form
  const onSubmit = async (event) => {
    event.preventDefault();

    const isFormValid = await shiftingSlotsFormSchema.isValid(shiftingDetails, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      generatingShiftingSlotJson();
    } else {
      // If form is not valid, check which fields are incorrect:
      shiftingSlotsFormSchema
        .validate(shiftingDetails, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };
  // Handling input changes
  const handleInputChange = (key, value) => {
    const form = { ...shiftingDetails };
    form[key] = value;
    setShiftingDetails(form);
    setErrors(null);
  };

  // Generating Json for shifting slots
  const generatingShiftingSlotJson = () => {
    let row = null;
    if (checkBoxFlag === true) {
      row = checkBoxRowId;
    } else {
      row = uniqueId;
    }
    let shiftingSlotJson = JSON.parse(JSON.stringify(shiftingDetails));
    const dockId = inboundSlotsData[row].dockId;
    const dockNo = inboundSlotsData[row].dock;
    const slotsArray = [];

    shiftingSlotJson.bookingId = parseInt(shiftingSlotJson.bookingId);
    shiftingSlotJson.assignedDockId = dockId;
    shiftingSlotJson.dockNo = dockNo;

    const selectedDock = inboundSlotsData[row];
    if (checkBoxFlag === true) {
      for (let sl in selectedDock) {
        if (sl !== "dock" && sl !== "dockId" && sl !== "break" && sl !== "id") {
          console.log("Slots", sl);
          if (selectedDock[sl].status === status[0]) {
            const singleSlot = {
              fromTime: activeDate + " " + selectedDock[sl].from,
              toTime: activeDate + " " + selectedDock[sl].to,
            };
            // console.log("Single slot", prebookDock[allKeys[sl]]);
            slotsArray.push(singleSlot);
          }
        }
      }
      //   return false;
    } else {
      for (let slot = 0; slot < selectedCellData.length; slot++) {
        const singleSlot = {
          fromTime:
            activeDate + " " + selectedDock[selectedCellData[slot]].from,
          toTime: activeDate + " " + selectedDock[selectedCellData[slot]].to,
        };
        slotsArray.push(singleSlot);
      }
    }

    shiftingSlotJson.slots = slotsArray;
    console.log("Shifting Slots Json", shiftingSlotJson);
    shiftingSlots(shiftingSlotJson);
  };

  const shiftingSlots = (shiftingSlotJson) => {
    console.log("Shifting Slots Json", shiftingSlotJson);
    dispatch(
      _shiftingSlots(
        shiftingSlotJson,
        (res) => {
          console.log("Response of post shifting api -->", res);
          closeModal();
          getShiftingSuccess();
        },
        (err) => {
          console.log(err);
          // getPrebookingSuccess(false);
        }
      )
    );
  };

  return (
    <div className="shiftingSlotsDialog">
      <Dialog
        closeText="Close dialog"
        disableOutsideClick={true}
        headerContent={
          <div style={{ display: "flex", alignItems: "center" }}>
            {t("SHIFTING_SLOT_FORM_TITLE")}
          </div>
        }
        onClose={() => {
          closeModal();
        }}
      >
        <Body as="div" size="Four">
          <div className="shiftingSlotsDialogBody">
            <>
              <form onSubmit={onSubmit}>
                <div className="shiftingSlotsRow">
                  <div className="bookingIdDiv">
                    <Input
                      id="input"
                      label={t("BOOKING_ID_TITLE")}
                      message={errors && t(errors["bookingId"])}
                      onChange={(e) =>
                        handleInputChange("bookingId", e.target.value)
                      }
                      type="text"
                      value={shiftingDetails.bookingId}
                      className="bookingId"
                      isInvalid={errors && "bookingId" in errors}
                    />
                  </div>
                </div>
                <div className="submit_btn_box">
                  <Button
                    className="slot_detail_submit_btn"
                    iconPosition="right"
                    type="submit"
                    // disabled={submitDisable}
                    size="sm"
                    onClick={() => {
                      // console.log("Booking details", bookingDetail);
                    }}
                  >
                    {t("PREBOOK_SUBMIT")}
                    <ArrowForwardRoundedIcon
                      style={{ marginLeft: "10px", fontSize: "20px" }}
                    />
                  </Button>
                </div>
              </form>
            </>
          </div>
        </Body>
      </Dialog>
    </div>
  );
};

export default ShiftingSlots;
