import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { showToast } from "../../CommomFiles/globalFunctions";
import i18n from "i18next";
import { saveEventSource } from "../../Store/Actions/commonAction";
import { useDispatch, useSelector } from "react-redux";

// Variables
const notificationTime = 8000;
export const typesOfPush = ["Booking", "Planning"];
const pushStatus = [
  "CHECK_IN_EARLY",
  "CHECKED_IN",
  "CHECK_IN_DELAYED",
  "DOCK_UNAVAILABLE",
];

const Websocket = () => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);

  const [keepActive, setKeepActive] = useState(true);

  //   const getIdToken = async () => {
  //     let token = await Auth.currentSession().then((res) =>
  //       res.getIdToken().getJwtToken()
  //     );
  //     return token;
  //   };

  useEffect(() => {
    let ws = null;
    if (userDetail?.facilities?.length) {
      ws = new WebSocket(
        `${
          process.env.REACT_APP_WEBSOCKET_URL
        }?facilities=${userDetail?.facilities?.toString()}`
      );

      // open connection
      ws.addEventListener("open", (e) => {
        console.log("Connection opened!");
        dispatch(saveEventSource(ws));
        //   ws.send(userDetail);
      });

      // Listen for messages
      ws.addEventListener("message", (event) => {
        console.log("WS Message from server ", event.data);
        const data = JSON.parse(event.data);
        // facility filter adding for whm notifications
        if (userDetail?.facilities?.includes(data?.facilityId.toString())) {
          if (data?.typesOfPush === typesOfPush[0]) {
            // if booking type notification
            console.log("Bookingdata" + data);
            //show push only for 4 status
            if (pushStatus.includes(data?.status)) {
              if (data?.status === "CHECK_IN_DELAYED") {
                showToast(
                  i18n.t("BOOKING_ID") +
                    ": " +
                    `${data?.bookingId}` +
                    " " +
                    i18n.t("WAITING_AT_GATE_FOR_DOCK_ASSIGNMENT"),
                  "",
                  notificationTime
                );
              } else {
                showToast(
                  i18n.t("BOOKING_ID") +
                    " " +
                    `${data?.bookingId}` +
                    " " +
                    i18n.t("IS_NOW") +
                    " " +
                    i18n.t(`${data?.status}`),
                  "",
                  notificationTime
                );
              }
            }
          } else if (data?.typesOfPush === typesOfPush[1]) {
            // if planning type notification
            console.log("Progressdata" + data);

            if (data?.planningfailureDays.length > 0) {
              showToast(
                `${data?.successPercent}` +
                  "% " +
                  i18n.t("PLANNING_IS_COMPLETED") +
                  "but" +
                  i18n.t("PLANNING_FAILED_FOR") +
                  `${data?.planningfailureDays.toString()}`,
                "",
                notificationTime
              );
            } else {
              if (data?.successPercent === "100") {
                showToast(
                  i18n.t("SLOT_PLANNING_SUCCESSFUL"),
                  "",
                  notificationTime
                );
              } else {
                showToast(
                  `${data?.successPercent}` +
                    "% " +
                    i18n.t("PLANNING_IS_COMPLETED"),
                  "",
                  notificationTime
                );
              }
            }
          }
        }
      });

      // When connection get closed
      ws.onclose = (e) => {
        console.log("WS close", e.code, e.reason);
        if (e.code === 1001) setKeepActive((keepActive) => !keepActive);
      };

      // Listen for error
      ws.onerror = (e) => {
        // an error occurred
        console.log("WS On error", e);
      };
    }

    return () => {
      // close on unmount
      if (ws) ws.close();
    };
  }, [userDetail, keepActive]);

  return null;
};

export default Websocket;
