// All apis related to dockData will be here

import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

export const _getDockData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.slotBookingURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};
