import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="page_404">
      <h2 className="tt">404</h2>
      <h4 className="not_found">{t("PAGE_NOT_FOUND")}</h4>
      <p>{t("REQUESTED_PAGE_NOT_FOUND")}</p>
      <Link className="home_btn" to="/">
        <span>{t("GOTOHOME")}</span>
      </Link>
    </div>
  );
};
export default PageNotFound;
