import React, { useState } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useTranslation } from "react-i18next";
import DashboardContainer from "../../components/Dashboard/DashboardContainer";
import PageSublink from "../../components/Dashboard/PageSublink";
import MDCarrier from "./masterDataCarrier/MDCarrier";
import MDDriver from "./masterDataDriver/MDDriver";
import MDFacility from "./masterDataFacility/MDFacility";
import MDCustomer from "./masterDataCustomer/MDCustomer";
import { isAdmin } from "../../CommomFiles/globalFunctions";
import { useSelector } from "react-redux";

const tabs = ["FACILITY", "CARRIER", "DRIVER", "CUSTOMER"];

const ManageMasterData = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const userDetail = useSelector((state) => state.user.userDetail);
  return (
    <DashboardContainer>
      <div className="manage_master_data_page">
        <div>
          {isAdmin(userDetail) ? (
            <PageSublink pageName={t("MANAGE_MASTER_DATA_TITLE")} />
          ) : (
            <PageSublink pageName={t("MASTER_DATA")} />
          )}

          {isAdmin(userDetail) ? (
            <h1 className="manage_master_data_title">
              {t("MANAGE_MASTER_DATA_TITLE")}
            </h1>
          ) : (
            <h1 className="manage_master_data_title">{t("MASTER_DATA")}</h1>
          )}
        </div>
        {activeTab && (
          <div className="sub_menu_master_data">
            <button
              className={`sub_menu_links_master_data ${
                activeTab === tabs[0] ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab(tabs[0]);
              }}
            >
              {t("FACILITY")}
            </button>
            <button
              className={`sub_menu_links_master_data ${
                activeTab === tabs[1] ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab(tabs[1]);
              }}
            >
              {t("CARRIER")}
            </button>
            <button
              className={`sub_menu_links_master_data ${
                activeTab === tabs[2] ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab(tabs[2]);
              }}
            >
              {t("DRIVER")}
            </button>
            <button
              className={`sub_menu_links_master_data ${
                activeTab === tabs[3] ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab(tabs[3]);
              }}
            >
              {t("CUSTOMER")}
            </button>
          </div>
        )}
        {activeTab === tabs[0] && <MDFacility />}
        {activeTab === tabs[1] && <MDCarrier />}
        {activeTab === tabs[2] && <MDDriver />}
        {activeTab === tabs[3] && <MDCustomer />}
      </div>
    </DashboardContainer>
  );
};

export default ManageMasterData;
