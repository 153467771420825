import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import { useQrious } from "react-qrious";
import { _getFacilityList } from "../../Services/slotServices";
import { _getFacilityData } from "../../Services/facilityServices";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const GeneratePdf = forwardRef((props, ref) => {
  const { rowData, hidePdf } = props;

  const [data, setData] = useState(null);
  const [adress, setAdress] = useState(null);
  const [allData, setAllData] = useState(null);
  const [value, setValue] = useState(null);
  const [dataUrl, _qrious] = useQrious({ value });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bookingId = String(rowData?.bookingId);
  const numberOfPallets = String(rowData?.numberOfPallets);

  useEffect(() => {
    setValue(bookingId);
    setData(rowData);
    if (rowData?.facilityId) getFacility();
  }, [rowData?.bookingId]);

  useEffect(() => {
    if (adress) printDocument();
  }, [adress]);

  const getFacility = () => {
    dispatch(
      _getFacilityData(
        { facilityId: rowData?.facilityId },
        (res) => {
          setAllData(String(res.data[0].facilityId));
          setAdress(
            String(
              res.data[0].siteName +
                ", " +
                "\n" +
                "\n" +
                res.data[0].streetNo +
                ", " +
                res.data[0].streetName +
                ", " +
                "\n" +
                res.data[0].city +
                "."
            )
          );
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const printDocument = () => {
    var img1 = new Image();
    img1.src = "/imgs/newLogo.png";
    const input = document.getElementById("divToOfferInfo");
    const pdf = new jsPDF();
    if (pdf) {
      domtoimage.toPng(input).then((imgData) => {
        //Header
        pdf.setHeaderFunction(pdf.addImage(img1, "PNG", 15, 4, 55, 20));
        pdf.setFontSize(18);
        pdf.text(77, 13, t("ADITRO_LOGISTICS_SLOT_MANAGEMENT_SYSTEM"));
        //changing font size
        pdf.setFontSize(14);
        pdf.text(125, 21, t("BOOKING_CONFIRMATION_RECEIPT"));

        //Header Line
        pdf.line(15, 27, 195, 27);

        //Body
        //pdf.setFont("Montserrat");
        pdf.setFontSize(15);
        pdf.text(15, 38, t("BOOKING_DETAILS"));
        pdf.setFontSize(10);
        pdf.text(15, 48, t("BOOKING_ID"));
        pdf.text(74, 48, ":");
        pdf.text(80, 48, bookingId ? bookingId : " ");
        pdf.text(15, 55, t("ETD"));
        pdf.text(74, 55, ":");
        pdf.text(80, 55, rowData?.originETD ? rowData?.originETD : " ");
        pdf.text(15, 62, t("EXPECTED_UNLOADING_DATE"));
        pdf.text(74, 62, ":");
        pdf.text(80, 62, rowData?.ETA ? rowData?.ETA : " ");
        // pdf.text(15, 69, t("Expected unloading time"));
        // pdf.text(74, 69, ":");
        // pdf.text(80, 69, rowData.expected_time ? rowData.expected_time : " ");
        pdf.text(15, 69, t("DRIVER_NAME"));
        pdf.text(74, 69, ":");
        pdf.text(
          80,
          69,
          rowData?.driverDetails?.driverName
            ? rowData?.driverDetails?.driverName
            : " "
        );
        pdf.text(15, 76, t("LICENSE_NUMBER"));
        pdf.text(74, 76, ":");
        pdf.text(
          80,
          76,
          rowData?.driverDetails?.drivingLicenseNo
            ? rowData?.driverDetails?.drivingLicenseNo
            : " "
        );
        pdf.text(15, 83, t("VEHICLE_PLATE_NUMBER"));
        pdf.text(74, 83, ":");
        pdf.text(
          80,
          83,
          rowData?.vehicleLicensePlateNumber
            ? rowData?.vehicleLicensePlateNumber
            : " "
        );
        pdf.text(15, 90, t("PO_REF"));
        pdf.text(74, 90, ":");
        pdf.text(80, 90, rowData?.PORef ? rowData?.PORef : " ");
        pdf.text(15, 97, t("SHIPPER_INVOICE_REF"));
        pdf.text(74, 97, ":");
        pdf.text(
          80,
          97,
          rowData?.shipperInvoiceRef ? rowData?.shipperInvoiceRef : "-"
        );
        pdf.text(15, 104, t("CUSTOMER"));
        pdf.text(74, 104, ":");
        pdf.text(
          80,
          104,
          rowData?.customerDetails?.customerName
            ? rowData?.customerDetails.customerName
            : " "
        );
        pdf.text(15, 111, t("NO_OF_PALLETS"));
        pdf.text(74, 111, ":");
        pdf.text(80, 111, numberOfPallets ? numberOfPallets : " ");
        pdf.text(15, 118, t("Status"));
        pdf.text(74, 118, ":");
        pdf.text(
          80,
          118,
          rowData?.shipmentStatus ? rowData?.shipmentStatus : " "
        );

        pdf.setFontSize(15);
        pdf.text(125, 38, t("FACILITY_DETAILS"));
        pdf.setFontSize(10);
        pdf.text(125, 48, adress);

        pdf.setFontSize(15);
        pdf.text(125, 74, t("CUSTOMER_SUPPORT"));
        pdf.setFontSize(10);
        pdf.setFontSize(10);
        pdf.text(125, 83, t("PHONE_NO"));
        pdf.text(140, 83, " :");
        pdf.text(144, 83, "+46 36 362200");

        pdf.text(125, 88, t("EMAIL"));
        pdf.text(140, 88, " :");
        pdf.text(144, 88, "Support@aditrologistics.com");

        //lines

        pdf.line(195, 65, 125, 65);
        pdf.line(15, 141, 195, 141);

        //QR code
        pdf.setFontSize(13);
        pdf.text(87, 150, t("QR_CODE"));
        pdf.addImage(dataUrl, "PNG", 72, 154, 50, 50);
        pdf.text(90, 210, bookingId);

        pdf.save(bookingId + ".pdf");
      });
    }
    hidePdf?.();
  };

  return <div id="divToOfferInfo" />;
});

export default GeneratePdf;
