import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import DashboardContainer from "../../components/Dashboard/DashboardContainer";
import { Calendar } from "primereact/calendar";
import {
  _getCarrierBookings,
  _modifyCarrierBookings,
} from "../../Services/carrierServices";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenu } from "primereact/contextmenu";
import { formateDate, showToast } from "../../CommomFiles/globalFunctions";
import { Dialog } from "primereact/dialog";
import EditBooking from "../../components/Bookings/EditBooking";
import { DATEFORMAT, DATETIMEFORMAT } from "../../config/constant";
import GeneratePdf from "../../components/QRcode/GeneratePdf";
import PageSublink from "../../components/Dashboard/PageSublink";

const status = [
  "Booked",
  "Cancelled",
  "Checked In",
  "Delayed Check In",
  "Delayed",
  "Departed",
  "Dock Unavailable",
  "Released",
];

const ViewBooking = () => {
  const [bookings, setBookings] = useState(null);
  const [cancelProductsDialog, setCancelProductsDialog] = useState(false);
  const [editProductsDialog, setEditProductsDialog] = useState(false);
  const [departProductsDialog, setDepartProductsDialog] = useState(false);
  const [warningDialog, setWarningDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [product, setProduct] = useState();
  const [pdfData, setPdfData] = useState(null);
  const childRef = useRef();

  const cm = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);
  let menuModel = [];

  if (selectedProduct != null) {
    switch (selectedProduct.shipmentStatus) {
      case "Departed":
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
          {
            label: t("CANCEL_BOOKING"),
            icon: "pi pi-fw pi-times-circle",
            command: () => confirmCancelSelected(selectedProduct),
          },
        ];
        break;
      case "Delayed":
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
          {
            label: t("CANCEL_BOOKING"),
            icon: "pi pi-fw pi-times-circle",
            command: () => confirmCancelSelected(selectedProduct),
          },
        ];
        break;
      case "Booked":
        if (moment().diff(selectedProduct?.originETD) < 0) {
          menuModel = [
            {
              label: t("EDIT_BOOKING"),
              icon: "pi pi-fw pi-user-edit",
              command: () => confirmEditSelected(selectedProduct),
            },
            {
              label: t("DOWNLOAD_PDF"),
              icon: "pi pi-fw pi-file-pdf",
              command: () => createPDFData(selectedProduct),
            },
            {
              label: t("CANCEL_BOOKING"),
              icon: "pi pi-fw pi-times-circle",
              command: () => confirmCancelSelected(selectedProduct),
            },
          ];
        } else {
          menuModel = [
            {
              label: t("EDIT_BOOKING"),
              icon: "pi pi-fw pi-user-edit",
              command: () => confirmEditSelected(selectedProduct),
            },
            {
              label: t("DOWNLOAD_PDF"),
              icon: "pi pi-fw pi-file-pdf",
              command: () => createPDFData(selectedProduct),
            },
            {
              label: t("CANCEL_BOOKING"),
              icon: "pi pi-fw pi-times-circle",
              command: () => confirmCancelSelected(selectedProduct),
            },
            {
              label: t("DEPARTED"),
              icon: "pi pi-fw pi-check",
              command: () => confirmDepartSelected(selectedProduct),
            },
          ];
        }
        break;
      default:
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
        ];
        break;
    }
  }

  useEffect(() => {
    getCarrierBookings();
  }, []);

  const getCarrierBookings = () => {
    dispatch(
      _getCarrierBookings(
        { carrierId: userDetail?.user?.username },
        (res) => {
          const updatedData = res.data.map((product) => {
            product["originETD"] = moment(
              product["originETD"],
              DATEFORMAT
            ).toDate();
            product["ETA"] = moment(product["ETA"], DATETIMEFORMAT).toDate();
            return product;
          });
          setBookings(updatedData);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const cancelBooking = (data) => {
    dispatch(
      _modifyCarrierBookings(
        {
          bookingId: data.bookingId,
          action: "MODIFY_BOOKING",
          shipmentStatus: "CANCELLED",
        },
        (res) => {
          const responseData = res.data;
          const index = bookings.findIndex(
            (item) => item.bookingId === responseData[0].bookingId
          );
          const tempArray = [...bookings];
          tempArray.splice(index, 1);
          responseData[0]["originETD"] = moment(
            responseData[0]["originETD"],
            DATEFORMAT
          ).toDate();

          responseData[0]["ETA"] = moment(
            responseData[0]["ETA"],
            DATETIMEFORMAT
          ).toDate();
          tempArray.splice(index, 1, responseData[0]);
          setBookings(tempArray);
          showToast(t("BOOKING_CANCELLED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setCancelProductsDialog(false);
  };

  const departBooking = (data) => {
    dispatch(
      _modifyCarrierBookings(
        {
          bookingId: data.bookingId,
          action: "MODIFY_BOOKING",
          shipmentStatus: "DEPARTED",
        },
        (res) => {
          const responseData = res.data;
          const index = bookings.findIndex(
            (item) => item.bookingId === responseData[0].bookingId
          );
          const tempArray = [...bookings];

          responseData[0]["originETD"] = moment(
            responseData[0]["originETD"],
            DATEFORMAT
          ).toDate();

          responseData[0]["ETA"] = moment(
            responseData[0]["ETA"],
            DATETIMEFORMAT
          ).toDate();
          tempArray.splice(index, 1, responseData[0]);
          setBookings(tempArray);
          showToast(t("DEPARTED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setDepartProductsDialog(false);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.shipmentStatus === "CHECKED_IN") {
      rowData.shipmentStatus = "Checked In";
    } else if (rowData.shipmentStatus === "BOOKED") {
      rowData.shipmentStatus = "Booked";
    } else if (rowData.shipmentStatus === "DEPARTED") {
      rowData.shipmentStatus = "Departed";
    } else if (rowData.shipmentStatus === "RELEASED") {
      rowData.shipmentStatus = "Released";
    } else if (rowData.shipmentStatus === "CANCELLED") {
      rowData.shipmentStatus = "Cancelled";
    } else if (rowData.shipmentStatus === "DELAYED") {
      rowData.shipmentStatus = "Delayed";
    } else if (rowData.shipmentStatus === "DOCK_UNAVAILABLE") {
      rowData.shipmentStatus = "Dock Unavailable";
    } else if (rowData.shipmentStatus === "CHECK_IN_DELAYED") {
      rowData.shipmentStatus = "Delayed Check In";
    }
    const statusClassName = classNames({
      booked: rowData.shipmentStatus === "Booked",
      departed: rowData.shipmentStatus === "Departed",
      delayed: rowData.shipmentStatus === "Delayed",
      checked_In: rowData.shipmentStatus === "Checked In",
      completed: rowData.shipmentStatus === "Released",
      cancelled: rowData.shipmentStatus === "Cancelled",
      dock_Unavailable: rowData.shipmentStatus === "Dock Unavailable",
      delayed_Check_In: rowData.shipmentStatus === "Delayed Check In",
    });

    return <div className={statusClassName}>{rowData.shipmentStatus}</div>;
  };

  const confirmCancelSelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "CANCELLED") {
      setWarningDialog(true);
    } else {
      setCancelProductsDialog(true);
    }
  };

  const confirmDepartSelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "DEPARTED") {
      setWarningDialog(true);
    } else if (product.shipmentStatus === "CANCELLED") {
      setWarningDialog(true);
    } else {
      setDepartProductsDialog(true);
    }
  };

  const confirmEditSelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "DEPARTED") {
      setWarningDialog(true);
    } else {
      setEditProductsDialog(true);
    }
  };

  const updateBookingList = (data) => {
    const index = bookings.findIndex(
      (item) => item.bookingId === data.bookingId
    );
    const tempArray = [...bookings];
    tempArray.splice(index, 1, data);
    setBookings(tempArray);
  };
  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder={DATEFORMAT}
        mask="99/99/9999"
      />
    );
  };

  const timeFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={DATEFORMAT}
        dateFormat="yy-mm-dd"
      />
    );
  };
  const ETDBodyTemplate = (rowData) => {
    return formatDate(rowData.originETD);
  };
  const ETABodyTemplate = (rowData) => {
    return formatDateandTime(rowData.ETA);
  };
  const formatDateandTime = (value) => {
    return moment(value).format(DATETIMEFORMAT);
  };

  const formatDate = (value) => {
    return moment(value).format(DATEFORMAT);
    // return value.toLocaleString("en-US", {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    // });
  };

  const createPDFData = (selectedProduct) => {
    const singleRow = { ...selectedProduct };
    singleRow["originETD"] = formateDate(singleRow["originETD"]);
    singleRow["ETA"] = moment(singleRow["ETA"]).format(DATETIMEFORMAT);
    setPdfData(singleRow);
  };

  return (
    <DashboardContainer>
      <div>
        {pdfData && (
          <GeneratePdf
            rowData={pdfData}
            ref={childRef}
            hidePdf={() => setPdfData(null)}
          />
        )}
        <ContextMenu
          model={menuModel}
          ref={cm}
          onHide={() => setSelectedProduct(null)}
        />
        <PageSublink pageName={t("VIEW_BOOKINGS_TITLE")} />
        <h1 className="book_slot_title">{t("VIEW_BOOKINGS_TITLE")}</h1>
        <div className="box">
          <div>
            <DataTable
              value={bookings}
              stripedRows
              paginator={true}
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              contextMenuSelection={selectedProduct}
              onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
              onContextMenu={(e) => cm.current.show(e.originalEvent)}
              rowHover
              responsiveLayout="scroll"
            >
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("BOOKING_ID")}
                field="bookingId"
                header={t("BOOKING_ID")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("PO_REF")}
                field="PORef"
                header={t("PO_REF")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("SHIPPER_INVOICE_REF")}
                field="shipperInvoiceRef"
                header={t("SHIPPER_INVOICE_REF")}
                body={(e) => {
                  return (
                    <span>
                      {e?.shipperInvoiceRef ? e?.shipperInvoiceRef : "-"}
                    </span>
                  );
                }}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("CUSTOMER_NAME")}
                field="customerDetails.customerName"
                header={t("CUSTOMER_NAME")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("VEHICLE_LICENSE_PLATE_NUMBER")}
                field="vehicleLicensePlateNumber"
                header={t("VEHICLE_LICENSE_PLATE_NUMBER")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("NUMBER_OF_PALLETS_UNITS")}
                field="numberOfPallets"
                header={t("NUMBER_OF_PALLETS_UNITS")}
              ></Column>
              <Column
                field="shipmentStatus"
                header={t("STATUS")}
                filterPlaceholder={t("STATUS")}
                filter
                showAddButton={false}
                showFilterOperator={false}
                showFilterMenuOptions={false}
                filterElement={statusRowFilterTemplate}
                body={statusBodyTemplate}
              ></Column>
              <Column
                filter
                filterElement={timeFilterTemplate}
                body={ETABodyTemplate}
                field="ETA"
                dataType="date"
                showAddButton={false}
                showFilterOperator={false}
                filterField="ETA"
                header={t("EXPECTED_DATE_AND_TIME")}
                sortable
                className="ETA_column"
              ></Column>
              <Column
                body={(e) => {
                  return (
                    <span>
                      {e?.ATA
                        ? moment(e.ATA, DATETIMEFORMAT).format("HH:mm")
                        : "-"}
                    </span>
                  );
                }}
                field="ATA"
                header={t("TIME_OF_ARRIVAL")}
                sortable
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("DRIVER_NAME")}
                field="driverDetails.driverName"
                header={t("DRIVER_NAME")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("LICENSE_NUMBER")}
                field="driverDetails.drivingLicenseNo"
                header={t("LICENSE_NUMBER")}
              ></Column>
              <Column
                sortable
                showAddButton={false}
                showFilterOperator={false}
                field="originETD"
                dataType="date"
                filterField="originETD"
                body={ETDBodyTemplate}
                filter
                filterElement={dateFilterTemplate}
                header={t("ETD")}
                className="ETD_column"
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("FACILITY_NAME")}
                field="siteName"
                header={t("FACILITY_NAME")}
              ></Column>
            </DataTable>
          </div>
        </div>
        <Dialog
          visible={cancelProductsDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setCancelProductsDialog(false)}
        >
          <div>
            <span style={{ color: "red", fontSize: "18px" }}>
              {t("ARE_YOU_SURE_CANCEL")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setCancelProductsDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => cancelBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={departProductsDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setDepartProductsDialog(false)}
        >
          <div>
            <span
              style={{ color: "red", fontSize: "18px", marginLeft: "110px" }}
            >
              {t("ARE_YOU_SURE_DEPART")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setDepartProductsDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => departBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={warningDialog}
          style={{ width: "450px", height: "205px" }}
          header={t("WARNING")}
          onHide={() => setWarningDialog(false)}
        >
          <div>
            <span
              style={{ color: "red", fontSize: "18px", marginLeft: "83px" }}
            >
              {t("BOOKING_ALREADY")} {t(product?.shipmentStatus)}
            </span>
          </div>
          <div>
            <Button
              onClick={() => setWarningDialog(false)}
              style={{
                color: "blue",
                fontSize: "18px",
                marginLeft: "170px",
                marginTop: "25px",
              }}
            >
              {t("OK")}
            </Button>
          </div>
        </Dialog>
        {editProductsDialog && (
          <EditBooking
            close={() => setEditProductsDialog(false)}
            booking={product}
            updateList={(data) => updateBookingList(data)}
          />
        )}
      </div>
    </DashboardContainer>
  );
};

export default ViewBooking;
