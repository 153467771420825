import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageSublink from "../../components/Dashboard/PageSublink";
import DashboardContainer from "../../components/Dashboard/DashboardContainer";
import CarrierDetails from "./CarrierDetails";
import MyProfileCustomer from "./MyProfileCustomer";

const CustomerData = () => {
  const [myProfileTab, setMyProfileTab] = useState(true);
  const [driverDataTab, setDriverDataTab] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <DashboardContainer>
      <div>
        <PageSublink pageName={t("MY_DATA")} />
        <h1 className="book_slot_title">{t("MY_DATA")}</h1>
        <div className="sub_menu_customer">
          <button
            className={`sub_menu_links_customer ${
              myProfileTab ? "active" : ""
            }`}
            onClick={() => {
              setMyProfileTab(true);
              setDriverDataTab(false);
            }}
            size="sm"
          >
            {t("MY_PROFILE_TITLE")}
          </button>
          <button
            className={`sub_menu_links_customer ${
              driverDataTab ? "active" : ""
            }`}
            onClick={() => {
              setMyProfileTab(false);
              setDriverDataTab(true);
            }}
            size="sm"
          >
            {t("CARRIER")}
          </button>
        </div>
        {myProfileTab && <MyProfileCustomer />}
        {driverDataTab && <CarrierDetails />}
      </div>
    </DashboardContainer>
  );
};

export default CustomerData;
