import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { classNames } from "primereact/utils";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import AddMDFacility from "./AddMDFacility";
import { _getMDFacilityList } from "../../../Services/masterDataServices";
import EditMDFacility from "./EditMDFacility";
import ManageManager from "./ManageManager";
import MDDock from "../masterDataDock/MDDock";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";
import { isAdmin, isWHMUser } from "../../../CommomFiles/globalFunctions";
const status = ["Active", "Inactive"];

const MDFacility = () => {
  const [bookings, setBookings] = useState(null);
  const [addFacilityDialog, setAddFacilityDialog] = useState(false);
  const [editProductsDialog, setEditProductsDialog] = useState(false);
  const [manageManagerDialog, setManageManagerDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [product, setProduct] = useState();
  const [expandedRows, setExpandedRows] = useState(null);
  const cm = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);
  const menuModel = [
    {
      label: t("EDIT_FACILITY"),
      icon: "pi pi-fw pi-user-edit",
      command: () => confirmEditSelected(selectedProduct),
    },
    {
      label: t("MANAGE_MANAGER"),
      icon: "pi pi-fw pi-user-edit",
      command: () => manageManagerSelected(selectedProduct),
    },
  ];

  useEffect(() => {
    getFacilityDetails();
  }, []);

  const getFacilityDetails = () => {
    const params = isWHMUser(userDetail)
      ? {
          facilityId:
            userDetail?.facilities.length > 0
              ? userDetail?.facilities.toString()
              : "",
        }
      : { orgId: "o1" };
    dispatch(
      _getMDFacilityList(
        params,
        (res) => {
          setBookings(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const confirmEditSelected = (product) => {
    setProduct(product);
    setEditProductsDialog(true);
  };

  const manageManagerSelected = (product) => {
    console.log("Product", product);
    setProduct(product);
    setManageManagerDialog(true);
  };

  const addBookingList = (data) => {
    const tempArray = [...bookings];
    tempArray.splice(0, 0, data);
    setBookings(tempArray);
  };

  const updateBookingList = (data) => {
    const index = bookings.findIndex((item) => item.siteName === data.siteName);
    const tempArray = [...bookings];
    tempArray.splice(index, 1, data);
    setBookings(tempArray);
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <MDDock facilityId={data.facilityId} />
      </div>
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.facilityStatus === "ACTIVE") {
      rowData.facilityStatus = "Active";
    } else if (rowData.facilityStatus === "INACTIVE") {
      rowData.facilityStatus = "Inactive";
    }
    const statusClassName = classNames({
      in_active: rowData.facilityStatus === "Inactive",
      active: rowData.facilityStatus === "Active",
    });
    return <div className={statusClassName}>{rowData.facilityStatus}</div>;
  };
  const urlBodyTemplate = (rowData) => {
    return <div className="facilityIdMapUrl">{rowData.facilityIdMapUrl}</div>;
  };

  return (
    <div>
      <ContextMenu
        model={menuModel}
        ref={cm}
        onHide={() => setSelectedProduct(null)}
      />
      <div className="box">
        <div>
          <DataTable
            value={bookings}
            stripedRows
            paginator={true}
            rows={5}
            contextMenuSelection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onContextMenu={(e) =>
              isAdmin(userDetail) ? cm.current.show(e.originalEvent) : null
            }
            onRowToggle={(e) => {
              setExpandedRows(e.data);
            }}
            rowsPerPageOptions={[5, 10, 20]}
            rowHover
            responsiveLayout="scroll"
            // onRowExpand={onRowExpand}
            expandedRows={expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column expander style={{ width: "3em" }} iconPosition="right" />
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("FACILITY_NAME")}
              field="siteName"
              header={t("FACILITY_NAME")}
            ></Column>

            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NAME")}
              field="streetName"
              header={t("STREET_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NUMBER")}
              field="streetNo"
              header={t("STREET_NUMBER")}
            ></Column>

            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("POST_CODE")}
              field="postCode"
              header={t("POST_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CITY")}
              field="city"
              header={t("CITY")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("FACILITY_STATUS")}
              field="facilityStatus"
              body={statusBodyTemplate}
              filterElement={statusRowFilterTemplate}
              header={t("FACILITY_STATUS")}
            ></Column>

            {/* <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              body={urlBodyTemplate}
              filterPlaceholder={t("FACILITY_DCO_MAP_URL")}
              field="facilityIdMapUrl"
              header={t("FACILITY_DCO_MAP_URL")}
            ></Column> */}
          </DataTable>
        </div>
        <MasterDataAddButton
          title={"ADD_NEW_FACILITY"}
          onClick={(e) => setAddFacilityDialog(true)}
        />
      </div>
      {editProductsDialog && (
        <EditMDFacility
          close={() => setEditProductsDialog(false)}
          booking={product}
          updateList={(data) => updateBookingList(data)}
        />
      )}
      {addFacilityDialog && (
        <AddMDFacility
          close={() => setAddFacilityDialog(false)}
          updateList={(data) => addBookingList(data)}
        />
      )}
      {manageManagerDialog && (
        <ManageManager
          close={() => setManageManagerDialog(false)}
          booking={product}
          // updateList={(data) => updateBookingList(data)}
        />
      )}
    </div>
  );
};

export default MDFacility;
