import React, { useEffect, useState } from "react";
import {
  Input,
  Dropdown,
  DropdownMenuItem,
  Button,
  Dialog,
  Body,
} from "@postidigital/posti-components";
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import { useTranslation } from "react-i18next";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  formateDate,
  showWarningToast,
} from "../../CommomFiles/globalFunctions";
import { hideLoader, showLoader } from "../../Store/Actions/commonAction";
import { replanningPreBookingFormSchema } from "../../CommomFiles/FormsValidationSchema";
import { DATEFORMAT } from "../../config/constant";
import {
  _getDriverList,
  _getCustomerList,
} from "../../Services/customerServices";
import { _getCarrierData } from "../../Services/carrierServices";
import { status } from "../../CommomFiles/slotPlanningFunction";
import { _prebookingSlots } from "../../Services/slotServices";

export const prebookingTypes = ["Container", "Special Booking"];

export const bookingTypeCode = ["CNTR", "SPCL_BKNG"];
const ReplanningPreBookSlotDetails = (props) => {
  const {
    closeModal,
    activeDate,
    uniqueId,
    selectedCellData,
    inboundSlotsData,
    checkBoxFlag,
    checkBoxRowId,
    selectedFacilty,
    getPrebookingSuccess,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);
  const [isCustomerDropdownOpen, setCustomerDropdown] = useState(false);
  const [isprebookingTypeDropdownOpen, setPrebookingTypeDropdown] =
    useState(false);
  const [isCarrierDropdownOpen, setCarrierDropdown] = useState(false);
  const [isDriverDropdownOpen, setDriverDropdown] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [carrierOptions, setCarrierOptions] = useState(null);
  const [driverOptions, setDriverOptions] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [bookingDetail, setBookingDetail] = useState({
    prebookingType: "Special Booking",
    PORef: "",
    shipperInvoiceRef: "",
    customerDetails: {},
    vehicleLicensePlateNumber: "",
    numberOfPallets: "",
    originETD: null,
    carrierDetails: {},
    driverDetails: {},
  });
  const [errors, setErrors] = useState(null);
  const [carrierId, setCarrierId] = useState(null);

  useEffect(() => {
    if (
      bookingDetail.prebookingType === prebookingTypes[0] &&
      selectedCellData.length <= 1 &&
      checkBoxFlag === false
    ) {
      showWarningToast(t("SELECT_AT_LEAST_TWO_SLOT_WARNING"));
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  }, [bookingDetail]);

  useEffect(() => {
    getCustomerOptions();
    getCarrierOptions();
  }, []);

  useEffect(() => {
    if (
      bookingDetail?.prebookingType === "Special Booking" &&
      Object.keys(bookingDetail?.carrierDetails).length !== 0
    ) {
      getDriverOptions();
      handleInputChange("driverDetails", {});
    }
  }, [bookingDetail?.carrierDetails]);

  // Getting Customer details
  const getCustomerOptions = () => {
    dispatch(showLoader());
    _getCustomerList(
      {
        orgId: "o1",
        customerStatus: "Active",
      },
      (res) => {
        setCustomerOptions(res.data);
        dispatch(hideLoader());
      },
      (err) => {
        console.log(err);
        dispatch(hideLoader());
      }
    );
  };

  // Getting Driver details
  const getDriverOptions = () => {
    dispatch(showLoader());
    _getDriverList(
      {
        carrierId:
          // "venky",
          bookingDetail.carrierDetails.carrierId,
        driverStatus: "Active",
      },
      (res) => {
        setDriverOptions(res.data);
        dispatch(hideLoader());
        // console.log("Driver data in truck", res.data);
      },
      (err) => {
        console.log(err);
        dispatch(hideLoader());
      }
    );
  };

  // Getting Carrier details
  const getCarrierOptions = () => {
    dispatch(
      _getCarrierData(
        {
          orgId: "o1",
          carrierStatus: "Active",
          // userDetail?.user?.username,
        },
        (res) => {
          setCarrierOptions(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  // Handling input changes
  const handleInputChange = (key, value) => {
    const form = { ...bookingDetail };
    form[key] = value;
    setBookingDetail(form);
    setErrors(null);
  };

  // Handling onSubmit of form
  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await replanningPreBookingFormSchema.isValid(
      bookingDetail,
      {
        abortEarly: false, // Prevent aborting validation after first error
      }
    );

    if (isFormValid) {
      bookingDetail["numberOfPallets"] = parseInt(
        bookingDetail.numberOfPallets
      );
      if (bookingDetail["originETD"])
        bookingDetail["originETD"] = formateDate(bookingDetail.originETD);

      // if not shipperInvoiceRef
      bookingDetail["shipperInvoiceRef"] = bookingDetail["shipperInvoiceRef"]
        ? bookingDetail["shipperInvoiceRef"]
        : null;

      if (bookingDetail.prebookingType === prebookingTypes[0]) {
        delete bookingDetail["carrierDetails"];
        delete bookingDetail["driverDetails"];
      }
      generatePrebookJson();
    } else {
      // If form is not valid, check which fields are incorrect:
      replanningPreBookingFormSchema
        .validate(bookingDetail, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  // Function for getting date difference
  const getDateDifference = () => {
    const startDate = moment(activeDate, DATEFORMAT);
    const today = moment(moment().format(DATEFORMAT), DATEFORMAT);
    return startDate.diff(today, "days");
  };

  // Function for disabling dates in calender
  const checkOutSideDate = (day) => {
    const today = moment();

    return (
      moment(formateDate(day)).isBefore(formateDate(today), "day") ||
      moment(formateDate(day)).isAfter(
        formateDate(today.add(getDateDifference(), "days")),

        "day"
      )
    );
  };

  // Function for generating prebook json
  const generatePrebookJson = () => {
    let row = null;
    if (checkBoxFlag === true) {
      row = checkBoxRowId;
    } else {
      row = uniqueId;
    }
    let replanningPrebookJson = JSON.parse(JSON.stringify(bookingDetail));
    let bookingType = null;
    const dockId = inboundSlotsData[row].dockId;
    const dockNo = inboundSlotsData[row].dock;
    const slotsArray = [];
    if (bookingDetail.prebookingType === prebookingTypes[0]) {
      bookingType = bookingTypeCode[0];
    } else {
      bookingType = bookingTypeCode[1];
    }
    const tempPrebookJson = JSON.parse(JSON.stringify(replanningPrebookJson));
    tempPrebookJson["bookingType"] = bookingType;
    tempPrebookJson["dockId"] = dockId;
    tempPrebookJson["dockNo"] = dockNo;
    const prebookDock = inboundSlotsData[row];
    if (checkBoxFlag === true) {
      const allKeys = Object.keys(prebookDock);
      for (let sl = 0; sl < allKeys.length; sl++) {
        if (
          allKeys[sl] !== "dock" &&
          allKeys[sl] !== "dockId" &&
          allKeys[sl] !== "break" &&
          allKeys[sl] !== "id"
        ) {
          if (prebookDock[allKeys[sl]].status === status[0]) {
            const singleSlot = {
              fromTime: activeDate + " " + prebookDock[allKeys[sl]].from,
              toTime: activeDate + " " + prebookDock[allKeys[sl]].to,
            };
            // console.log("Single slot", prebookDock[allKeys[sl]]);
            slotsArray.push(singleSlot);
          }
        }
      }
    } else {
      for (let slot = 0; slot < selectedCellData.length; slot++) {
        const singleSlot = {
          fromTime: activeDate + " " + prebookDock[selectedCellData[slot]].from,
          toTime: activeDate + " " + prebookDock[selectedCellData[slot]].to,
        };
        slotsArray.push(singleSlot);
      }
    }

    tempPrebookJson["slots"] = slotsArray;
    tempPrebookJson["slotDate"] = activeDate;
    tempPrebookJson["facilityId"] = selectedFacilty;
    delete tempPrebookJson["prebookingType"];
    replanningPrebookJson = tempPrebookJson;
    // console.log("Prebook json", replanningPrebookJson);
    handleReplanningPrebookCell(replanningPrebookJson);
  };

  // Function for prebooking slot
  const handleReplanningPrebookCell = (json) => {
    // console.log("Json in slot replanning screen", json);
    dispatch(
      _prebookingSlots(
        json,
        (res) => {
          // console.log("Response of post planning api -->", res);
          getPrebookingSuccess();
        },
        (err) => {
          console.log(err);
          // getPrebookingSuccess(false);
        }
      )
    );
  };

  return (
    <Dialog
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={
        <div style={{ display: "flex", alignItems: "center" }}>
          {t("PREBOOK_SLOT_FORM_TITLE")}
        </div>
      }
      onClose={() => {
        closeModal();
      }}
    >
      <Body as="div" size="Four">
        <div className="prebook_slot_detail">
          <>
            <form onSubmit={onSubmit}>
              <div className="replanning_detail_row">
                <div className="b_s_dropdown_container">
                  <Dropdown
                    isOpen={isprebookingTypeDropdownOpen}
                    title={bookingDetail.prebookingType}
                    iconColor="#73808C"
                    label={t("PREBOOKING_TYPE_TITLE")}
                    menuAriaLabel={t("PREBOOKING_TYPE_TITLE")}
                    onSelect={(e) => {
                      handleInputChange("prebookingType", e);
                    }}
                    onToggle={() =>
                      setPrebookingTypeDropdown(
                        (isprebookingTypeDropdownOpen) =>
                          !isprebookingTypeDropdownOpen
                      )
                    }
                    overflowMenuContainerDirection="right"
                    className="b_s_dropdown"
                    isValid={!(errors && "prebookingType" in errors)}
                    message={errors && t(errors["prebookingType"])}
                  >
                    {prebookingTypes?.map((item, index) => {
                      return (
                        <DropdownMenuItem
                          allowWrap
                          aria-label="Menu item"
                          tabIndex={index}
                          value={item}
                          selected={bookingDetail.prebookingType === item}
                          key={item}
                          className="b_s_dropdown_item"
                        >
                          {item}
                        </DropdownMenuItem>
                      );
                    })}
                  </Dropdown>
                </div>
                <Input
                  id="input"
                  label={t("PO_REF_TITLE")}
                  message={errors && t(errors["PORef"])}
                  onChange={(e) => handleInputChange("PORef", e.target.value)}
                  type="text"
                  value={bookingDetail.PORef}
                  className="b_s_input_control"
                  isInvalid={errors && "PORef" in errors}
                />
              </div>
              <div className="detail_row">
                <Input
                  id="input"
                  label={t("PREBOOK_SHIPPER_INVOICE_REF")}
                  message={errors && t(errors["shipperInvoiceRef"])}
                  onChange={(e) =>
                    handleInputChange("shipperInvoiceRef", e.target.value)
                  }
                  type="text"
                  value={bookingDetail.shipperInvoiceRef}
                  className="b_s_input_control"
                  isInvalid={errors && "shipperInvoiceRef" in errors}
                />
                <div className="b_s_dropdown_container">
                  <Dropdown
                    isOpen={isCustomerDropdownOpen}
                    title={bookingDetail.customerDetails?.customerName}
                    iconColor="#73808C"
                    label={t("PREBOOK_CUSTOMER_TITLE")}
                    menuAriaLabel="Customer *"
                    onSelect={(e) => handleInputChange("customerDetails", e)}
                    onToggle={() =>
                      setCustomerDropdown(
                        (isCustomerDropdownOpen) => !isCustomerDropdownOpen
                      )
                    }
                    overflowMenuContainerDirection="right"
                    className="b_s_dropdown replanningPrebookDropdown"
                    isValid={!(errors && "customerDetails" in errors)}
                    message={errors && t(errors["customerDetails"])}
                  >
                    {customerOptions?.map((item, index) => {
                      return (
                        <DropdownMenuItem
                          allowWrap
                          aria-label="Menu item"
                          tabIndex={index}
                          value={item}
                          selected={
                            bookingDetail?.customerDetails?.customerId ===
                            item?.customerId
                          }
                          key={item.customerId}
                          className="b_s_dropdown_item"
                        >
                          {item.customerName}
                        </DropdownMenuItem>
                      );
                    })}
                  </Dropdown>
                </div>
              </div>

              {bookingDetail.prebookingType === prebookingTypes[1] ? (
                <div className="detail_row">
                  <div className="truck_dropdown_container">
                    <Dropdown
                      isOpen={isCarrierDropdownOpen}
                      title={bookingDetail.carrierDetails?.carrierName}
                      iconColor="#73808C"
                      label={t("PREBOOKING_CARRIER_TITLE")}
                      menuAriaLabel={t("PREBOOKING_CARRIER_TITLE")}
                      onSelect={(e) => handleInputChange("carrierDetails", e)}
                      onToggle={() =>
                        setCarrierDropdown(
                          (isCarrierDropdownOpen) => !isCarrierDropdownOpen
                        )
                      }
                      overflowMenuContainerDirection="right"
                      className="truck_dropdown replanningPrebookDropdown"
                      isValid={!(errors && "carrierDetails" in errors)}
                      message={errors && t(errors["carrierDetails"])}
                    >
                      {carrierOptions?.map((item, index) => {
                        return (
                          <DropdownMenuItem
                            allowWrap
                            aria-label="Menu item"
                            tabIndex={index}
                            value={item}
                            selected={
                              bookingDetail?.carrierDetails?.carrierName ===
                              item.carrierName
                            }
                            key={item.carrierName}
                            className="truck_dropdown_item"
                          >
                            {item.carrierName}
                          </DropdownMenuItem>
                        );
                      })}
                    </Dropdown>
                  </div>
                  <div className="truck_dropdown_container">
                    <Dropdown
                      isOpen={isDriverDropdownOpen}
                      title={bookingDetail.driverDetails?.driverName}
                      iconColor="#73808C"
                      label={t("PREBOOKING_DRIVER_TITLE")}
                      menuAriaLabel="Customer *"
                      onSelect={(e) => handleInputChange("driverDetails", e)}
                      onToggle={() => {
                        if (
                          Object.keys(bookingDetail?.carrierDetails).length ===
                          0
                        ) {
                          showWarningToast(t("CARRIER_DETAILS_WARNING"));
                        } else {
                          setDriverDropdown(
                            (isDriverDropdownOpen) => !isDriverDropdownOpen
                          );
                        }
                      }}
                      overflowMenuContainerDirection="right"
                      className="truck_dropdown replanningPrebookDropdown"
                      isValid={!(errors && "driverDetails" in errors)}
                      message={errors && t(errors["driverDetails"])}
                    >
                      {driverOptions?.map((item, index) => {
                        return (
                          <DropdownMenuItem
                            allowWrap
                            aria-label="Menu item"
                            tabIndex={index}
                            value={item}
                            selected={
                              bookingDetail?.driverDetails?.driverName ===
                              item.driverName
                            }
                            key={item.driverName}
                            className="truck_dropdown_item"
                          >
                            {item.driverName}
                          </DropdownMenuItem>
                        );
                      })}
                    </Dropdown>
                  </div>
                </div>
              ) : null}
              <div className="detail_row">
                <Input
                  helpText=""
                  id="input"
                  label={t("PREBOOK_NUMBER_OF_PALLETS")}
                  message={errors && t(errors["numberOfPallets"])}
                  onChange={(e) =>
                    handleInputChange("numberOfPallets", e.target.value)
                  }
                  type="text"
                  value={bookingDetail.numberOfPallets}
                  className="b_s_input_control"
                  isInvalid={errors && "numberOfPallets" in errors}
                />
                <Input
                  helpText=""
                  id="input"
                  label={t("VEHICLE_LICENCE_PLATE_NUMBER")}
                  message={errors && t(errors["vehicleLicensePlateNumber"])}
                  onChange={(e) =>
                    handleInputChange(
                      "vehicleLicensePlateNumber",
                      e.target.value
                    )
                  }
                  type="text"
                  value={bookingDetail.vehicleLicensePlateNumber}
                  className="b_s_input_control"
                  isInvalid={errors && "vehicleLicensePlateNumber" in errors}
                />
              </div>
              <div className="detail_row">
                <DateInput
                  firstDayOfWeek={0}
                  displayFormat={DATEFORMAT}
                  // message={errors && errors["originETD"]}
                  message={errors && t(errors["originETD"])}
                  isInvalid={errors && "originETD" in errors}
                  id="bookigngDateInput"
                  label={t("ORIGIN_ETD")}
                  locale="en"
                  nextMonthButtonAriaLabel="Move to next month"
                  prevMonthButtonAriaLabel="Move to previous month"
                  onDateChange={(date) =>
                    handleInputChange("originETD", date ? date._d : "")
                  }
                  className="b_s_date_input"
                  date={
                    bookingDetail.originETD
                      ? moment(bookingDetail.originETD)
                      : ""
                  }
                  isOutsideRange={checkOutSideDate}
                  onTouchCancel={false}
                />
              </div>

              <div className="submit_btn_box">
                <Button
                  className="slot_detail_submit_btn"
                  iconPosition="right"
                  type="submit"
                  disabled={submitDisable}
                  size="sm"
                  onClick={() => {
                    // console.log("Booking details", bookingDetail);
                  }}
                >
                  {t("PREBOOK_SUBMIT")}
                  <ArrowForwardRoundedIcon
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                  />
                </Button>
              </div>
            </form>
          </>
        </div>
      </Body>
    </Dialog>
  );
};

export default ReplanningPreBookSlotDetails;
