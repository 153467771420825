import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { ContextMenu } from "primereact/contextmenu";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { _getMDCustomerList, _removeUser } from "../../../Services/masterDataServices";
import EditMDCustomer from "../masterDataCustomer/EditMDCustomer";
import AddMDCustomer from "../masterDataCustomer/AddMDCustomer";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";
import { isAdmin } from "../../../CommomFiles/globalFunctions";
import {
  Button
} from "@postidigital/posti-components";
import { Dialog } from "primereact/dialog";
import { showToast } from "../../../CommomFiles/globalFunctions";
import { hideLoader, showLoader } from '../../../Store/Actions/commonAction';

const status = ["Active", "Inactive"];

const MDCustomer = () => {
  const [bookings, setBookings] = useState(null);
  const [addCustomerDialog, setAddCustomerDialog] = useState(false);
  const [editProductsDialog, setEditProductsDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [product, setProduct] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const cm = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);
  const [cancelDelete, setcancelDelete] = useState(false);

  const menuModel = [
    {
      label: t("EDIT_CUSTOMER"),
      icon: "pi pi-fw pi-user-edit",
      command: () => confirmEditSelected(selectedProduct),
    },
  ];

  const isAdmin = () => {
    return (
      userDetail &&
      Object.keys(userDetail).length > 0 &&
      userDetail?.groups?.includes("SMS_Admin")
    );
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = () => {
    dispatch(
      _getMDCustomerList(
        { orgId: "o1" },
        (res) => {
          setBookings(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const confirmEditSelected = (product) => {
    setProduct(product);
    setEditProductsDialog(true);
  };

  const updateBookingList = (data) => {
    const index = bookings.findIndex(
      (item) => item.customerId === data.customerId
    );
    const tempArray = [...bookings];
    tempArray.splice(index, 1, data);
    setBookings(tempArray);
  };

  const addBookingList = (data) => {
    const tempArray = [...bookings];
    tempArray.splice(0, 0, data);
    setBookings(tempArray);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.status === "ACTIVE") {
      rowData.status = "Active";
    } else if (rowData.status === "INACTIVE") {
      rowData.status = "Inactive";
    }
    const statusClassName = classNames({
      in_active: rowData.status === "Inactive",
      active: rowData.status === "Active",
    });
    return <div className={statusClassName}>{rowData.status}</div>;
  };

  const removeCustomer=()=>{
    setcancelDelete(true);
  }

  const confirmDelete = () => {
    const selectedCustomerIds = [];
    selectedCustomers.map(customer=>{
      selectedCustomerIds.push(customer.customerId)
    })
    const params = { cognitoId:selectedCustomerIds,userType:"customer", orgId: "o1" };
    dispatch(
      _removeUser(
        params,
        (res) => {
          dispatch(showLoader());
          setSelectedCustomers(null) ;
          selectedCustomers.length = 0;
          getCustomerDetails();
          showToast(t("CUSTOMER_DELETE_SUCCESS"), "success");
          dispatch(hideLoader());
        },
        (err) => {
          console.log("Customer delete error", err);
          // showToast(t("CUSTOMER_DELETE_FAILURE"), "error");
        }
      )
     );
     setcancelDelete(false);
  };

  const closeAddCustomerPopup =()=>{
    setAddCustomerDialog(false)
    getCustomerDetails();
  }

  return (
    <div>
      <ContextMenu
        model={menuModel}
        ref={cm}
        onHide={() => setSelectedProduct(null)}
      />
      <div className="box">
        <div>
          {
            isAdmin() && (selectedCustomers !== null && selectedCustomers.length > 0) ?
              <Button
                className="manageManagersButton"
                iconPosition="right"
                type="submit"
                size="sm"
                style={{ margin: "12px" }}
              onClick={removeCustomer}
              > {t("REMOVE_CUSTOMER")}</Button> : null
          }

        </div>
        <div>
          <DataTable
            value={bookings}
            stripedRows
            paginator={true}
            rows={5}
            contextMenuSelection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onContextMenu={(e) =>
              isAdmin(userDetail) ? cm.current.show(e.originalEvent) : null
            }
            rowsPerPageOptions={[5, 10, 20]}
            rowHover
            responsiveLayout="scroll"
            selection={selectedCustomers}
            onSelectionChange={e => setSelectedCustomers(e.value)}
          >
            {isAdmin() ?
              <Column selectionMode="multiple" className="datatableHeaderWidth50" frozen={true} style={{ height: '35.5px', textAlign: 'center' }} />
              : null}
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CUSTOMER_CODE")}
              field="customerCode"
              header={t("CUSTOMER_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CUSTOMER_NAME")}
              field="customerName"
              header={t("CUSTOMER_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STATUS")}
              field="status"
              body={statusBodyTemplate}
              filterElement={statusRowFilterTemplate}
              header={t("STATUS")}
            ></Column>
            {/* <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("ORG_CODE")}
              field="orgCode"
              header={t("ORG_CODE")}
            ></Column> */}
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NAME")}
              field="streetName"
              header={t("STREET_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NUMBER")}
              field="streetNo"
              header={t("STREET_NUMBER")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("POST_CODE")}
              field="postCode"
              header={t("POST_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CITY")}
              field="city"
              header={t("CITY")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_NAME")}
              field="spocName"
              header={t("SPOC_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_EMAIL")}
              field="spocEmail"
              header={t("SPOC_EMAIL")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_PHONE_NUMBER")}
              field="spocPhNo"
              header={t("SPOC_PHONE_NUMBER")}
            ></Column>
          </DataTable>
        </div>

        <MasterDataAddButton
          title={"ADD_NEW_CUSTOMER"}
          onClick={(e) => setAddCustomerDialog(true)}
        />
      </div>
      {editProductsDialog && (
        <EditMDCustomer
          close={() => setEditProductsDialog(false)}
          booking={product}
          updateList={(data) => updateBookingList(data)}
        />
      )}
      {addCustomerDialog && (
        <AddMDCustomer
          close={() => closeAddCustomerPopup()}
          updateList={(data) => addBookingList(data)}
        />
      )}
      <Dialog
          visible={cancelDelete}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setcancelDelete(false)}
        >
          <div>
            <span style={{ color: "red", fontSize: "18px" }}>
              {t("DELETE_CUSTOMER")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => confirmDelete()}
                  style={{
                    color: "#fff",
                    fontSize: "18px"
                  }}
                >
                  {t("YES")}
                </Button>
                <Button
                  onClick={() => setcancelDelete(false)}
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    marginLeft: "120px",
                    marginTop: "-62px"
                  }}
                >
                  {t("NO")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
    </div>
  );
};

export default MDCustomer;
