import React, { useEffect, useState } from "react";
import {
  Input,
  Dropdown,
  DropdownMenuItem,
  Button,
  Dialog,
  Body,
} from "@postidigital/posti-components";
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import { useTranslation } from "react-i18next";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import moment from "moment";
import { useDispatch } from "react-redux";
import { preBookingFormSchema } from "../../CommomFiles/FormsValidationSchema";
import { formateDate } from "../../CommomFiles/globalFunctions";
import { hideLoader, showLoader } from "../../Store/Actions/commonAction";
import { _getCustomerList } from "../../Services/customerServices";
import { DATEFORMAT } from "../../config/constant";

const PreBookSlotDetails = (props) => {
  const { t } = useTranslation();
  const { closeModal, getSubmittedData, activeDate } = props;
  const dispatch = useDispatch();

  const [isCustomerDropdownOpen, setCustomerDropdown] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [bookingDetail, setBookingDetail] = useState({
    PORef: "",
    shipperInvoiceRef: "",
    customerDetails: {},
    vehicleLicensePlateNumber: "",
    numberOfPallets: "",
    originETD: null,
  });
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    getCustomerOptions();
  }, []);

  // Getting Customer details
  const getCustomerOptions = () => {
    dispatch(showLoader());
    _getCustomerList(
      { orgId: "o1", customerStatus: "Active" },
      (res) => {
        setCustomerOptions(res.data);
        dispatch(hideLoader());
      },
      (err) => {
        console.log(err);
        dispatch(hideLoader());
      }
    );
  };

  // Handling input changes
  const handleInputChange = (key, value) => {
    const form = { ...bookingDetail };
    form[key] = value;
    setBookingDetail(form);
    setErrors(null);
  };

  // Handling onSubmit of form
  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await preBookingFormSchema.isValid(bookingDetail, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      bookingDetail["numberOfPallets"] = parseInt(
        bookingDetail.numberOfPallets
      );
      if (bookingDetail["originETD"])
        bookingDetail["originETD"] = formateDate(bookingDetail.originETD);
      // if not shipperInvoiceRef
      bookingDetail["shipperInvoiceRef"] = bookingDetail["shipperInvoiceRef"]
        ? bookingDetail["shipperInvoiceRef"]
        : null;
      getSubmittedData(bookingDetail);
    } else {
      // If form is not valid, check which fields are incorrect:
      preBookingFormSchema
        .validate(bookingDetail, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  // Function for getting date difference
  const getDateDifference = () => {
    const startDate = moment(activeDate, DATEFORMAT);
    const today = moment(moment().format(DATEFORMAT), DATEFORMAT);
    return startDate.diff(today, "days");
  };

  // Function for disabling dates in calender
  const checkOutSideDate = (day) => {
    const today = moment();

    return (
      moment(formateDate(day)).isBefore(formateDate(today), "day") ||
      moment(formateDate(day)).isAfter(
        formateDate(today.add(getDateDifference(), "days")),

        "day"
      )
    );
  };

  return (
    <Dialog
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={
        <div style={{ display: "flex", alignItems: "center" }}>
          {t("PREBOOK_SLOT_FORM_TITLE")}
        </div>
      }
      onClose={() => {
        closeModal();
      }}
    >
      <Body as="div" size="Four">
        <div className="prebook_slot_detail">
          <form onSubmit={onSubmit}>
            <div className="detail_row">
              <Input
                id="input"
                label={t("PO_REF_TITLE")}
                message={errors && t(errors["PORef"])}
                onChange={(e) => handleInputChange("PORef", e.target.value)}
                type="text"
                value={bookingDetail.PORef}
                className="b_s_input_control"
                isInvalid={errors && "PORef" in errors}
              />
              <Input
                id="input"
                label={t("PREBOOK_SHIPPER_INVOICE_REF")}
                message={errors && t(errors["shipperInvoiceRef"])}
                onChange={(e) =>
                  handleInputChange("shipperInvoiceRef", e.target.value)
                }
                type="text"
                value={bookingDetail.shipperInvoiceRef}
                className="b_s_input_control"
                isInvalid={errors && "shipperInvoiceRef" in errors}
              />
            </div>
            <div className="detail_row">
              <div className="b_s_dropdown_container">
                <Dropdown
                  isOpen={isCustomerDropdownOpen}
                  title={bookingDetail.customerDetails?.customerName}
                  iconColor="#73808C"
                  label={t("PREBOOK_CUSTOMER_TITLE")}
                  menuAriaLabel="Customer *"
                  onSelect={(e) => handleInputChange("customerDetails", e)}
                  onToggle={() =>
                    setCustomerDropdown(
                      (isCustomerDropdownOpen) => !isCustomerDropdownOpen
                    )
                  }
                  overflowMenuContainerDirection="right"
                  className="b_s_dropdown prebookModalDropdown"
                  isValid={!(errors && "customerDetails" in errors)}
                  message={errors && t(errors["customerDetails"])}
                >
                  {customerOptions?.map((item, index) => {
                    return (
                      <DropdownMenuItem
                        allowWrap
                        aria-label="Menu item"
                        tabIndex={index}
                        value={item}
                        selected={
                          bookingDetail?.customerDetails?.customerId ===
                          item?.customerId
                        }
                        key={item.customerId}
                        className="b_s_dropdown_item dropdownMenuItem"
                      >
                        {item.customerName}
                      </DropdownMenuItem>
                    );
                  })}
                </Dropdown>
              </div>
              <Input
                helpText=""
                id="input"
                label={t("VEHICLE_LICENCE_PLATE_NUMBER")}
                message={errors && t(errors["vehicleLicensePlateNumber"])}
                onChange={(e) =>
                  handleInputChange("vehicleLicensePlateNumber", e.target.value)
                }
                type="text"
                value={bookingDetail.vehicleLicensePlateNumber}
                className="b_s_input_control"
                isInvalid={errors && "vehicleLicensePlateNumber" in errors}
              />
            </div>
            <div className="detail_row">
              <Input
                helpText=""
                id="input"
                label={t("PREBOOK_NUMBER_OF_PALLETS")}
                message={errors && t(errors["numberOfPallets"])}
                onChange={(e) =>
                  handleInputChange("numberOfPallets", e.target.value)
                }
                type="text"
                value={bookingDetail.numberOfPallets}
                className="b_s_input_control"
                isInvalid={errors && "numberOfPallets" in errors}
              />
              <DateInput
                firstDayOfWeek={0}
                displayFormat={DATEFORMAT}
                // message={errors && errors["originETD"]}
                message={errors && t(errors["originETD"])}
                isInvalid={errors && "originETD" in errors}
                id="bookigngDateInput"
                label={t("ORIGIN_ETD")}
                locale="en"
                nextMonthButtonAriaLabel="Move to next month"
                prevMonthButtonAriaLabel="Move to previous month"
                onDateChange={(date) =>
                  handleInputChange("originETD", date ? date._d : "")
                }
                className="b_s_date_input"
                date={
                  bookingDetail.originETD ? moment(bookingDetail.originETD) : ""
                }
                isOutsideRange={checkOutSideDate}
                onTouchCancel={false}
              />
            </div>
            <div className="detail_row"></div>
            <div className="submit_btn_box">
              <Button
                className="slot_detail_submit_btn"
                iconPosition="right"
                type="submit"
                size="sm"
              >
                {t("PREBOOK_SUBMIT")}
                <ArrowForwardRoundedIcon
                  style={{ marginLeft: "10px", fontSize: "20px" }}
                />
              </Button>
            </div>
          </form>
        </div>
      </Body>
    </Dialog>
  );
};

export default PreBookSlotDetails;
