import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { _getMDCarrierList } from "../../Services/masterDataServices";

const status = ["ACTIVE", "INACTIVE"];

const CarrierDetails = () => {
  const [bookings, setBookings] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getCarrierDetails();
  }, []);

  const getCarrierDetails = () => {
    dispatch(
      _getMDCarrierList(
        { orgId: "o1" },
        (res) => {
          setBookings(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };
  const statusBodyTemplate = (rowData) => {
    if (rowData.status === "ACTIVE") {
      rowData.status = "Active";
    } else if (rowData.status === "INACTIVE") {
      rowData.status = "Inactive";
    }
    const statusClassName = classNames({
      in_active: rowData.status === "Inactive",
      active: rowData.status === "Active",
    });
    return <div className={statusClassName}>{rowData.status}</div>;
  };

  return (
    <div>
      <div className="box">
        <div>
          <DataTable
            value={bookings}
            stripedRows
            paginator={true}
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            rowHover
            responsiveLayout="scroll"
          >
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CARRIER_NAME")}
              field="carrierName"
              header={t("CARRIER_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CARRIER_CODE")}
              field="carrierCode"
              header={t("CARRIER_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STATUS")}
              field="status"
              body={statusBodyTemplate}
              filterElement={statusRowFilterTemplate}
              header={t("STATUS")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NAME")}
              field="streetName"
              header={t("STREET_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NUMBER")}
              field="streetNo"
              header={t("STREET_NUMBER")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("POST_CODE")}
              field="postCode"
              header={t("POST_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CITY")}
              field="city"
              header={t("CITY")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_NAME")}
              field="spocName"
              header={t("SPOC_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_EMAIL")}
              field="spocEmail"
              header={t("SPOC_EMAIL")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_PHONE_NUMBER")}
              field="spocPhNo"
              header={t("SPOC_PHONE_NUMBER")}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default CarrierDetails;
