import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./screens/LandingPage/LandingPage";
import SlotBooking from "./screens/slot-booking";
import SlotPlanning from "./screens/slot-planning";
import AllBookings from "./screens/WareHouseManager/allBookings";
import ViewBooking from "./screens/carrier/view-booking";
import QrCodeScanner from "./screens/qrCode/qrCodeScanner";
import QrCodeDetails from "./screens/qrCode/qrCodeDetails";
import ViewBookingCustomer from "./screens/customer/view-booking-customer";
import PageNotFound from "./screens/PageNotFound";
import PrivateRoute from "./components/Dashboard/PrivateRoute";
import ManageMasterData from "./screens/masterData/master-data";
import CarrierData from "./screens/carrier/CarrierData";
import CustomerData from "./screens/customer/CustomerData";
import Rolling from "./screens/rollingScreen/Rolling";
import { values } from "./CommomFiles/landingPageConstants";

const Routing = () => {
  const WHM_User = values.WHM_GROUP_START_STR;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        {/* For Ware House Manager */}
        <Route
          path="/whm/view-booking"
          element={
            <PrivateRoute roles={WHM_User}>
              <AllBookings />
            </PrivateRoute>
          }
        />
        <Route
          path="/slot-planning"
          element={
            <PrivateRoute roles={WHM_User}>
              <SlotPlanning />
            </PrivateRoute>
          }
        />

        <Route
          path="/whm/manage-master-data"
          element={
            <PrivateRoute roles={WHM_User}>
              <ManageMasterData />
            </PrivateRoute>
          }
        />

        {/* For Carrier  */}
        <Route
          path="/carrier/view-booking"
          element={
            <PrivateRoute roles="SMS_Carrier">
              <ViewBooking />
            </PrivateRoute>
          }
        />
        <Route
          path="/carrier/data"
          element={
            <PrivateRoute roles="SMS_Carrier">
              <CarrierData />
            </PrivateRoute>
          }
        />
        <Route
          path="/slot-booking"
          element={
            <PrivateRoute roles="SMS_Carrier">
              <SlotBooking />
            </PrivateRoute>
          }
        />

        {/* for gate manager */}
        <Route
          path="/qr-code-scan"
          element={
            <PrivateRoute roles="SMS_Security">
              <QrCodeScanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/qr-code-scan/qr-code-details"
          element={
            <PrivateRoute roles="SMS_Security">
              <QrCodeDetails />
            </PrivateRoute>
          }
        />

        {/* For admin*/}
        <Route
          path="/manage-master-data"
          element={
            <PrivateRoute roles="SMS_Admin">
              <ManageMasterData />
            </PrivateRoute>
          }
        />

        {/* For Customer  */}
        <Route
          path="/customer/view-booking"
          element={
            <PrivateRoute roles="SMS_Customer">
              <ViewBookingCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/data"
          element={
            <PrivateRoute roles="SMS_Customer">
              <CustomerData />
            </PrivateRoute>
          }
        />

        {/* For desk*/}
        <Route
          path="/rolling"
          element={
            <PrivateRoute roles="SMS_Desk">
              <Rolling />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
