import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import Header from "../../components/Headers/Header";
import CommonFooter from "../../components/Footer/CommonFooter";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@postidigital/posti-components";
import { _getDockData } from "../../Services/qrCodeServices";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const QrCodeDetails = () => {
  const [dockData, setDockData] = useState(null);
  const [validDockData, setValidDockData] = useState(null);
  const location = useLocation();
  const userDetail = useSelector((state) => state.user.userDetail);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getDockData();
  }, []);

  const dockDetails = () => {
    let finalResult = "";
    if (dockData?.dockNo != null && dockData?.dockNo.includes(" ")) {
      finalResult = t("DOCK_DETAILS_ALREADY_PROVIDED");
    } else if (validDockData?.dockNo != null) {
      finalResult = t("GO_TO_DOCK") + validDockData?.dockNo;
    }
    return finalResult;
  };

  const dockMessage = () => {
    let finalResult = "";
    if (dockData?.msgKey === "VALUE_SHIPMENT_EARLY") {
      if (dockData?.message.includes("days")) {
        finalResult =
          t(dockData?.msgKey) +
          dockData?.message.substring(
            dockData?.message.indexOf("for") + 4,
            dockData?.message.indexOf("days")
          ) +
          t("DAYS") +
          ", " +
          dockData?.message.substring(dockData?.message.indexOf("days") + 6);
      } else {
        finalResult =
          t(dockData?.msgKey) +
          dockData?.message.substring(dockData?.message.indexOf("for") + 4);
      }
    } else if (validDockData?.msgKey === "DOCK_ASSIGNED") {
      finalResult =
        t("BOOKING_ID") +
        ": " +
        validDockData?.message.substring(
          validDockData?.message.indexOf(":") + 2,
          validDockData?.message.indexOf(";")
        ) +
        "; " +
        t("CUSTOMER") +
        ": " +
        validDockData?.message.substring(
          getPosition(validDockData?.message, ":", 2) + 2,
          getPosition(validDockData?.message, ";", 2)
        ) +
        "; " +
        t("ETA") +
        ": " +
        validDockData?.message.substring(
          getPosition(validDockData?.message, ":", 3) + 2,
          getPosition(validDockData?.message, ";", 3)
        ) +
        "; " +
        t("DOCK_ASSIGNED") +
        ": " +
        validDockData?.dockNo +
        "; " +
        t("PLEASE_MOVE_FORWARD");
    } else {
      finalResult = t(dockData?.msgKey);
    }
    return finalResult;
  };

  const getDockData = () => {
    dispatch(
      _getDockData(
        { bookingId: `${location.state.id}`, action: "ASSIGN_DOCK" },
        (res) => {
          setDockData(res.data.detail);
          setValidDockData(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  return (
    <div className="qrCodeDetailsPage">
      <Header user={userDetail?.user} />
      <div className="qrCode_page_container">
        <div className="cards_container">
          <Card className="card_box_qr_code">
            <div className="contentDivision">
              <div className="greenTickImg">
                <img alt="Settings" src="/imgs/correct.png" width="50px" />
              </div>
              <div className="cardTitleQRCode">
                {t("QR_CODE_SCANNED_SUCCESSFULLY")}
              </div>
              <div className="cardContent">{dockMessage()}</div>
              <div className="dockClass">{dockDetails()}</div>

              <Button className="confirmButton" onClick={() => navigate("/")}>
                {t("CONFIRM")}
              </Button>
            </div>
          </Card>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default QrCodeDetails;
