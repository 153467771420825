// Files for all global function
import moment from "moment";
import { toast } from "react-toastify";
import { ADITRO_USERNAME_PREFIX, DATEFORMAT } from "../config/constant";
import { Auth } from "aws-amplify";
import { values } from "./landingPageConstants";

export const showToast = (msg, type = "success", timer = 5000) => {
  toast(msg, {
    position: "top-right",
    autoClose: timer,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    type,
  });
};

export const showWarningToast = (msg, type = "warning") => {
  toast(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    type,
  });
};

export const showErorToast = (msg, type = "error") => {
  toast(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    type,
  });
};

export const showInformationToast = (msg, type = "info") => {
  toast(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    type,
  });
};

export const isToday = (value) => {
  return (
    moment(value, DATEFORMAT).format(DATEFORMAT) === moment().format(DATEFORMAT)
  );
};

export const getCurrentWeekDays = () => {
  let week = {
    start: "",
    end: "",
  };
  let today = new Date();
  if ([0, 6].includes(today.getDay())) {
    week.start = formateDate(today);
    week.end = formateDate(today);
  } else {
    let firstday = new Date(
      today.setDate(today.getDate() + 1) //+1 for tomorrow
    ).toUTCString();
    let lastday = new Date(
      today.setDate(today.getDate() - today.getDay() + 7) //+7 for complete week
    ).toUTCString();
    console.log({ firstday }, { lastday });
    week.start = formateDate(firstday);
    week.end = formateDate(lastday);
  }
  return week;
};

export const getNextWeekDays = () => {
  let week = {
    start: "",
    end: "",
  };
  let today = new Date();
  if (today.getDay() === 0) {
    let start = new Date(
      today.setDate(today.getDate() + 1) //+1 for monday,
    ).toUTCString();
    let end = new Date(
      today.setDate(today.getDate() + 60) //+30 for friday from monday
    ).toUTCString();
    week.start = formateDate(start);
    week.end = formateDate(end);
  } else {
    let start = new Date(
      today.setDate(today.getDate() - today.getDay() + 1 + 7) //+1 for monday, +7 for next monday
    ).toUTCString();
    let end = new Date(
      today.setDate(today.getDate() + 60) //+30 for friday from monday
    ).toUTCString();
    week.start = formateDate(start);
    week.end = formateDate(end);
  }

  return week;
};

export const getFacilityOpeningClosingTime = (data) => {
  let openingTime = "";
  let closingTime = "";
  if (data && data.length > 0) {
    data.forEach((i) => {
      // Opening time
      const startTime = moment(i.slots[0]["from"]).format("HH:mm");
      if (!openingTime) {
        openingTime = startTime;
      } else if (
        moment(openingTime, "HH:mm").isAfter(moment(startTime, "HH:mm"))
      ) {
        openingTime = startTime;
      }
      // Closing time
      let lastIndex = i.slots.length - 1;
      const endTime = moment(i.slots[lastIndex]["to"]).format("HH:mm");
      if (!closingTime) {
        closingTime = endTime;
      } else if (
        moment(closingTime, "HH:mm").isBefore(moment(endTime, "HH:mm"))
      ) {
        closingTime = endTime;
      }
    });
    let officeTime = {
      from: openingTime,
      to: closingTime,
    };
    return officeTime;
  }
};

export const getFormatedTime = (time, format) => {
  return moment(time).format(format);
};

export const formateDate = (time) => {
  return moment(time).format(DATEFORMAT);
};

export const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
};

export const digitsOnly = (value) =>
  /^\d*[\.{1}\d*]\d*$/.test(value) || value?.length === 0;

export const LogoutApp = async () => {
  try {
    await Auth.signOut().then((res) => {
      localStorage.clear();
      window.location.href = "/";
    });
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const isAdmin = (userDetail) => {
  return (
    userDetail &&
    Object.keys(userDetail).length > 0 &&
    userDetail?.groups?.includes("SMS_Admin")
  );
};

export const isEmail = (emailList) => {
  if (emailList) {
    let emails = emailList.replace(/\s/g, "").split(",");
    // console.log("Email", emails);
    let valid = true;
    let regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    emails.forEach((email, index) => {
      if (email === "" || !regex.test(email)) {
        valid = false;
      }
      // else if (emails.indexOf(email) !== index) {
      //   // console.log("duplicate", email, index);
      //   valid = false;
      // }
    });
    return valid;
  } else {
    return true;
  }
};

export const isDuplicateEmail = (emailList) => {
  if (emailList) {
    let emails = emailList.replace(/\s/g, "").split(",");
    // console.log("Email", emails);
    let valid = true;

    emails.forEach((email, index) => {
      if (emails.indexOf(email) !== index) {
        // console.log("duplicate", email, index);
        valid = false;
      }
    });
    return valid;
  } else {
    return true;
  }
};

export const isAditroEmail = (emails) => {
  const RegEx = /@aditrologistics.com\s*$/;
  let splitArray = emails.split(",");
  let isValid = true;

  splitArray?.forEach((e) => {
    if (!e.trim()) {
      isValid = false;
    } else if (!RegEx.test(e.toLowerCase())) {
      isValid = false;
    }
  });
  return isValid;
};

export const isWHMUser = (userDetail) => {
  if (!userDetail?.groups) {
    return false;
  }
  const startsWithValue = userDetail?.groups?.filter((e) =>
    e.startsWith(values.WHM_GROUP_START_STR)
  );
  if (startsWithValue?.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const getWHMFacilities = (groups) => {
  const WHMFacilities = [];
  groups?.map((group) => {
    if (
      group.split("-")[1] &&
      group.split("-")[0] === values.WHM_GROUP_START_STR
    )
      WHMFacilities.push(group.split("-")[1]);
  });
  console.log("Group", WHMFacilities);
  return WHMFacilities;
};

export const addingAditroUsername = (managers) => {
  const managersData = managers.replace(/\s/g, "").split(",");
  const aditroManagers = managersData?.map((manager) => {
    return ADITRO_USERNAME_PREFIX + manager;
  });
  return aditroManagers.toString().toLowerCase();
};


export const isPhoneNoValid = (phoneNo) => {
  if (phoneNo) {
    let valid = true;
    let regex =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (phoneNo === "" || !regex.test(phoneNo)) {
        valid = false;
       }
    return valid;
  } else {
    return true;
  }
};
