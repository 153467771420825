import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  Body,
  Input,
  Dropdown,
  DropdownMenuItem,
} from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../CommomFiles/globalFunctions";
import { _addDockData } from "../../../Services/masterDataServices";
import { addMDDocFormSchema } from "../../../CommomFiles/FormsValidationSchema";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";

// const Status = ["Active", "Inactive"];

const AddMDDock = ({ close, facilityId, updateList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isStatusDropdownOpen, setStatusDropdown] = useState(false);

  const [errors, setErrors] = useState(null);
  const [product, setProduct] = useState({
    dockNo: "",
    facilityId: facilityId,
    dockType: "Inbound",
    dockStatus: "Active",
    dockName: "",
  });

  useEffect(() => {}, []);

  const addDock = () => {
    dispatch(
      _addDockData(
        product,
        (res) => {
          const responseData = res.data;
          updateList(responseData[0]);
          showToast(t("DOCK_ADDED_SUCCESSFULLY"), "success");
          close();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const onInputChange = (val, key) => {
    const _product = { ...product };
    _product[`${key}`] = val;
    setProduct(_product);
    setErrors(null);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await addMDDocFormSchema.isValid(product, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      addDock();
    } else {
      // If form is not valid, check which fields are incorrect:
      addMDDocFormSchema
        .validate(product, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  return (
    <Dialog
      bottomButtons={
        <MasterDataAddButton title={"SAVE"} onClick={(e) => onSubmit(e)} />
      }
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={t("ADD_DOCK")}
      onClose={() => {
        close();
      }}
      style={{ width: "600px" }}
    >
      <Body as="div" className="dock_input_container" size="Four">
        <Input
          type="text"
          label={t("DOCK_NO")}
          value={product?.dockNo}
          onChange={(e) => onInputChange(e.target.value, "dockNo")}
          required
          className="dock_input_control"
          message={errors && t(errors["dockNo"])}
          isInvalid={errors && "dockNo" in errors}
        />
        <Input
          readOnly={true}
          label={t("DOCK_TYPE")}
          value={product?.dockType}
          onChange={(e) => onInputChange(e.target.value, "dockType")}
          required
          className="dock_disable_input_control"
          message={errors && t(errors["dockType"])}
          isInvalid={errors && "dockType" in errors}
        />

        <Input
          readOnly={true}
          label={t("DOCK_STATUS")}
          value={product?.dockStatus}
          onChange={(e) => onInputChange(e.target.value, "dockStatus")}
          required
          className="dock_disable_input_control"
          message={errors && t(errors["dockStatus"])}
          isInvalid={errors && "dockStatus" in errors}
        />
        {/* <div className="m_d_dock_dropdown_container">
          <Dropdown
            isOpen={isStatusDropdownOpen}
            title={product?.dockStatus}
            iconColor="#73808C"
            label={t("DOCK_STATUS")}
            menuAriaLabel="dockStatus"
            className="m_d_dock_dropdown"
            onSelect={(e) => onInputChange(e, "dockStatus")}
            onToggle={() =>
              setStatusDropdown((isStatusDropdownOpen) => !isStatusDropdownOpen)
            }
            isValid={!(errors && "dockStatus" in errors)}
            message={errors && t(errors["dockStatus"])}
            overflowMenuContainerDirection="right"
          >
            {Status?.map((item, index) => {
              return (
                <DropdownMenuItem
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={product?.dockStatus === item}
                  key={item}
                  className="m_d_dock_dropdown_item"
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div> */}
      </Body>
    </Dialog>
  );
};

export default AddMDDock;
