import React from "react";
import CommonFooter from "../Footer/CommonFooter";
import CommonHeader from "../Headers/CommonHeader";
import Header from "../Headers/Header";

const DashboardContainer = (props) => {
  const { headerType = "C" } = props;

  return (
    <div className="dashboard-page">
      {headerType === "C" ? <CommonHeader /> : <Header />}
      <div className="dashboard-container">{props.children}</div>
      <CommonFooter />
    </div>
  );
};

export default DashboardContainer;
