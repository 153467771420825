import React from "react";
import { useSelector } from "react-redux";
import { isWHMUser } from "../../CommomFiles/globalFunctions";
import { values } from "../../CommomFiles/landingPageConstants";
import PageNotFound from "../../screens/PageNotFound";

const PrivateRoute = ({ children, roles }) => {
  const userDetail = useSelector((state) => state.user.userDetail);

  //First check for warehouse manager
  const userHasRequiredRole =
    roles === values.WHM_GROUP_START_STR
      ? isWHMUser(userDetail)
      : userDetail?.groups?.includes(roles)
      ? true
      : false;

  if (!userHasRequiredRole) {
    return <PageNotFound />; // auth (sth like 404)
  }

  return children;
};

export default PrivateRoute;
