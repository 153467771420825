export const values = {
  pageTitle: "Slot Management System",
  bookASlotTitle: "Book a slot",
  bookASlotContent:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!",
  bookASlotButton: "Book a slot",
  WHM_GROUP_START_STR: "SMS_WHM",
  CARRIER_GROUP_START_STR: "SMS_Carrier",
  CUSTOMER_GROUP_START_STR: "SMS_Customer",
  SECURITY_GROUP_START_STR: "SMS_Security",
  ADMIN_GROUP_START_STR: "SMS_Admin",
  DESK_GROUP_START_STR: "SMS_Desk",
};
