import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Dropdown,
  DropdownMenuItem,
  Button,
  Dialog,
  Body,
} from "@postidigital/posti-components";
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import moment from "moment";
import { _bookSelectedSlot } from "../../Services/slotServices";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useNavigate } from "react-router";
import { bookingFormSchema } from "../../CommomFiles/FormsValidationSchema";
import {
  formateDate,
  getFormatedTime,
} from "../../CommomFiles/globalFunctions";
import { hideLoader, showLoader } from "../../Store/Actions/commonAction";
import {
  _getCustomerList,
  _getDriverList,
} from "../../Services/customerServices";
import GeneratePdf from "../../components/QRcode/GeneratePdf";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { DATEFORMAT, DATETIMEFORMAT } from "../../config/constant";

const BookSlotDetail = (props) => {
  const { t } = useTranslation();
  const { bookedSlot, facilityId } = props;
  const childRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.user.userDetail);

  const [isCustomerDropdownOpen, setCustomerDropdown] = useState(false);
  const [isDriverDropdownOpen, setDriverDropdown] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [bookingDetail, setBookingDetail] = useState({
    facilityId: facilityId,
    bookingType: "CB",
    slotDetails: "",
    carrierId: userDetail?.user?.username,
    PORef: "",
    shipperInvoiceRef: "",
    customerDetails: {},
    vehicleLicensePlateNumber: "",
    numberOfPallets: "",
    driverDetails: {},
    originETD: null,
  });
  const [errors, setErrors] = useState(null);
  const [bookingSucessModal, setBookingSuccessModal] = useState(false);
  const [successResponse, setSuccessResponse] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    let details = { ...bookingDetail };
    let booked = {};
    booked["fromTime"] = getFormatedTime(bookedSlot.from, DATETIMEFORMAT);
    booked["toTime"] = getFormatedTime(bookedSlot.to, DATETIMEFORMAT);
    details["slotDetails"] = booked;
    setBookingDetail(details);
  }, [bookedSlot]);

  useEffect(() => {
    getCustomerOptions();
  }, []);

  const getCustomerOptions = () => {
    dispatch(showLoader());
    _getCustomerList(
      { orgId: "o1", customerStatus: "Active" },
      (res) => {
        setCustomerOptions(res.data);
        getDriverOptions();
      },
      (err) => {
        dispatch(hideLoader());
      }
    );
  };

  const getDriverOptions = () => {
    _getDriverList(
      { carrierId: userDetail?.user?.username, driverStatus: "Active" },
      (res) => {
        setDriverOptions(res.data);
        dispatch(hideLoader());
      },
      (err) => {
        dispatch(hideLoader());
      }
    );
  };

  const handleInputChange = (key, value) => {
    const form = { ...bookingDetail };
    form[key] = value;
    setBookingDetail(form);
    setErrors(null);
  };

  const getDateDifference = () => {
    const activeDate = formateDate(bookedSlot.from);
    const startDate = moment(activeDate, DATEFORMAT);
    const today = moment(moment().format(DATEFORMAT), DATEFORMAT);
    return startDate.diff(today, "days");
  };

  const checkOutSideDate = (day) => {
    const today = moment();
    return (
      moment(formateDate(day)).isBefore(formateDate(today), "day") ||
      moment(formateDate(day)).isAfter(
        formateDate(today.add(getDateDifference(), "days")),
        "day"
      )
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await bookingFormSchema.isValid(bookingDetail, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      handleBooking();
    } else {
      // If form is not valid, check which fields are incorrect:
      bookingFormSchema
        .validate(bookingDetail, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  const handleBooking = () => {
    const finalBookingDetails = { ...bookingDetail };
    if (finalBookingDetails["originETD"])
      finalBookingDetails["originETD"] = formateDate(bookingDetail.originETD);
    // if not shipperInvoiceRef
    finalBookingDetails["shipperInvoiceRef"] = finalBookingDetails[
      "shipperInvoiceRef"
    ]
      ? finalBookingDetails["shipperInvoiceRef"]
      : null;

    dispatch(
      _bookSelectedSlot(
        finalBookingDetails,
        (res) => {
          console.log(res);
          setBookingSuccessModal(true);
          setSuccessResponse(res.data[0]);
        },
        (err) => {}
      )
    );
  };

  return (
    <div className="book_slot_detail">
      <form onSubmit={onSubmit}>
        <div className="detail_row">
          <Input
            id="input"
            label={t("BOOK_PO_REF_TITLE")}
            message={errors && t(errors["PORef"])}
            onChange={(e) => handleInputChange("PORef", e.target.value)}
            type="text"
            value={bookingDetail.PORef}
            className="b_s_input_control"
            isInvalid={errors && "PORef" in errors}
          />
          <Input
            id="input"
            label={t("BOOK_SHIPPER_INVOICE_REF_TITLE")}
            message={errors && t(errors["shipperInvoiceRef"])}
            onChange={(e) =>
              handleInputChange("shipperInvoiceRef", e.target.value)
            }
            type="text"
            value={bookingDetail.shipperInvoiceRef}
            className="b_s_input_control"
            isInvalid={errors && "shipperInvoiceRef" in errors}
          />
        </div>
        <div className="detail_row">
          <div className="b_s_dropdown_container">
            <Dropdown
              isOpen={isCustomerDropdownOpen}
              title={bookingDetail.customerDetails?.customerName}
              iconColor="#73808C"
              label={t("BOOK_CUSTOMER_TITLE")}
              menuAriaLabel="Customer *"
              onSelect={(e) => handleInputChange("customerDetails", e)}
              onToggle={() =>
                setCustomerDropdown(
                  (isCustomerDropdownOpen) => !isCustomerDropdownOpen
                )
              }
              overflowMenuContainerDirection="right"
              className="b_s_dropdown"
              isValid={!(errors && "customerDetails" in errors)}
              message={errors && t(errors["customerDetails"])}
            >
              {customerOptions?.map((item, index) => {
                return (
                  <DropdownMenuItem
                    allowWrap
                    aria-label="Menu item"
                    tabIndex={index}
                    value={item}
                    selected={
                      bookingDetail?.customerDetails?.customerId ===
                      item?.customerId
                    }
                    key={item.customerId}
                    className="b_s_dropdown_item dropdownMenuItem"
                  >
                    {item.customerName}
                  </DropdownMenuItem>
                );
              })}
            </Dropdown>
          </div>
          <Input
            helpText=""
            id="input"
            label={t("BOOK_VEHICLE_LICENCE_PLATE_NUMBER")}
            message={errors && t(errors["vehicleLicensePlateNumber"])}
            onChange={(e) =>
              handleInputChange("vehicleLicensePlateNumber", e.target.value)
            }
            type="text"
            value={bookingDetail.vehicleLicensePlateNumber}
            className="b_s_input_control"
            isInvalid={errors && "vehicleLicensePlateNumber" in errors}
          />
        </div>
        <div className="detail_row">
          <Input
            helpText=""
            id="input"
            label={t("BOOK_NUMBER_OF_PALLETS_TITLE")}
            message={errors && t(errors["numberOfPallets"])}
            onChange={(e) =>
              handleInputChange("numberOfPallets", e.target.value)
            }
            type="text"
            value={bookingDetail.numberOfPallets}
            className="b_s_input_control"
            isInvalid={errors && "numberOfPallets" in errors}
          />
          <div className="b_s_dropdown_container">
            <Dropdown
              isOpen={isDriverDropdownOpen}
              title={bookingDetail.driverDetails?.driverName}
              iconColor="#73808C"
              label={t("BOOK_DRIVER_NAME_TITLE")}
              menuAriaLabel="Driver name *"
              onSelect={(e) => handleInputChange("driverDetails", e)}
              onToggle={() =>
                setDriverDropdown(
                  (isDriverDropdownOpen) => !isDriverDropdownOpen
                )
              }
              overflowMenuContainerDirection="right"
              className="b_s_dropdown"
              isValid={!(errors && "driverDetails" in errors)}
              message={errors && t(errors["driverDetails"])}
            >
              {driverOptions?.map((item, index) => {
                return (
                  <DropdownMenuItem
                    allowWrap
                    aria-label="Menu item"
                    tabIndex={index}
                    value={item}
                    selected={
                      bookingDetail?.driverDetails?.driverId === item?.driverId
                    }
                    key={item.driverId}
                    className="b_s_dropdown_item dropdownMenuItem"
                  >
                    {item.driverName}
                  </DropdownMenuItem>
                );
              })}
            </Dropdown>
          </div>
        </div>
        <div className="detail_row">
          <Input
            helpText=""
            id="input"
            label={t("BOOK_LICENCE_NUMBER_TITLE")}
            type="text"
            value={bookingDetail?.driverDetails?.drivingLicenseNo}
            className="b_s_input_control"
            readOnly={true}
          />
          <DateInput
            firstDayOfWeek={0}
            displayFormat={DATEFORMAT}
            id="bookigngDateInput"
            label={t("BOOK_ORIGIN_ETD")}
            locale="en"
            message={errors && t(errors["originETD"])}
            isInvalid={errors && "originETD" in errors}
            nextMonthButtonAriaLabel="Move to next month"
            prevMonthButtonAriaLabel="Move to previous month"
            onDateChange={(date) =>
              handleInputChange("originETD", date ? date._d : "")
            }
            className="b_s_date_input"
            date={
              bookingDetail.originETD ? moment(bookingDetail.originETD) : ""
            }
            isOutsideRange={checkOutSideDate}
          />
        </div>
        <div className="submit_btn_box">
          <Button
            className="slot_detail_submit_btn"
            iconPosition="right"
            type="submit"
            size="sm"
          >
            {t("BOOK_SUBMIT")}
            <ArrowForwardRoundedIcon
              style={{ marginLeft: "10px", fontSize: "20px" }}
            />
          </Button>
        </div>
      </form>

      {/* Success Dialog box */}
      {bookingSucessModal && (
        <Dialog
          bottomButtons={
            <Button
              iconPosition="right"
              onClick={() => setPdfData(successResponse)}
              style={{ marginRight: "auto" }}
            >
              <FileDownloadOutlinedIcon
                fontSize="medium"
                style={{ marginRight: 10 }}
              />
              {t("DOWNLOAD_PDF")}
            </Button>
          }
          closeText="Close dialog"
          disableOutsideClick={true}
          headerContent={
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon
                fontSize="large"
                style={{ color: "#008A00", marginRight: 10 }}
              />
              {t("SLOT_BOOKING_SUCCESS")}
            </div>
          }
          onClose={() => {
            setBookingSuccessModal(false);
            navigate("/");
          }}
        >
          <Body as="div" size="Four">
            {t("ARRIVE_AT_FACILITY_15_MIN")}
          </Body>
        </Dialog>
      )}
      {pdfData && <GeneratePdf rowData={pdfData} ref={childRef} />}
    </div>
  );
};

export default BookSlotDetail;
