export const loginDict = {
  sv: {
    "Enter your username": "Skriv in ditt användarnamn",
    "Sign in to your account": "logga in på ditt konto",
    "Sign In": "Logga in",
    "Sign in": "Logga in",
    "Sign Up": "Bli Medlem",
    "Username *": "Användarnamn *",
    Username: "Användarnamn",
    "Password *": "Lösenord *",
    Password: "Lösenord",
    "Enter your password": "Ange ditt lösenord",
    "No account?": "Inget konto?",
    "Create account": "Skapa konto",
    "Create Account": "Skapa konto",
    "Have an account?": "Har ett konto?",
    "Have a code?": "Har du en kod?",
    "Code *": "Kod *",
    Confirm: "Bekräfta",
    "Reset Password": "Återställ lösenord",
    "Reset password": "Återställ lösenord",
    "Forgot your password?": "Glömt ditt lösenord?",
    "Reset your password": "Återställ ditt lösenord    ",
    "Back to Sign in": "Tillbaka till Logga in    ",
    "You will receive a verification code to reset your password":
      "Du kommer att få en verifieringskod för att återställa ditt lösenord      ",
    Submit: "Skicka in",
    Verify: "Kontrollera",
    "Verify Contact": "Verifiera kontakt",
    Skip: "Hoppa",
    "Lost your code?'": "Har du tappat bort din kod?",
    Resend: "Skicka igen",
    "Forgot Password?": "Glömt ditt lösenord?",
    "You will receive a verification code":
      "Du kommer att få en verifieringskod",
    Code: "Koda",
    "Account recovery requires verified contact information":
      "Kontoåterställning kräver verifierad kontaktinformation",
    "User does not exist": "Användare finns inte",
    "User already exists": "Användare finns redan",
    "Incorrect username or password": "felaktigt användarnamn eller lösenord",
    "Invalid password format": "Invalid password format",
    "Invalid phone number format": "Ogiltigt telefonnummerformat      ",
    "Username cannot be empty": "Användarnamnet får inte vara tomt    ",
    "Custom auth lambda trigger is not configured for the user pool.":
      "Custom auth lambda trigger är inte konfigurerad för användarpoolen.",
    "Incorrect username or password.": "Felaktigt användarnamn eller lösenord.",
    "Password attempts exceeded": "Lösenordsförsök har överskridits",
    "Back to Sign In": "Tillbaka till Logga in",
    "SEND CODE": "skicka kod",
    "Send Code": "skicka kod",
  },
};
