import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Body, Textarea, Button } from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import {
  addingAditroUsername,
  showToast,
} from "../../../CommomFiles/globalFunctions";
import {
  addManagerFormSchema,
  removeManagerFormSchema,
} from "../../../CommomFiles/FormsValidationSchema";
import {
  _addManagers,
  _removeManagers,
} from "../../../Services/masterDataServices";

const ManageManager = ({ close, booking, updateList }) => {
  const [errors, setErrors] = useState(null);
  const [managers, setManagers] = useState({
    addManagers: "",
    removeManagers: "",
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onInputChange = (val, key) => {
    const _managers = { ...managers };
    _managers[`${key}`] = val;
    setManagers(_managers);
    setErrors(null);
  };

  const addingManagers = () => {
    dispatch(
      _addManagers(
        {
          facilityId: booking.facilityId,
          managers: addingAditroUsername(managers.addManagers),
        },
        (res) => {
          showToast(t("MANAGERS_ADDED"));
          close();
        },
        (err) => {
          const errDetail = err.response?.data?.detail;
          console.log("Add error", errDetail);
          if (errDetail?.msgKey === "ERR_USER_ADDITION") {
            const er = {
              addManagers:
                t("FAILED_TO_ADD") +
                errDetail?.message +
                t("CHECK_USER_PRESENT_COGNITO"),
            };
            setErrors(er);
          }
        }
      )
    );
  };

  const removingManagers = () => {
    dispatch(
      _removeManagers(
        {
          facilityId: booking.facilityId,
          managers: addingAditroUsername(managers.removeManagers),
        },
        (res) => {
          showToast(t("MANAGERS_REMOVED"));
          close();
        },
        (err) => {
          const errDetail = err.response?.data?.detail;
          console.log("Remove err", errDetail);
          if (errDetail?.msgKey === "ERR_USER_DELETION") {
            const er = {
              removeManagers:
                t("FAILED_TO_REMOVE") +
                errDetail?.message +
                t("CHECK_USER_PRESENT_COGNITO"),
            };
            setErrors(er);
          } else if (errDetail?.msgKey === "ERR_USER_NOT_IN_GIVEN_FACILITY") {
            const er = {
              removeManagers: errDetail?.message + t("NOT_PRESENT_FACILITY"),
            };
            setErrors(er);
          }
        }
      )
    );
  };

  const addManager = async (event) => {
    event.preventDefault();
    const isFormValid = await addManagerFormSchema.isValid(managers, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      // modifyBooking();
      addingManagers();
    } else {
      // If form is not valid, check which fields are incorrect:
      addManagerFormSchema
        .validate(managers, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  const removeManager = async (event) => {
    event.preventDefault();
    const isFormValid = await removeManagerFormSchema.isValid(managers, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      console.log("Remove Manager", managers);
      removingManagers();
      // modifyBooking();
    } else {
      // If form is not valid, check which fields are incorrect:
      removeManagerFormSchema
        .validate(managers, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };
  return (
    <Dialog
      //   bottomButtons={
      //     <MasterDataAddButton title={"SAVE"} onClick={(e) => onSubmit(e)} />
      //   }
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={t("MANAGE_MANAGER")}
      onClose={() => {
        close();
      }}
      style={{ width: "600px" }}
    >
      <Body as="div" className="manageManagerBody" size="Four">
        <div style={{ marginBottom: 50 }}>
          <form onSubmit={addManager}>
            <Textarea
              label={t("ADD_MANAGERS")}
              value={managers?.addManagers}
              onChange={(e) => onInputChange(e.target.value, "addManagers")}
              className="addManagerInput"
              message={errors && t(errors["addManagers"])}
              isInvalid={errors && "addManagers" in errors}
            />
            <Button
              className="manageManagersButton"
              iconPosition="right"
              type="submit"
              size="sm"
              style={{ margin: "12px" }}
              //   onClick={addManager}
            >
              {t("ADD_MANAGERS_BTN")}
            </Button>
          </form>
        </div>
        <div>
          <form onSubmit={removeManager}>
            <Textarea
              label={t("REMOVE_MANAGERS")}
              value={managers?.removeManagers}
              onChange={(e) => onInputChange(e.target.value, "removeManagers")}
              className="removeManagerInput"
              message={errors && t(errors["removeManagers"])}
              isInvalid={errors && "removeManagers" in errors}
              hasCounter={false}
            />
            <Button
              className="manageManagersButton"
              iconPosition="right"
              type="submit"
              size="sm"
              style={{ margin: "12px" }}
              //   onClick={removeManager}
            >
              {t("REMOVE_MANAGERS_BTN")}
            </Button>
          </form>
        </div>
      </Body>
    </Dialog>
  );
};

export default ManageManager;
