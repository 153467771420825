// All apis related to carrier will be here

import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

// Get carrier data
export const _getCarrierData = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.carrierURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _getCarrierBookings = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.slotBookingURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _modifyCarrierBookings = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.slotBookingURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};
