import React, { useEffect, useState } from "react";
import DashboardContainer from "../components/Dashboard/DashboardContainer";
import {
  Dropdown,
  DropdownMenuItem,
  Switch,
  Body,
  Button,
  Dialog,
} from "@postidigital/posti-components";
import PageSublink from "../components/Dashboard/PageSublink";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { classNames } from "primereact/utils";
// import "primeicons/primeicons.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/primereact.css";
// import "primeflex/primeflex.css";
import TimePicker from "@mui/lab/TimePicker";
import Box from "@mui/material/Box";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import PreBookSlotDetails from "../components/BookSlot/PreBookSlotDetails";
import {
  _getDockByFacility,
  _getFacilityData,
  _getPlannedDatesByFacility
} from "../Services/facilityServices";
import {
  _slotPlanning,
  _getStatusOfPlanningWeek,
} from "../Services/wHMServices";
import { showToast, showWarningToast, formateDate } from "../CommomFiles/globalFunctions";
import { useTranslation } from "react-i18next";
import {
  oneDaySlotPlanning,
  slotOptions,
  status,
  generateWeekData,
  getSlotPlanningJson,
} from "../CommomFiles/slotPlanningFunction";
import { useDispatch, useSelector } from "react-redux";
import SlotReplanning from "../components/Planning/SlotReplanning";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DATEFORMAT } from "../config/constant";
import { useNavigate } from "react-router-dom";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import { hideLoader, showLoader } from '../Store/Actions/commonAction';

const orangeTheme = createTheme({
  palette: {
    primary: {
      main: "#E84F1E",
      dark: "#E84F1E",
    },
  },
});

const SlotPlanning = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedFlag, setCheckedFlag] = useState(false);
  const [firstDayFlag, setFirstDayFlag] = useState(false);
  const [weekDaysWarningDialog, setWeekDaysWarningDialog] = useState(false);
  const [unCheckedFlag, setUnCheckedFlag] = useState(false);
  const [currentSelectedCheckBox, setCurrentSelectedCheckBox] = useState(null);
  const [selectedCheckBoxId, setSelectedCheckBoxId] = useState(null);
  const [clearSlotSelection, setClearSlotSelection] = useState(false);
  const [showingDatatableFlag, setShowingDatatableFlag] = useState(false);
  const [activeWeekIndex, setActiveWeekIndex] = useState(0);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [dataTableFlag, setDataTableFlag] = useState(false);
  const [activeDateIndex, setActiveDateIndex] = useState(0);
  const [cellSelectCount, setCellSelectCount] = useState(0);
  const [selectedCellData, setSelectedCellData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [unselectedRowData, setUnselectedRowData] = useState([]);
  const [unselectedRowIndex, setUnselectedRowIndex] = useState(null);
  const [cellSelectFlag, setCellSelectFlag] = useState(false);
  const [preBookDetails, setPreBookDetails] = useState(null);
  const [datesOfTheWeek, setDatesOfTheWeek] = useState([]);
  const [dataForTheWeek, setDataForTheWeek] = useState([]);
  const [preBookModal, setPreBookModal] = useState(false);
  const [checkBoxFlag, setCheckBoxFlag] = useState(false);
  const [checkBoxRowId, setCheckBoxRowId] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [products, setProducts] = useState([]);
  const [preBookToggle, setPreBookToggle] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [tabSelect, setTabSelect] = useState("Planning");
  const [isFacilityopen, setFacilityOpen] = useState(false);
  const [selectedFacilty, setSelectedFacility] = useState(null);
  const [selectedFaciltyName, setSelectedFaciltyName] = useState(null);
  const [isBeforeSlotopen, setBeforeSlotOpen] = useState(false);
  const [slotsBeforeBreak, setSlotsBeforeBreak] = useState(null);
  const [isAfterSlotopen, setAfterSlotOpen] = useState(false);
  const [slotsAfterBreak, setSlotsAfterBreak] = useState(null);
  const [selectStartTime, setSelectStartTime] = React.useState(null);
  const userDetail = useSelector((state) => state.user.userDetail);
  const [selectedFromDate, setselectedFromDate] = useState(null);
  const [selectedToDate, setselectedToDate] = useState(null);
  // Slot planning

  const [uniqueKey, setUniqueKey] = useState("");
  const [uniqueId, setUniqueId] = useState(null);
  const [startTimeForSlot, setStartTimeForSlot] = useState(null);
  const [dockData, setDockData] = useState("");
  const[DisabledDates,setDisabledDates] = useState(new Date());
  
  const startTime = startTimeForSlot;
// Use effects

// Getting dock details
useEffect(() => {
  if (selectedFacilty) {
    _getDockByFacility(
      { facilityId: selectedFacilty, dockStatus: "Active" },
      (res) => {
        if (res.data.length <= 0) {
          showWarningToast(t("NO_DOCKS"));
          setSelectedFacility(null);
          setSelectedFaciltyName(null);
        } else {
          setDockData(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}, [selectedFacilty]);

// Getting facility details
useEffect(() => {
  const params = {
    facilityId:
      userDetail?.facilities?.length > 0
        ? userDetail?.facilities.toString()
        : "",
  };
  dispatch(
    _getFacilityData(
      params,
      (res) => {
        setFacilityOptions(res.data);
      },
      (err) => {
        console.log(err);
      }
    )
  );
}, []);

useEffect(() => {
  handlePrebookCell();
}, [preBookDetails]);

// Slot planning function
const slotPlanning = () => {
  const daySlot = oneDaySlotPlanning(
    startTime,
    slotsBeforeBreak,
    slotsAfterBreak,
    status,
    dockData
  );
  setProducts(daySlot);
  console.log("Solt=>", daySlot)
  generatingDatesOfTheWeek(daySlot);
};

// Plotting checkbox cells
const checkBoxBody = (rowData) => {
  if (rowData.id === parseInt(currentSelectedCheckBox)) {
    return (
      <div className="checkBoxContainer">
        <input
          className="checkBox"
          id="checkBoxId"
          type="checkbox"
          value={rowData.id}
          checked={checkedFlag}
          onChange={(e) => {
            setCheckedFlag(!checkedFlag);
            if (checkedFlag === false) {
              setSelectedCheckBoxId(null);
            }
            if (e.target.checked === false) {
              setUnCheckedFlag(true);
            } else {
              setUnCheckedFlag(false);
            }
            // setUnCheckedFlag(true);
            setSelectedCheckBoxId(e.target.value);
            setCurrentSelectedCheckBox(e.target.value);
            setCheckBoxFlag(true);
            // setPreBookToggle(false);
            // setDeleteToggle(false);
            setCheckBoxRowId(e.target.value);
          }}
        ></input>
      </div>
    );
  }
  return (
    <div className="checkBoxContainer">
      <input
        className="checkBox"
        id="checkBoxId"
        type="checkbox"
        value={rowData.id}
        // checked={false}
        onChange={(e) => {
          setCheckedFlag(!checkedFlag);
          if (checkedFlag === false) {
            setSelectedCheckBoxId(null);
          }
          if (e.target.checked === false) {
            setUnCheckedFlag(true);
          } else {
            setUnCheckedFlag(false);
          }

          const tempObject = [
            ...dataForTheWeek[activeWeekIndex]?.slotPlanningData,
          ];

          if (tempObject[e.target.value - 1] !== undefined) {
            for (
              let i = 0;
              i < Object.keys(tempObject[e.target.value - 1]).length;
              i++
            ) {
              if (
                Object.keys(tempObject[e.target.value - 1])[i] !== "id" &&
                Object.keys(tempObject[e.target.value - 1])[i] !== "dock" &&
                Object.keys(tempObject[e.target.value - 1])[i] !== "break" &&
                Object.keys(tempObject[e.target.value - 1])[i] !== "dockId"
              ) {
                // console.log(
                //   "Data type",
                //   Object.keys(tempObject[e.target.value - 1])[i]
                // );
                if (
                  tempObject[e.target.value - 1][
                    Object.keys(tempObject[e.target.value - 1])[i]
                  ].status === status[1]
                ) {
                  setPreBookToggle(true);
                  setDeleteToggle(false);
                } else if (
                  tempObject[e.target.value - 1][
                    Object.keys(tempObject[e.target.value - 1])[i]
                  ].status === status[2]
                ) {
                  setDeleteToggle(true);
                  setPreBookToggle(false);
                } else if (
                  tempObject[e.target.value - 1][
                    Object.keys(tempObject[e.target.value - 1])[i]
                  ].status === status[0]
                ) {
                  setPreBookToggle(false);
                  setDeleteToggle(false);
                }
              }
            }
          }

          setSelectedCheckBoxId(e.target.value);
          setCurrentSelectedCheckBox(e.target.value);
          setCheckBoxFlag(true);
          // setPreBookToggle(false);
          // setDeleteToggle(false);
          setCheckBoxRowId(e.target.value);
        }}
      ></input>
    </div>
  );
};

// Plotting break cells
const breakBody = (rowData) => {
  const stockClassName = classNames({
    breakRow: rowData.break.from,
  });
  return (
    <div className={stockClassName}>
      <div>Break</div>
      <div>
        {rowData.break.from}-{rowData.break.to}
      </div>
    </div>
  );
};

// Plotting slot cells
const slotBody = (rowData, key) => {
  const value = rowData[key];
  let slotClassName;
  if (value.status === status[1]) {
    slotClassName = classNames({
      preBookRow: rowData,
    });
  } else if (value.status === status[0]) {
    slotClassName = classNames({
      availableRow: rowData,
    });
  } else {
    slotClassName = classNames({
      deletedRow: rowData,
    });
  }

  return <div className={slotClassName}>{value.from}</div>;
};

// plotting dock cells
const dockBody = (rowData) => {
  const stockClassName = classNames({
    dockRow: rowData.dock,
  });

  return <div className={stockClassName}>{rowData.dock}</div>;
};

// dynamic column function
const dynamicColumns =
  products &&
  products.length > 0 &&
  Object.entries(products[0]).map(([key, value]) => {
    if (key === "id") {
      return (
        <Column
          // selectionMode="multiple"
          className="checkBoxColumn"
          key={`${key}.from`}
          field={`${key}.from`}
          header=""
          onRowClick={(e) => { }}
          // selection={false}
          body={(e) => checkBoxBody(e)}
        ></Column>
      );
    }
    if (key !== "id") {
      if (key === "dock") {
        return (
          <Column
            // selectionMode="multiple"
            onselectionchange={(e) => { }}
            // headerStyle={{ width: "3em" }}
            className="dockColumns"
            key={key}
            field={key}
            header=""
            body={dockBody}
          />
        );
      } else if (key === "break") {
        return (
          <Column
            className="breakColumns"
            key={`${key}.from`}
            field={`${key}.from`}
            header=""
            body={breakBody}
          />
        );
      } else if (key === "dockId") {
        // console.log("Key", key);
      } else {
        return (
          <Column
            className="tableColumns2"
            key={`${key}.from`}
            field={`${key}.from`}
            header=""
            // tabIndex="0"
            body={(e) => slotBody(e, key)}
          />
        );
      }
    }
    // return 0;
  });

// Cell select function
const onCellSelect = (event) => {
  if (
    event.field.split(".")[0] === "id" ||
    event.field.split(".")[0] === "dock" ||
    event.field.split(".")[0] === "break"
  ) {
    // console.log("Event", event.field.split(".")[0]);
  } else {
    const selectedSlotsArray = selectedCellData;
    const selectedRowsArray = selectedRowData;
    setCellSelectCount(cellSelectCount + 1);
    setCellSelectFlag(true);
    const slotName = event.field.split(".")[0];
    setCheckBoxFlag(false);
    if (event.rowData[slotName]["status"] === status[1]) {
      setPreBookToggle(true);
    } else {
      setPreBookToggle(false);
    }
    if (event.rowData[slotName]["status"] === status[2]) {
      setDeleteToggle(true);
    } else {
      setDeleteToggle(false);
    }
    console.log(
      "Cell selected",
      event,
      event.field.split(".")[0],
      event.rowIndex,
      event.rowData[slotName]["status"]
    );

    setUniqueKey(event.field.split(".")[0]);
    setUniqueId(event.rowIndex);
    const selectedSlotName = event.field.split(".")[0];
    selectedSlotsArray.push(selectedSlotName);
    setSelectedCellData(selectedSlotsArray);

    const selectedRowIndex = event.rowIndex;
    selectedRowsArray.push(selectedRowIndex);
    setSelectedRowData(selectedRowsArray);

    console.log("selected row data", selectedRowData);
    console.log("selected cell data", selectedCellData);
  }
};

// Cell unselect function
const onCellUnselect = (event) => {
  if (
    event.field.substring(0, 5) === "dock" ||
    event.field.substring(0, 5) === "id.fr" ||
    event.field.substring(0, 5) === "id" ||
    event.field.substring(0, 5) === "break"
  ) {
    // console.log("Event",  event.field.substring(0, 5));
  } else {
    const unselectedRow = event.rowIndex;
    const unselectedSlot = event.field.substring(0, 5);
    let unselectSameCellCount = 0;
    console.log("Unselect", event.rowIndex);
    console.log("Unselect cell", event.field.substring(0, 5));

    setUnselectedRowIndex(event.rowIndex);
    unselectedRowData.push(event.field.substring(0, 5));
    console.log("Unselect cell rowdata", unselectedRowData);

    for (let j = 0; j < selectedRowData.length; j++) {
      if (selectedRowData[j] === unselectedRow) {
        selectedRowData.splice(j, 1);
        setUniqueId(selectedRowData[j - 1]);
      }

      for (let i = 0; i < selectedCellData.length; i++) {
        if (selectedCellData[i] === unselectedSlot) {
          unselectSameCellCount++;
          if (unselectSameCellCount < 1) {
            selectedCellData.splice(i, 1);
          }
        }
      }
    }

    let removingSlotCount = 0;
    for (let i = 0; i < selectedCellData.length; i++) {
      if (selectedCellData[i] === unselectedSlot) {
        if (removingSlotCount < 1) {
          selectedCellData.splice(i, 1);
          removingSlotCount++;
        }
      }
    }

    console.log("selected cell data updated", selectedCellData);
    console.log("selected row data updated", selectedRowData);
  }
};

// Function for handling prebook before opening pre-book detail form
const handlePrebookCellBeforeForm = () => {
  if (deleteToggle === true) {
    showWarningToast(t("WARNING_PREBOOKING_DELETED_SLOTS"));
    setSelectedCellData([]);
    setSelectedRowData([]);
    setUnselectedRowData([]);
    setUnselectedRowIndex(null);
    setCellSelectCount(0);
    setCurrentSelectedCheckBox(null);
  } else {
    let multipleRowFlag = false;
    let nonAdjacentSlotFlag = false;

    console.log("rows data selected======>", selectedRowData);

    for (let k = 0; k < selectedRowData.length; k++) {
      if (k >= 1) {
        if (
          parseInt(selectedRowData[k]) - parseInt(selectedRowData[k - 1]) !==
          0
        ) {
          multipleRowFlag = true;
        }
      }
    }

    let tempCellSelectedArray = [];
    for (let i = 0; i < selectedCellData.length; i++) {
      console.log(selectedCellData[i].substring(5, 4));
      tempCellSelectedArray.push(
        parseInt(selectedCellData[i].substring(5, 4))
      );
    }
    tempCellSelectedArray = tempCellSelectedArray.sort();

    for (let j = 0; j < tempCellSelectedArray.length; j++) {
      if (j >= 1) {
        if (tempCellSelectedArray[j] - tempCellSelectedArray[j - 1] !== 1) {
          nonAdjacentSlotFlag = true;
        }
      }
    }

    if (multipleRowFlag === true) {
      showWarningToast(t("SAME_DOCK_WARNING"));
    } else if (nonAdjacentSlotFlag === true) {
      showWarningToast(t("ADJACENT_SLOT_WARNING"));
    } else {
      console.log("sorted", tempCellSelectedArray);
      if (selectedCellData.length <= 1 && checkBoxFlag === false) {
        // showWarningToast(t("SELECT_MORE"));
        showWarningToast(t("SELECT_AT_LEAST_TWO_SLOT_WARNING"));
      } else {
        if (cellSelectFlag === false && checkBoxFlag === false) {
          showWarningToast(t("SELECT_SLOT_WARNING"));
        } else if (preBookToggle === true) {
          if (checkedFlag === true) {
            handleCheckedAvailableCell();
          } else {
            if (unselectedRowData.length > 1) {
              handleMultipleAvailableCell();
            } else {
              handleAvailableCell();
            }
          }
        } else {
          setPreBookModal(true);
        }
        console.log("Cell count", cellSelectCount);
      }
    }
  }
};

// Function for prebooking slot
const handlePrebookCell = () => {
  if (preBookDetails != null) {
    if (checkBoxFlag === true) {
      if (dockData.length === parseInt(checkBoxRowId)) {
        setPreBookToggle(true);
        const tempObject = [
          ...dataForTheWeek[activeWeekIndex].slotPlanningData,
        ];
        for (
          let i = 0;
          i < Object.keys(tempObject[checkBoxRowId - 1]).length;
          i++
        ) {
          if (
            Object.keys(tempObject[checkBoxRowId - 1])[i] !== "id" &&
            Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dock" &&
            Object.keys(tempObject[checkBoxRowId - 1])[i] !== "break" &&
            Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dockId"
          ) {
            tempObject[checkBoxRowId - 1][
              Object.keys(tempObject[checkBoxRowId - 1])[i]
            ]["status"] = status[1];
            const newPrebookDetails = JSON.parse(
              JSON.stringify(preBookDetails)
            );
            tempObject[checkBoxRowId - 1][
              Object.keys(tempObject[checkBoxRowId - 1])[i]
            ]["preBookingDetails"] = newPrebookDetails;
            // setProducts(tempObject);

            const currentDateData = [...dataForTheWeek];
            currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
            setDataForTheWeek(currentDateData);
          }
        }
      } else {
        setPreBookToggle(true);
        const tempObject = [
          ...dataForTheWeek[activeWeekIndex].slotPlanningData,
        ];

        for (
          let i = 0;
          i < Object.keys(tempObject[checkBoxRowId]).length;
          i++
        ) {
          if (
            Object.keys(tempObject[checkBoxRowId])[i] !== "id" &&
            Object.keys(tempObject[checkBoxRowId])[i] !== "dock" &&
            Object.keys(tempObject[checkBoxRowId])[i] !== "break" &&
            Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dockId"
          ) {
            tempObject[checkBoxRowId - 1][
              Object.keys(tempObject[checkBoxRowId])[i]
            ]["status"] = status[1];
            const newPrebookDetails = JSON.parse(
              JSON.stringify(preBookDetails)
            );
            tempObject[checkBoxRowId - 1][
              Object.keys(tempObject[checkBoxRowId])[i]
            ]["preBookingDetails"] = newPrebookDetails;

            const currentDateData = [...dataForTheWeek];
            currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
            setDataForTheWeek(currentDateData);
          }
        }
      }
      setSelectedCellData([]);
      setSelectedRowData([]);
      setUnselectedRowData([]);
      setUnselectedRowIndex(null);
      setCheckBoxFlag(false);
    } else if (cellSelectCount > 1) {
      for (let i = 0; i < selectedCellData.length; i++) {
        // selectedCellData[i];

        setPreBookToggle(true);
        const tempObject = [
          ...dataForTheWeek[activeWeekIndex].slotPlanningData,
        ];

        tempObject[uniqueId][selectedCellData[i].toString()]["status"] =
          status[1];
        const newPrebookDetails = JSON.parse(JSON.stringify(preBookDetails));
        tempObject[uniqueId][selectedCellData[i].toString()][
          "preBookingDetails"
        ] = newPrebookDetails;

        const currentDateData = [...dataForTheWeek];
        currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
        setDataForTheWeek(currentDateData);
      }
      setSelectedCellData([]);
      setSelectedRowData([]);
      setUnselectedRowData([]);
      setUnselectedRowIndex(null);
    } else {
      setPreBookToggle(true);
      const tempObject = [
        ...dataForTheWeek[activeWeekIndex].slotPlanningData,
      ];
      tempObject[uniqueId][uniqueKey.toString()]["status"] = status[1];
      const newPrebookDetails = JSON.parse(JSON.stringify(preBookDetails));
      tempObject[uniqueId][uniqueKey.toString()]["preBookingDetails"] =
        newPrebookDetails;

      // setProducts(tempObject);
      setSelectedCellData([]);
      setSelectedRowData([]);
      setUnselectedRowData([]);
      setUnselectedRowIndex(null);

      const currentDateData = [...dataForTheWeek];
      currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
      setDataForTheWeek(currentDateData);
    }
  }
  setCellSelectCount(0);
  setCheckedFlag(false);
  setCurrentSelectedCheckBox(null);
  setPreBookDetails(null);
};

// Function for deleting slot
const handleDeleteCell = () => {
  // showToast(t("SLOT_DELETED"));
  let flagForMessage = true;
  if (preBookToggle === true) {
    showWarningToast(t("WARNING_DELETING_PREBOOKED_SLOTS"));
    setSelectedCellData([]);
    setSelectedRowData([]);
    setUnselectedRowData([]);
    setUnselectedRowIndex(null);
    setCurrentSelectedCheckBox(null);
    setCellSelectCount(0);
  } else {
    let multipleRowFlag = false;
    let nonAdjacentSlotFlag = false;

    console.log("rows data selected======>", selectedRowData);

    for (let k = 0; k < selectedRowData.length; k++) {
      if (k >= 1) {
        if (
          parseInt(selectedRowData[k]) - parseInt(selectedRowData[k - 1]) !==
          0
        ) {
          multipleRowFlag = true;
        }
      }
    }

    let tempCellSelectedArray = [];
    for (let i = 0; i < selectedCellData.length; i++) {
      console.log(selectedCellData[i].substring(5, 4));
      tempCellSelectedArray.push(
        parseInt(selectedCellData[i].substring(5, 4))
      );
    }
    tempCellSelectedArray = tempCellSelectedArray.sort();

    for (let j = 0; j < tempCellSelectedArray.length; j++) {
      if (j >= 1) {
        if (tempCellSelectedArray[j] - tempCellSelectedArray[j - 1] !== 1) {
          nonAdjacentSlotFlag = true;
        }
      }
    }

    if (multipleRowFlag === true) {
      showWarningToast(t("SAME_DOCK_WARNING"));
      flagForMessage = false;
    } else if (nonAdjacentSlotFlag === true) {
      showWarningToast(t("ADJACENT_SLOT_WARNING"));
    } else {
      // Deleting whole slots of dock
      if (checkBoxFlag === true) {
        // showToast(t("SLOTS_DELETED"));

        if (dockData.length === parseInt(checkBoxRowId)) {
          setDeleteToggle(true);
          const tempObject = [
            ...dataForTheWeek[activeWeekIndex].slotPlanningData,
          ];
          for (
            let i = 0;
            i < Object.keys(tempObject[checkBoxRowId - 1]).length;
            i++
          ) {
            if (
              Object.keys(tempObject[checkBoxRowId - 1])[i] !== "id" &&
              Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dock" &&
              Object.keys(tempObject[checkBoxRowId - 1])[i] !== "break" &&
              Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dockId"
            ) {
              tempObject[checkBoxRowId - 1][
                Object.keys(tempObject[checkBoxRowId - 1])[i]
              ]["status"] = status[2];
              // setProducts(tempObject);

              const currentDateData = [...dataForTheWeek];
              currentDateData[activeWeekIndex]["slotPlanningData"] =
                tempObject;
              setDataForTheWeek(currentDateData);
            }
          }
        } else {
          setDeleteToggle(true);
          const tempObject = [
            ...dataForTheWeek[activeWeekIndex].slotPlanningData,
          ];
          for (
            let i = 0;
            i < Object.keys(tempObject[checkBoxRowId]).length;
            i++
          ) {
            if (
              Object.keys(tempObject[checkBoxRowId])[i] !== "id" &&
              Object.keys(tempObject[checkBoxRowId])[i] !== "dock" &&
              Object.keys(tempObject[checkBoxRowId])[i] !== "break" &&
              Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dockId"
            ) {
              tempObject[checkBoxRowId - 1][
                Object.keys(tempObject[checkBoxRowId])[i]
              ]["status"] = status[2];
              setProducts(tempObject);

              const currentDateData = [...dataForTheWeek];
              currentDateData[activeWeekIndex]["slotPlanningData"] =
                tempObject;
              setDataForTheWeek(currentDateData);
            }
          }
        }
        setSelectedCellData([]);
        setSelectedRowData([]);
        // showToast(t("SLOT_DELETED") + 1);
      }
      console.log("sorted", tempCellSelectedArray);
      if (cellSelectFlag === false && checkBoxFlag === false) {
        flagForMessage = false;
        showWarningToast(t("SELECT_SLOT_WARNING"));
      } else if (deleteToggle === true) {
        if (unselectedRowData.length > 1) {
          handleMultipleAvailableCell();
          flagForMessage = false;
        } else if (checkBoxFlag === true) {
          handleCheckedAvailableCell();
          flagForMessage = false;
        } else {
          handleAvailableCell();
          flagForMessage = false;
        }
      } else {
        if (cellSelectFlag === false) {
          if (checkBoxFlag === false) {
            flagForMessage = false;
            showWarningToast(t("SELECT_SLOT_WARNING"));
          }
        } else {
          if (cellSelectCount > 1) {
            // Deleting multiple slots
            if (selectedCellData.length < 1 && checkBoxFlag === false) {
              // setSlotDeletedWarningFlag(true);
              flagForMessage = false;
              showWarningToast(t("SELECT_SLOT_WARNING"));
            } else {
              flagForMessage = false;
              showToast(t("SLOT_DELETED"));
              setDeleteToggle(true);
              for (let i = 0; i < selectedCellData.length; i++) {
                // selectedCellData[i];
                const tempObject = [
                  ...dataForTheWeek[activeWeekIndex].slotPlanningData,
                ];
                tempObject[uniqueId][selectedCellData[i].toString()][
                  "status"
                ] = status[2];

                // setProducts(tempObject);
                const currentDateData = [...dataForTheWeek];
                currentDateData[activeWeekIndex]["slotPlanningData"] =
                  tempObject;
                setDataForTheWeek(currentDateData);
              }
              setSelectedCellData([]);
              setSelectedRowData([]);
              setUnselectedRowData([]);
              setUnselectedRowIndex(null);
            }
          } else {
            // Deleting single slot
            if (selectedCellData.length >= 1) {
              flagForMessage = false;
              showToast(t("SLOT_DELETED"));
              setDeleteToggle(true);
              const tempObject = [
                ...dataForTheWeek[activeWeekIndex].slotPlanningData,
              ];

              tempObject[uniqueId][uniqueKey.toString()]["status"] =
                status[2];

              // setProducts(tempObject);

              const currentDateData = [...dataForTheWeek];
              currentDateData[activeWeekIndex]["slotPlanningData"] =
                tempObject;
              setDataForTheWeek(currentDateData);
              setSelectedCellData([]);
              setSelectedRowData([]);
              setUnselectedRowData([]);
              setUnselectedRowIndex(null);
            } else {
              //  setSlotDeletedWarningFlag(true);

              if (checkBoxFlag === false) {
                showWarningToast(t("SELECT_SLOT_WARNING"));
              }
            }
          }
        }
        setCellSelectCount(0);
      }
      console.log("Cell count", cellSelectCount);
    }
    setCurrentSelectedCheckBox(null);
  }
  if (selectedCellData.length < 1 && checkBoxFlag === false) {
    flagForMessage = false;
  }
  if (flagForMessage === true) {
    showToast(t("SLOT_DELETED"));
  }
  setCheckedFlag(false);
};

// Setting start time of slots
const handleStartTime = (newequals) => {
  const modifiedTime = moment(newequals).isValid() // checked if selected date is valid or not
    ? moment(newequals).format("HH:mm")
    : null; // if not setting null
  setStartTimeForSlot(modifiedTime);
  setSelectStartTime(newequals);
};

// Setting slots after break
const handleSlotAfterBreak = (e) => {
  setSlotsAfterBreak(e);
};

// Function for generating multiple dates
const generatingDatesOfTheWeek = (slotsObject) => {
  const weekDates = datesOfTheWeek;
  var planningStartDate = new Date(selectedFromDate),
    planningEndDate = new Date(selectedToDate),
    isWeekend = false;
  if (planningStartDate !== null && planningEndDate !== null) {
    weekDates.length = 0
    while (planningStartDate <= planningEndDate) {
      var day = planningStartDate.getDay();
      isWeekend = (day === 6) || (day === 0);
      if (!isWeekend) {
        console.log("Days List", planningStartDate.getFullYear() + '-' + (convertDateFormat(planningStartDate.getMonth() + 1)) + '-' + convertDateFormat(planningStartDate.getDate()))
        weekDates.push(planningStartDate.getFullYear() + '-' + (convertDateFormat(planningStartDate.getMonth() + 1)) + '-' + convertDateFormat(planningStartDate.getDate()));
      }
      planningStartDate.setDate(planningStartDate.getDate() + 1);
    }
  }
  planningStartDate = "";
  planningEndDate = "";
  setDatesOfTheWeek(weekDates);
  generatingDataForTheWeek(slotsObject);
};

// Function for generating full week data
const generatingDataForTheWeek = (slots) => {
  const weekArray = generateWeekData(datesOfTheWeek, slots);
  setDataForTheWeek(weekArray);
  // console.log("Week data", weekArray);
};

// Function for handling date change
const onDateChange = (date, index) => {
  setClearSlotSelection(true);
  setCheckedFlag(false);
  setActiveWeekIndex(index);
  setActiveDateIndex(index);
  setSelectedCellData([]);
  setSelectedRowData([]);
  setPreBookToggle(false);
  setDeleteToggle(false);
  setCheckBoxFlag(false);
  setCurrentSelectedCheckBox(null);
  setCellSelectFlag(false);
  setUnCheckedFlag(true);
};

// Function for handling start planning
const handleStartPlanning = () => {
  setFirstDayFlag(true);
  if (
    slotsAfterBreak != null &&
    slotsBeforeBreak != null &&
    startTimeForSlot != null &&
    selectedFacilty != null &&
    selectedFromDate !== null &&
    selectedToDate !== null
  ) {
    let firstDayOfTheWeek = moment(selectedFromDate).format("DDMMYY");
    let lastDayOfTheWeek = moment(selectedToDate).format("DDMMYY");
    let planningRequestId = `REQ-${firstDayOfTheWeek}-${lastDayOfTheWeek}-${selectedFacilty}`;
    //Checking status of planning week
    dispatch(
      _getStatusOfPlanningWeek(
        { requestId: planningRequestId,
          from_date: new Date(selectedFromDate).getFullYear() + '-' + (convertDateFormat(new Date(selectedFromDate).getMonth() + 1)) + '-' + convertDateFormat(new Date(selectedFromDate).getDate()),
          to_date: new Date(selectedToDate).getFullYear() + '-' + (convertDateFormat(new Date(selectedToDate).getMonth() + 1)) + '-' + convertDateFormat(new Date(selectedToDate).getDate()),
          facilityId: selectedFacilty
        },
        (res) => {
          console.log("Start Planning Response",res)
          slotPlanning();
          setDataTableFlag(true);
          setShowingDatatableFlag(true);
        },
        (err) => {
          console.log(err);
          setShowingDatatableFlag(false);
        }
      )
    );
  } else {
    showWarningToast(t("SELECT_FIELDS_WARNING"));
  }
  setSelectedCellData([]);
  setSelectedRowData([]);
};

// Function for making available slot available
const handleAvailableCell = () => {
  setPreBookToggle(false);
  setDeleteToggle(false);
  const tempObject = [...dataForTheWeek[activeWeekIndex].slotPlanningData];
  tempObject[uniqueId][uniqueKey.toString()]["status"] = status[0];
  delete tempObject[uniqueId][uniqueKey.toString()]["preBookingDetails"];
  // tempObject[uniqueId][uniqueKey.toString()]["preBookingDetails"] = {};
  // setProducts(tempObject);
  setSelectedCellData([]);
  setSelectedRowData([]);
  setUnselectedRowData([]);
  setUnselectedRowIndex(null);
  const currentDateData = [...dataForTheWeek];
  currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
  setDataForTheWeek(currentDateData);
  showToast(t("SLOT_AVAILABLE"));
  setClearSlotSelection(true);
};

// Function for making available slot available
const handleMultipleAvailableCell = () => {
  setPreBookToggle(false);
  setDeleteToggle(false);

  for (let i = 0; i < unselectedRowData.length; i++) {
    // selectedCellData[i];
    const tempObject = [...dataForTheWeek[activeWeekIndex].slotPlanningData];
    tempObject[unselectedRowIndex][unselectedRowData[i].toString()][
      "status"
    ] = status[0];
    delete tempObject[unselectedRowIndex][uniqueKey.toString()][
      "preBookingDetails"
    ];

    // setProducts(tempObject);
    const currentDateData = [...dataForTheWeek];
    currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
    setDataForTheWeek(currentDateData);
  }

  setSelectedCellData([]);
  setSelectedRowData([]);
  setUnselectedRowData([]);
  setUnselectedRowIndex(null);

  showToast(t("SLOT_AVAILABLE"));
};

// Function for making available slot available
const handleCheckedAvailableCell = () => {
  setDeleteToggle(false);
  setPreBookToggle(false);
  const tempObject = [...dataForTheWeek[activeWeekIndex].slotPlanningData];
  for (
    let i = 0;
    i < Object.keys(tempObject[checkBoxRowId - 1]).length;
    i++
  ) {
    if (
      Object.keys(tempObject[checkBoxRowId - 1])[i] !== "id" &&
      Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dock" &&
      Object.keys(tempObject[checkBoxRowId - 1])[i] !== "break" &&
      Object.keys(tempObject[checkBoxRowId - 1])[i] !== "dockId"
    ) {
      tempObject[checkBoxRowId - 1][
        Object.keys(tempObject[checkBoxRowId - 1])[i]
      ]["status"] = status[0];
      setProducts(tempObject);

      const currentDateData = [...dataForTheWeek];
      currentDateData[activeWeekIndex]["slotPlanningData"] = tempObject;
      setDataForTheWeek(currentDateData);
    }
  }
  setUnCheckedFlag(true);
  setSelectedCellData([]);
  setSelectedRowData([]);
  setUnselectedRowData([]);
  setUnselectedRowIndex(null);
  setCheckedFlag(false);
  showToast(t("SLOT_AVAILABLE"));
};
// Function for generating final JSON
const weekDataJsonGenerator = () => {
  if (
    firstDayFlag
  ) {
    const slotPlanningJson = getSlotPlanningJson(
      selectedFacilty,
      dataForTheWeek,
      status
    );
    dispatch(
      _slotPlanning(
        slotPlanningJson,
        (res) => {
          // showToast(t("SLOT_PLANNED_SUCCESS"));
          showToast(t("REQUEST_ID"));
          // console.log("Success", res.data.detail);
          navigate("/");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    // console.log("Json", slotPlanningJson);
    setWeekDaysWarningDialog(false);
  } else {
    setWeekDaysWarningDialog(true);
  }
};

const rowclass = (data) => {
  return {
    checkedRow: data.id === parseInt(selectedCheckBoxId),
  };
};


// Function for disabling dates in calender
const checkOutSideFromDate = (day) => {
  const today = moment();
  if(DisabledDates.length !== 0){
    return (
      moment(formateDate(day)).isSame(formateDate(today), "day") ||
      moment(formateDate(day)).isBefore(formateDate(today), "day") ||
      moment(formateDate(day)).isAfter(
        formateDate((today).add(30, "days"))
        ,
        "day"
      )
      || !(day.day() !== 0 && day.day() !== 6)
      ||  DisabledDates.includes(new Date(day).getFullYear() + '-' + (convertDateFormat(new Date(day).getMonth() + 1)) + '-' + convertDateFormat(new Date(day).getDate()))
  
    );
  }
  else{
    return (
      moment(formateDate(day)).isSame(formateDate(today), "day") ||
      moment(formateDate(day)).isBefore(formateDate(today), "day") ||
      moment(formateDate(day)).isAfter(
        formateDate((today).add(30, "days"))
        ,
        "day"
      )
      || !(day.day() !== 0 && day.day() !== 6)
  
    );
  }
  
};

// Function for disabling dates in calender
const checkOutSideToDate = (day) => {
  const today = selectedFromDate !== null ? moment(selectedFromDate) : moment();
  if(DisabledDates.length !== 0){
  return (
    moment(formateDate(day)).isBefore(formateDate(today), "day") ||
    moment(formateDate(day)).isAfter(
      formateDate((today).add(30, "days"))
      ,
      "day"
    )
    || !(day.day() !== 0 && day.day() !== 6)
    ||  DisabledDates.includes(new Date(day).getFullYear() + '-' + (convertDateFormat(new Date(day).getMonth() + 1)) + '-' + convertDateFormat(new Date(day).getDate()))

  );
    }
    else{
      return (
        moment(formateDate(day)).isBefore(formateDate(today), "day") ||
        moment(formateDate(day)).isAfter(
          formateDate((today).add(30, "days"))
          ,
          "day"
        )
        || !(day.day() !== 0 && day.day() !== 6)
    
      );
    }

};
//function to add '0' in single digit month (ex:01,02)
const convertDateFormat = (data) => {
  let newData = 0
  if (data < 10) {
    newData = '0' + data;
    return newData
  }
  else {
    return data
  }
}

const getAlreadyPlannedDates =(facilityId)=>{
  dispatch(showLoader());
  if (facilityId) {
    _getPlannedDatesByFacility(
      { facilityId: facilityId},
      (res) => {
        console.log("response Already",res.data)
        if (res.data.length > 0) {
          res.data.map(dateItem =>{
            getAlreadyAllocatedDates(dateItem.planStartDate, dateItem.planEndDate);
          })
          dispatch(hideLoader());
        }
        else{
          dispatch(hideLoader());
        }
      },
      (err) => {
        console.log(err);
        dispatch(hideLoader());
      }
    );
  }
  
};

const getAlreadyAllocatedDates = (startDate, stopDate)=> {
  var currentDate = new Date(startDate);
  while (currentDate <= new Date(stopDate)) {
     var tempDate= "";
     tempDate = currentDate.getFullYear() + '-' + (convertDateFormat(currentDate.getMonth() + 1)) + '-' + convertDateFormat(currentDate.getDate());
      setDisabledDates(DisabledDates => DisabledDates.concat(tempDate));
      currentDate.setDate(currentDate.getDate() + 1);
      tempDate ="";
  }
}

const dateToMonthDayFormat=(date)=> {
  var strArray=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    var dateParam = date.getDate();
    var monthParam = strArray[date.getMonth()];
    return  monthParam + '\n' + (dateParam <= 9 ? '0' + dateParam : dateParam);
}

return (
  <DashboardContainer>
    <div className="slot-planning-page">
      <PageSublink pageName={t("SLOT_PLANNING_TITLE")} />
      <h2 className="slot_planning_title">{t("SLOT_PLANNING_TITLE")}</h2>
      <div className="tabs">
        <div
          className={tabSelect === "Planning" ? "planning" : "disablePlan"}
          onClick={() => {
            setTabSelect("Planning");
          }}
        >
          {t("SLOT_PLANNING_PLANNING_TITLE")}
        </div>
        <div
          className={
            tabSelect === "Replanning" ? "replanning" : "disableReplan"
          }
          onClick={() => {
            setTabSelect("Replanning");
          }}
        >
          {t("REPLANNING")}
        </div>
      </div>
      <div
        className={
          showingDatatableFlag === true ? "tabContent" : "smallTabContent"
        }
      >
        {tabSelect === "Planning" ? (
          <div className="planningSection">
            <div className="PlanningUpperSection">
              <div className="selectorDivision">
                <div className="selectedFaciltyDropdown">
                  <Dropdown
                    isOpen={isFacilityopen}
                    title={selectedFaciltyName}
                    iconColor="#73808C"
                    label={t("SLOT_PLANNING_SELECT_FACILITY")}
                    menuAriaLabel="Select facility"
                    message=""
                    onSelect={(e) => {
                      setSelectedFacility(e.facilityId);
                      setSelectedFaciltyName(e.siteName);
                      setDisabledDates([]);
                      getAlreadyPlannedDates(e.facilityId)
                    }}
                    onToggle={() =>
                      setFacilityOpen((isFacilityopen) => !isFacilityopen)
                    }
                    overflowMenuContainerDirection="right"
                    className="selectedFaciltyDropdownButton"
                  >
                    {facilityOptions?.map((item, index) => {
                      return (
                        <DropdownMenuItem
                          allowWrap
                          aria-label="Menu item"
                          tabIndex={index}
                          value={item}
                          selected={selectedFacilty === item.facilityId}
                          key={item.facilityId}
                          className="dropdownMenuItem"
                        >
                          {item.siteName}
                        </DropdownMenuItem>
                      );
                    })}
                  </Dropdown>
                </div>

                <div className="dateDivision">
                  <Box
                    className="dateDivisionContainer"
                    sx={{ p: 2, backgroundColor: "grey" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="upcomingFromDate">
                        <ThemeProvider theme={orangeTheme}>
                          <div className="datePickerDivision">
                            <DateInput
                              firstDayOfWeek={0}
                              displayFormat={DATEFORMAT}
                              id="slotPlanningFromDate"
                              label={t("From")}
                              locale="en"
                              nextMonthButtonAriaLabel="Move to next month"
                              prevMonthButtonAriaLabel="Move to previous month"
                              onDateChange={(date) => {
                                setselectedFromDate(null); setselectedFromDate(date); setselectedToDate(null);
                              }}
                              date={selectedFromDate ? moment(selectedFromDate) : ""}
                              className="slotPlanningFrom"
                              onTouchCancel={false}
                              isOutsideRange={checkOutSideFromDate}
                              disabled={selectedFacilty === null}
                            />
                          </div>
                        </ThemeProvider>
                      </div>
                    </LocalizationProvider>

                  </Box>
                </div>
                <div className="dateDivisionToSection">
                  <Box
                    className="dateDivisionContainer"
                    sx={{ p: 2, backgroundColor: "grey" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="upcomingToDate">
                        <ThemeProvider theme={orangeTheme}>
                          <div className="datePickerDivision">
                            <DateInput
                              firstDayOfWeek={0}
                              displayFormat={DATEFORMAT}
                              id="slotPlanningTo"
                              label={t("To")}
                              locale="en"
                              nextMonthButtonAriaLabel="Move to next month"
                              prevMonthButtonAriaLabel="Move to previous month"
                              className="slotPlanningTo"
                              onTouchCancel={false}
                              isOutsideRange={checkOutSideToDate}
                              onDateChange={(date) => {
                                setselectedToDate(date);
                              }}
                              date={selectedToDate ? moment(selectedToDate) : ""}
                              disabled={selectedFromDate === null}
                            />
                          </div>
                        </ThemeProvider>
                      </div>
                    </LocalizationProvider>

                  </Box>
                </div>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="timeDivision">
                    <ThemeProvider theme={orangeTheme}>
                      <TimePicker
                        className="timePicker"
                        label={t("SLOT_PLANNING_TIME_TITLE")}
                        value={selectStartTime}
                        onChange={handleStartTime}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </ThemeProvider>
                  </div>
                </LocalizationProvider>
                <div className="slotsBeforeBreakDropdown">
                  <Dropdown
                    isOpen={isBeforeSlotopen}
                    title={slotsBeforeBreak}
                    iconColor="#73808C"
                    label={t("SLOT_PLANNING_BEFORE_BREAK_TITLE")}
                    menuAriaLabel={t("SLOT_PLANNING_BEFORE_BREAK_TITLE")}
                    message=""
                    onSelect={(e) => {
                      setSlotsBeforeBreak(e);
                    }}
                    onToggle={() =>
                      setBeforeSlotOpen(
                        (isBeforeSlotopen) => !isBeforeSlotopen
                      )
                    }
                    overflowMenuContainerDirection="right"
                    className="selectedSlotsDropdownButton"
                  >
                    {slotOptions.map((item, index) => {
                      return (
                        <DropdownMenuItem
                          allowWrap
                          aria-label="Menu item"
                          tabIndex={index}
                          value={item.value}
                          selected={slotsBeforeBreak === item.value}
                          className="dropdownMenuItem"
                          key={item.value}
                        >
                          {item.label}
                        </DropdownMenuItem>
                      );
                    })}
                  </Dropdown>
                </div>

                <div className="slotsAfterBreakDropdown">
                  <Dropdown
                    isOpen={isAfterSlotopen}
                    title={slotsAfterBreak}
                    iconColor="#73808C"
                    label={t("SLOT_PLANNING_AFTER_BREAK_TITLE")}
                    menuAriaLabel={t("SLOT_PLANNING_AFTER_BREAK_TITLE")}
                    message=""
                    onSelect={(e) => {
                      handleSlotAfterBreak(e);
                    }}
                    onToggle={() =>
                      setAfterSlotOpen((isAfterSlotopen) => !isAfterSlotopen)
                    }
                    overflowMenuContainerDirection="right"
                    className="selectedSlotsAfterDropdownButton"
                  >
                    {slotOptions.map((item, index) => {
                      return (
                        <DropdownMenuItem
                          allowWrap
                          aria-label="Menu item"
                          tabIndex={index}
                          value={item.value}
                          selected={slotsAfterBreak === item.value}
                          className="dropdownMenuItem"
                          key={item.value}
                        >
                          {item.label}
                        </DropdownMenuItem>
                      );
                    })}
                  </Dropdown>
                </div>
                <Button
                  className="startButton"
                  iconPosition="right"
                  size="sm"
                  onClick={() => {
                    handleStartPlanning();
                  }}
                >
                  {t("SLOT_PLANNING_START_PLANNING_TITLE")}
                  {/* <a className="arrow">&#8594;</a> */}
                  <ArrowForwardRoundedIcon
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                  />
                </Button>
              </div>
              <div
                className={
                  showingDatatableFlag === true ? "slotPlanningDivision" : ""
                }
              ></div>
            </div>
            {showingDatatableFlag === true ? (
              <div>
                <div className="planningLowerSection">
                  <div className="dates">
                    {dataForTheWeek.length > 0 &&
                      dataForTheWeek.map((item, index) => {
                        return activeDateIndex === index ? (
                          <div
                            className={"date active"}
                            onClick={() => {
                              onDateChange(datesOfTheWeek[index], index);
                            }}
                          >
                           {dateToMonthDayFormat(new Date(item.date))}
                          </div>
                        ) : (
                          <div
                            className={"date"}
                            onClick={() => {
                              onDateChange(datesOfTheWeek[index], index);
                            }}
                          >
                            {dateToMonthDayFormat(new Date(item.date))}
                          </div>
                        );
                      })}
                  </div>
                  <DataTable
                    className="plannedSlots"
                    // selection={[]}
                    value={
                      dataTableFlag === false
                        ? []
                        : dataForTheWeek[activeWeekIndex] !== undefined ? dataForTheWeek[activeWeekIndex].slotPlanningData : []
                    }
                    selectionMode="multiple"
                    cellSelection
                    metaKeySelection={false}
                    selection={clearSlotSelection ? [] : selectedProducts}
                    rowClassName={unCheckedFlag === true ? null : rowclass}
                    // rowHover={fale}
                    onSelectionChange={(e) => {
                      setClearSlotSelection(false);
                      setSelectedProducts(e.value);
                    }}
                    onCellSelect={onCellSelect}
                    onCellUnselect={onCellUnselect}
                    dataKey="id"
                    responsiveLayout="scroll"
                    onRowClick={(e) => { }}
                  >
                    {dynamicColumns}
                  </DataTable>
                  <div className="preBookSlotNote">
                    {t("SLOT_PLANNING_NOTE")}
                  </div>
                  <div className="toggles">
                    <Switch
                      className="preBookToggle"
                      label={t("SLOT_PLANNING_PREBOOK_TOGGLE")}
                      checked={preBookToggle}
                      onChange={handlePrebookCellBeforeForm}
                    />
                    <Switch
                      className="deleteToggle"
                      label={t("SLOT_PLANNING_DELETE_TOGGLE")}
                      checked={deleteToggle}
                      onChange={handleDeleteCell}
                    />
                  </div>
                </div>
                <Button
                  className="goLiveButton"
                  iconPosition="right"
                  size="sm"
                  onClick={() => {
                    weekDataJsonGenerator();
                  }}
                >
                  {t("SLOT_PLANNING_GO_LIVE")}
                  {/* <a className="arrow">&#8594;</a> */}
                  <ArrowForwardRoundedIcon
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                  />
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <SlotReplanning
            facility={facilityOptions}
            monday={selectedFromDate}
            oneDayData={dataForTheWeek[0]}
          />
        )}
      </div>
      {preBookModal && (
        <PreBookSlotDetails
          activeDate={dataForTheWeek[activeWeekIndex].date}
          closeModal={() => {
            setPreBookModal(false);
          }}
          getSubmittedData={(value) => {
            const tempDetails = value;
            setPreBookModal(false);
            setPreBookDetails(tempDetails);
            handlePrebookCell();
            if (checkBoxFlag) {
              showToast(t("SLOTS_PREBOOKED"));
            } else {
              showToast(t("SLOT_PREBOOKED"));
            }
          }}
        />
      )}
      {weekDaysWarningDialog ? (
        <Dialog
          style={{ padding: "5px", border: "none" }}
          closeText="Close dialog"
          disableOutsideClick={true}
          headerContent={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="Settings"
                src="/imgs/warning-icon.png"
                width="30px"
                style={{ marginRight: "5px" }}
              />
              {/* {t("PREBOOK_SLOT_FORM_TITLE")} */}
              {t("PLAN_FOR_WEEKDAYS_WARNING")}
            </div>
          }
          onClose={() => {
            setWeekDaysWarningDialog(false);
          }}
        >
          <Body>
            {" "}
            <Button
              className="slot_detail_submit_btn"
              iconPosition="right"
              type="submit"
              size="sm"
              onClick={() => {
                setWeekDaysWarningDialog(false);
              }}
            >
              {t("OK_BUTTON")}
            </Button>
          </Body>
        </Dialog>
      ) : null}
    </div>
  </DashboardContainer>
);
};

export default SlotPlanning;
