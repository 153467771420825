import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import DashboardContainer from "../../components/Dashboard/DashboardContainer";
import { useDispatch, useSelector } from "react-redux";
import { DATEFORMAT, DATETIMEFORMAT } from "../../config/constant";
import moment from "moment";
import { _getCustomerBookings } from "../../Services/customerServices";
import PageSublink from "../../components/Dashboard/PageSublink";
import { Calendar } from "primereact/calendar";

const status = [
  "Booked",
  "Cancelled",
  "Checked In",
  "Delayed Check In",
  "Delayed",
  "Departed",
  "Dock Unavailable",
  "Released",
];
const typeValue = ["Container", "Truck", "Special Booking"];

const ViewBookingCustomer = () => {
  const [bookings, setBookings] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);

  useEffect(() => {
    getCustomerBookings();
  }, []);

  const getCustomerBookings = () => {
    dispatch(
      _getCustomerBookings(
        { customerId: userDetail?.user?.username },
        (res) => {
          const updatedData = res.data.map((product) => {
            product["originETD"] = moment(
              product["originETD"],
              DATEFORMAT
            ).toDate();
            product["ETA"] = moment(product["ETA"], DATETIMEFORMAT).toDate();
            return product;
          });
          setBookings(updatedData);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.shipmentStatus === "CHECKED_IN") {
      rowData.shipmentStatus = "Checked In";
    } else if (rowData.shipmentStatus === "BOOKED") {
      rowData.shipmentStatus = "Booked";
    } else if (rowData.shipmentStatus === "DEPARTED") {
      rowData.shipmentStatus = "Departed";
    } else if (rowData.shipmentStatus === "RELEASED") {
      rowData.shipmentStatus = "Released";
    } else if (rowData.shipmentStatus === "CANCELLED") {
      rowData.shipmentStatus = "Cancelled";
    } else if (rowData.shipmentStatus === "DELAYED") {
      rowData.shipmentStatus = "Delayed";
    } else if (rowData.shipmentStatus === "DOCK_UNAVAILABLE") {
      rowData.shipmentStatus = "Dock Unavailable";
    } else if (rowData.shipmentStatus === "CHECK_IN_DELAYED") {
      rowData.shipmentStatus = "Delayed Check In";
    }
    const statusClassName = classNames({
      booked: rowData.shipmentStatus === "Booked",
      departed: rowData.shipmentStatus === "Departed",
      delayed: rowData.shipmentStatus === "Delayed",
      checked_In: rowData.shipmentStatus === "Checked In",
      completed: rowData.shipmentStatus === "Released",
      cancelled: rowData.shipmentStatus === "Cancelled",
      dock_Unavailable: rowData.shipmentStatus === "Dock Unavailable",
      delayed_Check_In: rowData.shipmentStatus === "Delayed Check In",
    });

    return <div className={statusClassName}>{rowData.shipmentStatus}</div>;
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder={DATEFORMAT}
        mask="99/99/9999"
      />
    );
  };
  const timeFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={DATEFORMAT}
        dateFormat="yy-mm-dd"
      />
    );
  };
  const ETDBodyTemplate = (rowData) => {
    return formatDate(rowData.originETD);
  };
  const ETABodyTemplate = (rowData) => {
    return formatDateandTime(rowData.ETA);
  };

  const formatDateandTime = (value) => {
    return moment(value).format(DATETIMEFORMAT);
  };

  const formatDate = (value) => {
    return moment(value).format(DATEFORMAT);
    // return value.toLocaleString("en-US", {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    // });
  };

  const bookingTypeRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={typeValue}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_BOOKING_TYPE")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const bookingTypeBodyTemplate = (rowData) => {
    if (rowData.bookingType === "CNTR") {
      rowData.bookingType = "Container";
    } else if (rowData.bookingType === "TRUCK") {
      rowData.bookingType = "Truck";
    } else if (rowData.bookingType === "SPCL_BKNG") {
      rowData.bookingType = "Special Booking";
    }
    return <div>{rowData.bookingType}</div>;
  };

  return (
    <DashboardContainer>
      <div>
        <PageSublink pageName={t("VIEW_BOOKINGS_TITLE")} />
        <h1 className="book_slot_title">{t("VIEW_BOOKINGS_TITLE")}</h1>
        <div className="boxCustomer">
          <div>
            <DataTable
              value={bookings}
              stripedRows
              paginator={true}
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              rowHover
              responsiveLayout="scroll"
            >
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("BOOKING_ID")}
                field="bookingId"
                header={t("BOOKING_ID")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("PO_REF")}
                field="PORef"
                style={{ whiteSpace: "unset" }}
                header={t("PO_REF")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("SHIPPER_INVOICE_REF")}
                field="shipperInvoiceRef"
                header={t("SHIPPER_INVOICE_REF")}
                body={(e) => {
                  return (
                    <span>
                      {e?.shipperInvoiceRef ? e?.shipperInvoiceRef : "-"}
                    </span>
                  );
                }}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("VEHICLE_LICENSE_PLATE_NUMBER")}
                field="vehicleLicensePlateNumber"
                header={t("VEHICLE_LICENSE_PLATE_NUMBER")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("NUMBER_OF_PALLETS_UNITS")}
                field="numberOfPallets"
                header={t("NUMBER_OF_PALLETS_UNITS")}
              ></Column>
              <Column
                field="shipmentStatus"
                header={t("STATUS")}
                filterPlaceholder={t("STATUS")}
                filter
                showAddButton={false}
                showFilterOperator={false}
                showFilterMenuOptions={false}
                filterElement={statusRowFilterTemplate}
                body={statusBodyTemplate}
              ></Column>
              <Column
                filter
                filterElement={timeFilterTemplate}
                body={ETABodyTemplate}
                field="ETA"
                showAddButton={false}
                showFilterOperator={false}
                dataType="date"
                filterField="ETA"
                header={t("EXPECTED_DATE_AND_TIME")}
                sortable
                className="ETA_column"
              ></Column>
              <Column
                body={(e) => {
                  return (
                    <span>
                      {e?.ATA
                        ? moment(e.ATA, DATETIMEFORMAT).format("HH:mm")
                        : "-"}
                    </span>
                  );
                }}
                field="ATA"
                header={t("TIME_OF_ARRIVAL")}
                sortable
              ></Column>

              <Column
                sortable
                showAddButton={false}
                showFilterOperator={false}
                field="originETD"
                dataType="date"
                filterField="originETD"
                body={ETDBodyTemplate}
                filter
                filterElement={dateFilterTemplate}
                header={t("ETD")}
                className="ETD_column"
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("CARRIER_SERVICE_PROVIDER")}
                field="carrierId"
                header={t("CARRIER_SERVICE_PROVIDER")}
              ></Column>
              <Column
                field="bookingType"
                header={t("BOOKING_TYPE")}
                filterPlaceholder={t("STATUS")}
                filter
                showAddButton={false}
                showFilterOperator={false}
                showFilterMenuOptions={false}
                filterElement={bookingTypeRowFilterTemplate}
                body={bookingTypeBodyTemplate}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("DRIVER_NAME")}
                field="driverDetails.driverName"
                header={t("DRIVER_NAME")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("LICENSE_NUMBER")}
                field="driverDetails.drivingLicenseNo"
                header={t("LICENSE_NUMBER")}
              ></Column>
              <Column
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder={t("FACILITY_NAME")}
                field="siteName"
                header={t("FACILITY_NAME")}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default ViewBookingCustomer;
