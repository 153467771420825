import * as yup from "yup";
import {
  digitsOnly,
  isEmail,
  isDuplicateEmail,
  isAditroEmail,
  isPhoneNoValid
} from "./globalFunctions";

export const bookingFormSchema = yup.object().shape({
  PORef: yup.string().required("POREF_REQUIRED"),
  // shipperInvoiceRef: yup.string().required("SHIPPER_INVOICE_REQUIRED"),
  customerDetails: yup
    .object()
    .test("customerDetails", "CUSTOMER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  vehicleLicensePlateNumber: yup.string().required("VEHICLE_LICENSE_REQUIRED"),
  numberOfPallets: yup
    .string()
    .required("PALLETS_REQUIRED")
    .test("Digits only", "PALLETS_WARNING", digitsOnly),
  driverDetails: yup
    .object()
    .test("driverDetails", "DRIVER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  originETD: yup.date().required("ORIGINETD_REQUIRED").nullable(),
});

export const preBookingFormSchema = yup.object().shape({
  PORef: yup.string().required("POREF_REQUIRED"),
  // shipperInvoiceRef: yup.string().required("SHIPPER_INVOICE_REQUIRED"),
  customerDetails: yup
    .object()
    .test("customerDetails", "CUSTOMER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  vehicleLicensePlateNumber: yup.string().required("VEHICLE_LICENSE_REQUIRED"),
  numberOfPallets: yup
    .string()
    .required("PALLETS_REQUIRED")
    .test("Digits only", "PALLETS_WARNING", digitsOnly),
  originETD: yup.date().required("ORIGINETD_REQUIRED").nullable(),
  // ETD: yup.date().required("ETD is required"),
});

export const replanningPreBookingFormSchema = yup.object().shape({
  PORef: yup.string().required("POREF_REQUIRED"),
  // shipperInvoiceRef: yup.string().required("SHIPPER_INVOICE_REQUIRED"),
  customerDetails: yup
    .object()
    .test("customerDetails", "CUSTOMER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  vehicleLicensePlateNumber: yup.string().required("VEHICLE_LICENSE_REQUIRED"),
  carrierDetails: yup.object().when("prebookingType", {
    is: "Special Booking",
    then: yup
      .object()
      .test("carrierDetails", "CARRIER_DETAIL_REQUIRED", (value) =>
        Object.keys(value).length > 0 ? true : false
      ),
  }),
  driverDetails: yup.object().when("prebookingType", {
    is: "Special Booking",
    then: yup
      .object()
      .test("driverDetails", "DRIVER_DETAIL_REQUIRED", (value) =>
        Object.keys(value).length > 0 ? true : false
      ),
  }),
  numberOfPallets: yup
    .string()
    .required("PALLETS_REQUIRED")
    .test("Digits only", "PALLETS_WARNING", digitsOnly),
  originETD: yup.date().required("ORIGINETD_REQUIRED").nullable(),
  // ETD: yup.date().required("ETD is required"),
});

export const truckPreBookingFormSchema = yup.object().shape({
  PORef: yup.string().required("POREF_REQUIRED"),
  shipperInvoiceRef: yup.string().required("SHIPPER_INVOICE_REQUIRED"),
  customerDetails: yup
    .object()
    .test("customerDetails", "CUSTOMER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  carrierDetails: yup
    .object()
    .test("carrierDetails", "CARRIER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  driverDetails: yup
    .object()
    .test("driverDetails", "DRIVER_DETAIL_REQUIRED", (value) =>
      Object.keys(value).length > 0 ? true : false
    ),
  vehicleLicensePlateNumber: yup.string().required("VEHICLE_LICENSE_REQUIRED"),
  numberOfPallets: yup
    .string()
    .required("PALLETS_REQUIRED")
    .test("Digits only", "PALLETS_WARNING", digitsOnly),
  originETD: yup.date().required("ORIGINETD_REQUIRED").nullable(),
  // ETD: yup.date().required("ETD is required"),
});

export const shiftingSlotsFormSchema = yup.object().shape({
  bookingId: yup
    .string()
    .required("BOOKING_ID_REQUIRED")
    .test("Digits only", "BOOKING_ID_NUMBER_WARNING", digitsOnly)
    .nullable(),
});

export const editFormSchema = yup.object().shape({
  PORef: yup.string().required("POREF_REQUIRED"),
  // shipperInvoiceRef: yup.string().required("SHIPPER_INVOICE_REQUIRED"),
  vehicleLicensePlateNumber: yup.string().required("VEHICLE_LICENSE_REQUIRED"),
  numberOfPallets: yup
    .string()
    .required("PALLETS_REQUIRED")
    .test("Digits only", "PALLETS_WARNING", digitsOnly),
});

export const addMDDriverFormSchema = yup.object().shape({
  driverName: yup.string().required("DRIVER_NAME_REQUIRED").nullable(),
  drivingLicenseNo: yup
    .string()
    .required("DRIVER_LICENSE_NO_REQUIRED")
    .nullable(),
  driverEmail: yup
    .string()
    .email("EMAIL_WARNING")
    .required("DRIVER_EMAIL_REQUIRED")
    .nullable(),
  driverPhNo: yup
    .string()
    .required("DRIVER_PHONE_NO_REQUIRED")
    .test("Phone only", "PHONE_NUMBER_INVALID", isPhoneNoValid)
    .nullable(),
  // driverId: yup.string().required("DRIVER_ID_REQUIRED").nullable(),
  status: yup.string().required("DRIVER_STATUS_REQUIRED").nullable(),
  carrierName: yup.string().required("CARRIER_NAME_REQUIRED").nullable()
});

export const addMDCarrierFormSchema = yup.object().shape({
  // carrierId: yup.string().required("CARRIER_ID_REQUIRED").nullable(),
  carrierName: yup.string().required("CARRIER_NAME_REQUIRED").nullable(),
  carrierCode: yup.string().required("CARRIER_CODE_REQUIRED").nullable(),
  status: yup.string().required("CARRIER_STATUS_REQUIRED").nullable(),
  streetName: yup.string().required("CARRIER_STREET_NAME_REQUIRED").nullable(),
  streetNo: yup.string().required("CARRIER_STREET_NO_REQUIRED").nullable(),
  postCode: yup.string().required("CARRIER_POSTCODE_REQUIRED").nullable(),
  city: yup.string().required("CARRIER_CITY_REQUIRED").nullable(),
  spocName: yup.string().required("CARRIER_SPOC_NAME_REQUIRED").nullable(),
  spocEmail: yup
    .string()
    .email("EMAIL_WARNING")
    .required("CARRIER_SPOC_EMAIL_REQUIRED")
    .nullable(),
  spocPhNo: yup
    .string()
    .required("CARRIER_SPOC_PHONE_NO_REQUIRED")
    .test("Phone only", "PHONE_NUMBER_INVALID", isPhoneNoValid)
    .nullable(),
});

export const addMDFacilityFormSchema = yup.object().shape({
  siteName: yup.string().required("FACILITY_NAME_REQUIRED").nullable(),
  streetName: yup.string().required("FACILITY_STREET_NAME_REQUIRED").nullable(),
  streetNo: yup.string().required("FACILITY_STREET_NO_REQUIRED").nullable(),
  postCode: yup.string().required("FACILITY_POSTCODE_REQUIRED").nullable(),
  city: yup.string().required("FACILITY_CITY_REQUIRED").nullable(),
  facilityStatus: yup.string().required("FACILITY_STATUS_REQUIRED").nullable(),
  // facilityIdMapUrl: yup
  //   .string()
  //   .required("FACILITY_ID_MAP_URL_REQUIRED")
  //   .nullable(),
});

export const editMDFacilityFormSchema = yup.object().shape({
  siteName: yup.string().required("FACILITY_NAME_REQUIRED").nullable(),
  streetName: yup.string().required("FACILITY_STREET_NAME_REQUIRED").nullable(),
  streetNo: yup.string().required("FACILITY_STREET_NO_REQUIRED").nullable(),
  postCode: yup.string().required("FACILITY_POSTCODE_REQUIRED").nullable(),
  city: yup.string().required("FACILITY_CITY_REQUIRED").nullable(),
  facilityStatus: yup.string().required("FACILITY_STATUS_REQUIRED").nullable(),
  // facilityIdMapUrl: yup
  //   .string()
  //   .required("FACILITY_ID_MAP_URL_REQUIRED")
  //   .nullable(),
});

export const addManagerFormSchema = yup.object().shape({
  addManagers: yup
    .string()
    .required("MANAGERS_REQUIRED")
    .test("Emails only", "EMAIL_WARNING", isEmail)
    .test("Emails only", "DUPLICATE_EMAIL", isDuplicateEmail)
    .test("Emails only", "VALID_INPUT", isAditroEmail)
    .nullable(),
});

export const removeManagerFormSchema = yup.object().shape({
  removeManagers: yup
    .string()
    .required("MANAGERS_REQUIRED")
    .test("Email only", "EMAIL_WARNING", isEmail)
    .test("Emails only", "DUPLICATE_EMAIL", isDuplicateEmail)
    .test("Emails only", "VALID_INPUT", isAditroEmail)
    .nullable(),
});

export const addMDDocFormSchema = yup.object().shape({
  dockNo: yup.string().required("DOCK_NO_REQUIRED").nullable(),
  dockType: yup.string().required("DOCK_TYPE_REQUIRED").nullable(),
  dockStatus: yup.string().required("DOCK_STATUS_REQUIRED").nullable(),
});

export const addMDCustomerFormSchema = yup.object().shape({
  // customerId: yup.string().required("CUSTOMER_ID_REQUIRED").nullable(),
  customerName: yup.string().required("CUSTOMER_NAME_REQUIRED").nullable(),
  customerCode: yup.string().required("CUSTOMER_CODE_REQUIRED").nullable(),
  status: yup.string().required("CUSTOMER_STATUS_REQUIRED").nullable(),
  streetName: yup.string().required("CUSTOMER_STREET_NAME_REQUIRED").nullable(),
  streetNo: yup.string().required("CUSTOMER_STREET_NO_REQUIRED").nullable(),
  postCode: yup.string().required("CUSTOMER_POSTCODE_REQUIRED").nullable(),
  city: yup.string().required("CUSTOMER_CITY_REQUIRED").nullable(),
  spocName: yup.string().required("CUSTOMER_SPOC_NAME_REQUIRED").nullable(),
  spocEmail: yup
    .string()
    .email("EMAIL_WARNING")
    .required("CUSTOMER_SPOC_EMAIL_REQUIRED")
    .nullable(),
  spocPhNo: yup
    .string()
    .required("CUSTOMER_SPOC_PHONE_NO_REQUIRED")
    .test("Phone only", "PHONE_NUMBER_INVALID", isPhoneNoValid)
    .nullable(),
});
