// All APis related to slot will be here

import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";
import { hideLoader, showLoader } from "../Store/Actions/commonAction";

export const _getFacilityList = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.facilityURL, { params })
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _getSlotData = (params, success, error) => {
  instance
    .get(endPoints.slotURL, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};

export const _bookSelectedSlot = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.slotBookingURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _getReplanningSlotsData = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.slotReplanningURL, { params })
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _prebookingSlots = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.slotPrebookingURL, params)
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _deleteSlots = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.slotDeletingURL, params)
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

export const _shiftingSlots = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.slotBookingURL, params)
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};
