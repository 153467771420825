import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from "aws-amplify";
import Clock from "react-live-clock";

import {
  ClearReducer,
  setLocalLanguage,
} from "../../Store/Actions/commonAction";
import { Dialog, Body } from "@postidigital/posti-components";
import { DATETIMEFORMAT } from "../../config/constant";

const RollingHeader = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.common.localLang);
  const es = useSelector((state) => state.common.es);
  const userDetail = useSelector((state) => state.user.userDetail);

  const [language, setLanguage] = useState(lang);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCustModal, setCustModal] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Signout function
  async function signOut() {
    try {
      await Auth.signOut().then((res) => {
        if (es) es.close();
        localStorage.clear();
        dispatch(ClearReducer());
        navigate("/");
        // window.location.href = "/";
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  //Function for handling changes in language
  const changeLanguage = (value) => {
    if (value === "English") {
      setLanguage("English");
    }
    if (value === "Swedish") {
      setLanguage("Swedish");
    }
    dispatch(setLocalLanguage(value));
  };

  return (
    <>
      <header className="main_header">
        <div className="left_header">
          <Link to="/" className="logo_box">
            <img
              className=""
              alt="posti"
              title="Posti"
              src="/imgs/AditroLogistics_new_logo.png"
            />
          </Link>
          <div className="verticle_bar" />
        </div>
        <div className="right_header">
          <div className="time">
            <Clock format={DATETIMEFORMAT} ticking={true} />
          </div>
          <div className="language_selector">
            <span className="language_badge">Aa</span>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    className="language_dropdown"
                    variant="contained"
                    {...bindTrigger(popupState)}
                  >
                    {language}
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={() => {
                        changeLanguage("English");
                        popupState.close();
                      }}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        changeLanguage("Swedish");
                        popupState.close();
                      }}
                    >
                      Swedish
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </div>
          <Button
            className="user_btn"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : false}
            onClick={handleClick}
          >
            {userDetail?.user?.username}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                signOut();
                handleClose();
              }}
            >
              {t("LOGOUT")}
            </MenuItem>
          </Menu>
        </div>
      </header>
      {/* Customer support modal */}
      {showCustModal && (
        <Dialog
          closeText="Close dialog"
          disableOutsideClick={true}
          headerContent={
            <div style={{ display: "flex", alignItems: "center" }}>
              {t("CUSTOMER_SUPPORT_DETAIL")}
            </div>
          }
          onClose={() => setCustModal(false)}
        >
          <Body as="div" size="Four">
            <div className="cust_detail_box">
              <a
                className="sup_act_btn"
                href="mailto:Support@aditrologistics.com"
              >
                Support@aditrologistics.com
              </a>
              <a className="sup_act_btn" href="tel:+46 36 362200">
                +46 36 362200
              </a>
            </div>
          </Body>
        </Dialog>
      )}
    </>
  );
};

export default RollingHeader;
