import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageSublink from "../../components/Dashboard/PageSublink";
import DashboardContainer from "../../components/Dashboard/DashboardContainer";
import MyProfile from "./MyProfile";
import DriverDetails from "./DriverDetails";

const CarrierData = () => {
  const [myProfileTab, setMyProfileTab] = useState(true);
  const [driverDataTab, setDriverDataTab] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <DashboardContainer>
      <div>
        <PageSublink pageName={t("MY_DATA")} />
        <h1 className="book_slot_title">{t("MY_DATA")}</h1>
        <div className="sub_menu_carrier">
          <button
            className={`sub_menu_links_carrier ${myProfileTab ? "active" : ""}`}
            onClick={() => {
              setMyProfileTab(true);
              setDriverDataTab(false);
            }}
            size="sm"
          >
            {t("MY_PROFILE_TITLE")}
          </button>
          <button
            className={`sub_menu_links_carrier ${
              driverDataTab ? "active" : ""
            }`}
            onClick={() => {
              setMyProfileTab(false);
              setDriverDataTab(true);
            }}
            size="sm"
          >
            {t("DRIVER_DETAILS")}
          </button>
        </div>
        {myProfileTab && <MyProfile />}
        {driverDataTab && <DriverDetails />}
      </div>
    </DashboardContainer>
  );
};

export default CarrierData;
