// all apis related to rolling screen would be here

import moment from "moment";
import { DATEFORMAT } from "../config/constant";
import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

// get MasterData driver list
export const _getRollingList = (facilityId, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    const URL = `${endPoints.rollingScreenURL}?facilityId=${facilityId}&slotBookedDate=${moment().format(DATEFORMAT)}&status=BOOKED&status=DELAYED&status=DEPARTED&status=CHECKED_IN&status=CHECK_IN_DELAYED&status=DOCK_UNAVAILABLE&sort=ETA:ASC`;
      instance
        .get(URL)
        .then((res) => {
          dispatch(hideLoader());
          success(res);
        })
        .catch((err) => {
          console.log("Error" + err);
          dispatch(hideLoader());
          error(err);
        });
  };
};