import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import { _getMDCustomerList } from "../../Services/masterDataServices";

const MyProfileCustomer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = () => {
    dispatch(
      _getMDCustomerList(
        { customerId: userDetail?.user?.username },
        (res) => {
          setProduct(res.data[0]);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };
  return (
      <div>
        <div className="box">
          <div className="customer_profile_input_container">
            <Input
              type="text"
              label={t("CUSTOMER_NAME")}
              value={product?.customerName}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("CUSTOMER_CODE")}
              value={product?.customerCode}
              required
              readOnly
              className="customer_profile_input_control"
            />
             <Input
              type="text"
              label={t("STATUS")}
              value={product?.status}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("STREET_NAME")}
              value={product?.streetName}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("STREET_NUMBER")}
              value={product?.streetNo}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("POST_CODE")}
              value={product?.postCode}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("CITY")}
              value={product?.city}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("SPOC_NAME")}
              value={product?.spocName}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("SPOC_EMAIL")}
              value={product?.spocEmail}
              required
              readOnly
              className="customer_profile_input_control"
            />
            <Input
              type="text"
              label={t("SPOC_PHONE_NUMBER")}
              value={product?.spocPhNo}
              required
              readOnly
              className="customer_profile_input_control"
            />
          </div>
        </div>
      </div>
  );
};

export default MyProfileCustomer;
