import React from "react";
import { Button } from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "react-redux";

const MasterDataAddButton = ({ title, onClick }) => {
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);

  const isAdmin = () => {
    return (
      userDetail &&
      Object.keys(userDetail).length > 0 &&
      userDetail?.groups?.includes("SMS_Admin")
    );
  };

  return (
    <>
      {isAdmin() ? (
        <Button
          className={
            title === "ADD_NEW_DOCK"
              ? "masterDataDockAddButton"
              : "masterDataAddButton"
          }
          iconPosition="right"
          onClick={onClick}
        >
          {t(title)}
          {title === "SAVE" ?null: <AddIcon className="add_icon" /> 
          }
          
        </Button>
      ) : null}
    </>
  );
};

export default MasterDataAddButton;
