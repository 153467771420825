import React from "react";
import { Card } from "primereact/card";
import { Button as PostiButton } from "@postidigital/posti-components";
import CommonFooter from "../../components/Footer/CommonFooter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Headers/Header";
import { values } from "../../CommomFiles/landingPageConstants";

export default function LandingPage() {
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.user.userDetail);
  const { t } = useTranslation();

  //comment on landing page
  const isSMSCarier = () => {
    if (!userDetail?.groups) {
      return false;
    }
    const startsWithValue = userDetail?.groups?.filter((e) =>
      e.startsWith(values.CARRIER_GROUP_START_STR)
    );
    if (startsWithValue?.length == 0) {
      return userDetail && Object.keys(userDetail).length > 0 && false;
    } else {
      return userDetail && Object.keys(userDetail).length > 0 && true;
    }
  };
  const isSMSWHM = () => {
    if (!userDetail?.groups) {
      return false;
    }
    const startsWithValue = userDetail?.groups?.filter((e) =>
      e.startsWith(values.WHM_GROUP_START_STR)
    );
    if (startsWithValue?.length == 0) {
      return userDetail && Object.keys(userDetail).length > 0 && false;
    } else {
      return userDetail && Object.keys(userDetail).length > 0 && true;
    }
  };
  const isSMSSecurity = () => {
    if (!userDetail?.groups) {
      return false;
    }
    const startsWithValue = userDetail?.groups?.filter((e) =>
      e.startsWith(values.SECURITY_GROUP_START_STR)
    );
    if (startsWithValue?.length == 0) {
      return userDetail && Object.keys(userDetail).length > 0 && false;
    } else {
      return userDetail && Object.keys(userDetail).length > 0 && true;
    }
  };
  const isSMSCustomer = () => {
    if (!userDetail?.groups) {
      return false;
    }
    const startsWithValue = userDetail?.groups?.filter((e) =>
      e.startsWith(values.CUSTOMER_GROUP_START_STR)
    );
    if (startsWithValue?.length == 0) {
      return userDetail && Object.keys(userDetail).length > 0 && false;
    } else {
      return userDetail && Object.keys(userDetail).length > 0 && true;
    }
  };

  const isSMSAdmin = () => {
    if (!userDetail?.groups) {
      return false;
    }
    const startsWithValue = userDetail?.groups?.filter((e) =>
      e.startsWith(values.ADMIN_GROUP_START_STR)
    );
    if (startsWithValue?.length == 0) {
      return userDetail && Object.keys(userDetail).length > 0 && false;
    } else {
      return userDetail && Object.keys(userDetail).length > 0 && true;
    }
  };

  const isSMSDesk = () => {
    if (!userDetail?.groups) {
      return false;
    }
    const startsWithValue = userDetail?.groups?.filter((e) =>
      e.startsWith(values.DESK_GROUP_START_STR)
    );
    if (startsWithValue?.length === 0) {
      return userDetail && Object.keys(userDetail).length > 0 && false;
    } else {
      return userDetail && Object.keys(userDetail).length > 0 && true;
    }
  };

  return (
    <div className="landingPage">
      <Header user={userDetail?.user} />
      <div className="landing_page_container">
        <h2 className="title">{t("PAGE_TITLE")}</h2>
        <div className="cards_container">
          {/* SMS Carrier Cards */}
          {isSMSCarier() && (
            <>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="calendarImage"
                    alt="Book a slot"
                    title="Book a slot"
                    src="/imgs/calendar.png"
                  />
                  <div className="cardTitle">{t("BOOK_A_SLOT_TITLE")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("BOOK_A_SLOT_CONTENT")}
                  </p>
                  <PostiButton
                    className="BookASlotButton"
                    iconPosition="right"
                    onClick={() => navigate("slot-booking")}
                    size="sm"
                  >
                    {t("BOOK_A_SLOT_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
              {/* <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="folderImage"
                    alt="Manage master data"
                    title="Manage master data"
                    src="/imgs/folder.png"
                  />
                  <div className="cardTitle">
                    {t("MANAGE_MASTER_DATA_TITLE")}
                  </div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("MANAGE_MASTER_DATA_CONTENT")}
                  </p>
                  <PostiButton
                    // icon={function noRefCheck(){}}
                    className="manageMasterDataButton"
                    iconPosition="right"
                    // onBlur={function noRefCheck(){}}
                    // onClick={function noRefCheck(){}}
                    // onFocus={function noRefCheck(){}}
                    size="sm"
                  >
                    {t("MANAGE_MASTER_DATA_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card> */}
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/search.png"
                  />
                  <div className="cardTitle">{t("VIEW_BOOKINGS_TITLE")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("CARIER_VIEW_BOOKINGS_CONTENT")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("carrier/view-booking")}
                    size="sm"
                  >
                    {t("VIEW_BOOKINGS_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/user.png"
                  />
                  <div className="cardTitle">{t("MY_DATA")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("MASTER_DATA_CONTENT_CARRIER_AND_CUSTOMER")}
                  </p>
                  <PostiButton
                    className="carrierDataButton"
                    iconPosition="right"
                    onClick={() => navigate("carrier/data")}
                    size="sm"
                  >
                    {t("MY_DATA")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
            </>
          )}
          {/* SMS WHM Cards */}
          {isSMSWHM() && (
            <>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="Manage master data"
                    title="Manage master data"
                    src="/imgs/search.png"
                  />
                  <div className="cardTitle">{t("MASTER_DATA")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("MASTER_DATA_CONTENT_WHM")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("/whm/manage-master-data")}
                    size="sm"
                  >
                    {t("MASTER_DATA")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/search.png"
                  />
                  <div className="cardTitle">{t("VIEW_BOOKINGS_TITLE")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("WHM_VIEW_BOOKINGS_CONTENT")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("/whm/view-booking")}
                    size="sm"
                  >
                    {t("VIEW_BOOKINGS_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="slotPlanningImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/slot-planning.png"
                  />
                  <div className="cardTitle">
                    {t("SLOT_PLANNING_CARD_TITLE")}
                  </div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("SLOT_PLANNING_CARD_CONTENT")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("slot-planning")}
                    size="sm"
                  >
                    {t("SLOT_PLANNING_CARD_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
            </>
          )}
          {/* SMS Customer Cards */}
          {isSMSCustomer() && (
            <>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/search.png"
                  />
                  <div className="cardTitle">{t("VIEW_BOOKINGS_TITLE")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("CUSTOMER_VIEW_BOOKINGS_CONTENT")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("customer/view-booking")}
                    size="sm"
                  >
                    {t("VIEW_BOOKINGS_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/user.png"
                  />
                  <div className="cardTitle">{t("MY_DATA")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("MASTER_DATA_CONTENT_CARRIER_AND_CUSTOMER")}
                  </p>
                  <PostiButton
                    className="carrierDataButton"
                    iconPosition="right"
                    onClick={() => navigate("customer/data")}
                    size="sm"
                  >
                    {t("MY_DATA")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
            </>
          )}
          {/* SMS Admin Cards */}
          {isSMSAdmin() && (
            <>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="Manage master data"
                    title="Manage master data"
                    src="/imgs/search.png"
                  />
                  <div className="cardTitle">
                    {t("MANAGE_MASTER_DATA_TITLE")}
                  </div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("MANAGE_MASTER_DATA_CONTENT")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("/manage-master-data")}
                    size="sm"
                  >
                    {t("MANAGE_MASTER_DATA_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
            </>
          )}

          {/* SMS Desk Cards */}
          {isSMSDesk() && (
            <>
              <Card
                className="card_box"
                title=""
                style={{ marginBottom: "2em" }}
              >
                <div className="contentDivision">
                  <img
                    className="searchImage"
                    alt="View bookings"
                    title="View bookings"
                    src="/imgs/search.png"
                  />
                  <div className="cardTitle">{t("ROLLING_SCREEN_TITLE")}</div>
                  <p className="content" style={{ lineHeight: "1.5" }}>
                    {t("ROLLING_SCREEN_CONTENT")}
                  </p>
                  <PostiButton
                    className="viewBookingsButton"
                    iconPosition="right"
                    onClick={() => navigate("/rolling")}
                    size="sm"
                  >
                    {t("ROLLING_SCREEN_BUTTON")}
                    <a className="arrow">&#8594;</a>
                  </PostiButton>
                </div>
              </Card>
            </>
          )}
          {/* SMS Security Cards */}
          {isSMSSecurity() && (
            <Card className="card_box_qr_code">
              <div className="contentDivision">
                <div className="cardTitle1QRCode">{t("WELCOME_TEXT")}</div>
                <div className="cardTitle2QRCode">
                  Aditro Logistics <br /> {t("PAGE_TITLE")}
                </div>
                <p className="content" style={{ lineHeight: "1.5" }}>
                  {t("SECURITY_QR_CODE_SCAN_CONTENT")}
                </p>
                <PostiButton
                  className="ScanAQRCodeButton"
                  // icon={function noRefCheck(){}}
                  iconPosition="right"
                  // onBlur={function noRefCheck(){}}
                  onClick={() => navigate("qr-code-scan")}
                  // onFocus={function noRefCheck(){}}
                  size="sm"
                >
                  {t("SCAN_YOUR_QR_CODE_BUTTON")}
                  <a className="arrow">&#8594;</a>
                </PostiButton>
              </div>
            </Card>
          )}
          {!isSMSCarier() &&
            !isSMSWHM() &&
            !isSMSCustomer() &&
            !isSMSAdmin() &&
            !isSMSDesk() &&
            !isSMSSecurity() && (
              <div>
                <p className="no-role">{t("NO_ROLE")}</p>
              </div>
            )}
        </div>
      </div>

      <CommonFooter />
    </div>
  );
}
