import React, { useState } from "react";
import { Card } from "primereact/card";
import Header from "../../components/Headers/Header";
import CommonFooter from "../../components/Footer/CommonFooter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Input } from "@postidigital/posti-components";

const QrCodeScanner = () => {
  const [value1, setValue1] = useState("");
  const userDetail = useSelector((state) => state.user.userDetail);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onBookingIdChange = (e) => {
    setValue1(e);
    if (e.length === 6) {
      navigate("qr-code-details", { state: { id: e } });
    }
  };

  return (
    <div className="qrCodeScannerPage">
      <Header user={userDetail?.user} />
      <div className="qrCode_page_containers">
        <div className="cards_containers">
          <Card className="card_box_qr_codes">
            <div className="contentDivisions">
              <div className="cardTitleQRCodes">
                {t("SCAN_QR_CODE_OR_ENTER_BOOKING_ID")}
              </div>
              <div className="qrCodeImgs">
                <img alt="Settings" src="/imgs/qrCode.png" width="300px" />
              </div>
              <div className="inputTextDivs">
                <Input
                  value={value1}
                  label={t("BOOKING_ID")}
                  className="inputTexts"
                  required
                  onChange={(e) => onBookingIdChange(e.target.value)}
                  autoFocus
                />
              </div>
            </div>
          </Card>
          <p className="outsideCardContents">
            {t("PLACE_YOUR_CODE_RIGHT_BELOW_THE_SCREEN")}
          </p>
          <div style={{ textAlign: "center" }}>
            <img
              src="/imgs/down-arrow-white.png"
              // width="10%"
              alt="downArrow"
              className="downArrowImgs"
            />
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default QrCodeScanner;
