import axios from "axios";
import { LogoutApp, showToast } from "../../CommomFiles/globalFunctions";
import { BaseURL1 } from "./baseUrls";
import { Auth } from "aws-amplify";
import i18n from "i18next";

const instance = axios.create({
  baseURL: `${BaseURL1}/`,
});

instance.interceptors.request.use(
  async (config) => {
    console.log("Request=>", config);
    let token = await Auth.currentSession()
      .then((res) => res.getIdToken().getJwtToken())
      .catch(() => {
        LogoutApp();
      });
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    // Do Something with error
    console.log("ERROR", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Do Something after request
    console.log("Response=>", response);
    const { status } = response;
    if (status === 200) {
      return response;
    }
  },
  (error) => {
    const { status, data } = error.response;
    console.log("ERROR", status, data);
    if (status === 401) {
      LogoutApp();
    } else if (status === 422) {
      showToast(i18n.t("SOMETHING_WENT_WRONG"), "error");
    } else {
      showToast(i18n.t(data?.detail?.msgKey), "error");
    }
    return Promise.reject(error);
  }
);

export { instance };
