import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { classNames } from "primereact/utils";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import AddMDDock from "./AddMDDock";
import { _getMDDockList } from "../../../Services/masterDataServices";
import EditMDDock from "./EditMDDock";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";
import { isAdmin } from "../../../CommomFiles/globalFunctions";
const status = ["Active", "Inactive"];

const MDDock = (facilityId) => {
  const [bookings, setBookings] = useState(null);
  const [addDockDialog, setAddDockDialog] = useState(false);
  const [editProductsDialog, setEditProductsDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [product, setProduct] = useState();
  const [facilityIdParam] = useState(facilityId?.facilityId);
  const cm = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);
  const menuModel = [
    {
      label: t("EDIT_DOCK"),
      icon: "pi pi-fw pi-user-edit",
      command: () => confirmEditSelected(selectedProduct),
    },
  ];

  useEffect(() => {
    getDockDetails();
  }, []);

  const getDockDetails = () => {
    dispatch(
      _getMDDockList(
        { facilityId: facilityIdParam },
        (res) => {
          setBookings(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const confirmEditSelected = (product) => {
    setProduct(product);
    setEditProductsDialog(true);
  };

  const addBookingList = (data) => {
    const tempArray = [...bookings];
    tempArray.splice(0, 0, data);
    setBookings(tempArray);
  };

  const updateBookingList = (data) => {
    const index = bookings.findIndex((item) => item.dockNo === data.dockNo);
    const tempArray = [...bookings];
    tempArray.splice(index, 1, data);
    setBookings(tempArray);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };
  const statusBodyTemplate = (rowData) => {
    if (rowData.dockStatus === "ACTIVE") {
      rowData.dockStatus = "Active";
    } else if (rowData.dockStatus === "INACTIVE") {
      rowData.dockStatus = "Inactive";
    }
    const statusClassName = classNames({
      in_active: rowData.dockStatus === "Inactive",
      active: rowData.dockStatus === "Active",
    });
    return <div className={statusClassName}>{rowData.dockStatus}</div>;
  };

  return (
    <div className="docDiv">
      <ContextMenu
        model={menuModel}
        ref={cm}
        onHide={() => setSelectedProduct(null)}
      />
      <div className="dockBox">
        <div>
          <DataTable
            value={bookings}
            stripedRows
            paginator={true}
            rows={4}
            contextMenuSelection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onContextMenu={(e) =>
              isAdmin(userDetail) ? cm.current.show(e.originalEvent) : null
            }
            rowsPerPageOptions={[4, 10, 20]}
            rowHover
            responsiveLayout="scroll"
          >
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("DOCK_NO")}
              field="dockNo"
              header={t("DOCK_NO")}
            ></Column>

            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("DOCK_TYPE")}
              field="dockType"
              header={t("DOCK_TYPE")}
            ></Column>

            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("DOCK_STATUS")}
              field="dockStatus"
              body={statusBodyTemplate}
              filterElement={statusRowFilterTemplate}
              header={t("DOCK_STATUS")}
            ></Column>
          </DataTable>
        </div>

        <MasterDataAddButton
          title={"ADD_NEW_DOCK"}
          onClick={(e) => setAddDockDialog(true)}
        />
      </div>
      {editProductsDialog && (
        <EditMDDock
          close={() => setEditProductsDialog(false)}
          booking={product}
          updateList={(data) => updateBookingList(data)}
        />
      )}
      {addDockDialog && (
        <AddMDDock
          close={() => setAddDockDialog(false)}
          facilityId={facilityIdParam}
          updateList={(data) => addBookingList(data)}
        />
      )}
    </div>
  );
};

export default MDDock;
