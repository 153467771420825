const INITIAL_STATE = {
  showLoader: false,
  localLang: navigator.language === "sv" ? "Swedish" : "English",
  es: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return {
        ...state,
        showLoader: true,
      };

    case "HIDE_LOADER":
      return {
        ...state,
        showLoader: false,
      };
    case "SET_LOCAL":
      return {
        ...state,
        localLang: action.payload,
      };
    case "SET_EVENTSOURCE":
      return {
        ...state,
        es: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
