import React from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageSublink = ({ pageName }) => {
  const { t } = useTranslation();
  return (
    <p className="sub_links">
      <Link to="/">
        <span>{t("HOME")}</span>
      </Link>
      <ArrowForwardIosOutlinedIcon className="seperator " />
      <span>{pageName}</span>
    </p>
  );
};

export default PageSublink;
