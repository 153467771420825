import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  Body,
  Input,
  Dropdown,
  DropdownMenuItem,
  Textarea,
} from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../CommomFiles/globalFunctions";
import { editMDFacilityFormSchema } from "../../../CommomFiles/FormsValidationSchema";
import { _modifyFacilityData } from "../../../Services/masterDataServices";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";

const Status = ["Active", "Inactive"];

const EditMDFacility = ({ close, booking, updateList }) => {
  const [isStatusDropdownOpen, setStatusDropdown] = useState(false);
  const [errors, setErrors] = useState(null);
  const [product, setProduct] = useState(booking);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modifyBooking = () => {
    dispatch(
      _modifyFacilityData(
        product,
        (res) => {
          const responseData = res.data;
          updateList(responseData[0]);
          showToast(t("EDITING_SUCCESSFUL"), "success");
          close();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const onInputChange = (val, key) => {
    const _product = { ...product };
    _product[`${key}`] = val;
    setProduct(_product);
    setErrors(null);
  };

  const onSubmit = async (event) => {
    console.log("Product", product);
    event.preventDefault();
    const isFormValid = await editMDFacilityFormSchema.isValid(product, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      modifyBooking();
    } else {
      // If form is not valid, check which fields are incorrect:
      editMDFacilityFormSchema
        .validate(product, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  return (
    <Dialog
      bottomButtons={
        <MasterDataAddButton title={"SAVE"} onClick={(e) => onSubmit(e)} />
      }
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={t("EDIT_FACILITY")}
      onClose={() => {
        close();
      }}
      style={{ width: "600px" }}
    >
      <Body as="div" className="facility_input_container" size="Four">
        <Input
          type="text"
          label={t("FACILITY_NAME")}
          value={product?.siteName}
          onChange={(e) => onInputChange(e.target.value, "siteName")}
          required
          className="facility_input_control"
          message={errors && t(errors["siteName"])}
          isInvalid={errors && "siteName" in errors}
        />
        <div className="m_d_facility_dropdown_container">
          <Dropdown
            isOpen={isStatusDropdownOpen}
            title={product?.facilityStatus}
            iconColor="#73808C"
            label={t("FACILITY_STATUS")}
            menuAriaLabel="facilityStatus"
            className="m_d_facility_dropdown"
            onSelect={(e) => onInputChange(e, "facilityStatus")}
            onToggle={() =>
              setStatusDropdown((isStatusDropdownOpen) => !isStatusDropdownOpen)
            }
            isValid={!(errors && "facilityStatus" in errors)}
            message={errors && t(errors["facilityStatus"])}
            overflowMenuContainerDirection="right"
          >
            {Status?.map((item, index) => {
              return (
                <DropdownMenuItem
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={product?.facilityStatus === item}
                  key={item}
                  className="m_d_facility_dropdown_item"
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div>
        <Input
          type="text"
          label={t("STREET_NAME")}
          value={product?.streetName}
          onChange={(e) => onInputChange(e.target.value, "streetName")}
          required
          className="facility_input_control"
          message={errors && t(errors["streetName"])}
          isInvalid={errors && "streetName" in errors}
        />
        <Input
          type="text"
          label={t("STREET_NUMBER")}
          value={product?.streetNo}
          onChange={(e) => onInputChange(e.target.value, "streetNo")}
          required
          className="facility_input_control"
          message={errors && t(errors["streetNo"])}
          isInvalid={errors && "streetNo" in errors}
        />
        <Input
          type="text"
          label={t("POST_CODE")}
          value={product?.postCode}
          onChange={(e) => onInputChange(e.target.value, "postCode")}
          required
          className="facility_input_control"
          message={errors && t(errors["postCode"])}
          isInvalid={errors && "postCode" in errors}
        />
        <Input
          type="text"
          label={t("CITY")}
          value={product?.city}
          onChange={(e) => onInputChange(e.target.value, "city")}
          required
          className="facility_input_control"
          message={errors && t(errors["city"])}
          isInvalid={errors && "city" in errors}
        />
        {/* <Input
          type="text"
          label={t("FACILITY_DCO_MAP_URL")}
          value={product?.facilityIdMapUrl}
          onChange={(e) => onInputChange(e.target.value, "facilityIdMapUrl")}
          required
          className="facility_input_control"
          message={errors && t(errors["facilityIdMapUrl"])}
          isInvalid={errors && "facilityIdMapUrl" in errors}
        /> */}
      </Body>
    </Dialog>
  );
};

export default EditMDFacility;
