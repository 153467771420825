import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { _getFacilityList } from "../../Services/slotServices";
import {
  Button,
  Dialog,
  Body,
  Dropdown,
  DropdownMenuItem,
  Input,
} from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import { showErorToast } from "../../CommomFiles/globalFunctions";

const FacilitySelectionModal = ({ getSelected, getRows }) => {
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isFacilityopen, setFacilityOpen] = useState(false);
  const [rows, setRows] = useState(10);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getFacilityList();
  }, []);

  const getFacilityList = () => {
    dispatch(
      _getFacilityList(
        { orgId: "o1", facilityStatus: "Active" },
        (res) => {
          setFacilityOptions(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const handleSubmit = () => {
    if (selectedFacility && rows && rows > 0) {
      getSelected(selectedFacility);
      getRows(rows);
    } else {
      showErorToast(t("PLEASE_SELECT_FACILITY_ERROR"));
    }
  };
  return (
    <Dialog
      bottomButtons={
        <Button
          iconPosition="right"
          onClick={handleSubmit}
          style={{ marginRight: "auto" }}
        >
          {t("SUBMIT")}
        </Button>
      }
      disableOutsideClick={true}
      noCloseButton={true}
      className="select_facility_modal"
    >
      <Body as="div" size="Four">
        <h4>{t("PLEASE_SELECT_FACILITY")}</h4>
        <Dropdown
          isOpen={isFacilityopen}
          title={selectedFacility?.siteName}
          iconColor="#73808C"
          label={t("SELECT_FACILITY")}
          menuAriaLabel={t("SELECT_FACILITY")}
          onSelect={(e) => setSelectedFacility(e)}
          onToggle={() => setFacilityOpen((isFacilityopen) => !isFacilityopen)}
          overflowMenuContainerDirection="right"
          className="rolling_facility_dropdown"
        >
          {facilityOptions?.map((item, index) => {
            return (
              <DropdownMenuItem
                allowWrap
                aria-label="Menu item"
                tabIndex={index}
                value={item}
                key={item.facilityId}
                selected={selectedFacility?.facilityId == item.facilityId}
                className="dropdownMenuItem"
              >
                {item.siteName}
              </DropdownMenuItem>
            );
          })}
        </Dropdown>
        <br />
        <Input
          id="input_row"
          label={t("ROW_PER_PAGE")}
          onChange={(e) => setRows(parseInt(e.target.value))}
          type="number"
          value={rows}
          className="b_s_input_control"
          min={5}
          max={20}
        />
      </Body>
    </Dialog>
  );
};

export default FacilitySelectionModal;
