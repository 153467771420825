import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownMenuItem,
  Input,
} from "@postidigital/posti-components";
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { showToast } from "../../CommomFiles/globalFunctions";
import { editFormSchema } from "../../CommomFiles/FormsValidationSchema";
import moment from "moment";
import {
  _getDriverList,
  _getCustomerList,
} from "../../Services/customerServices";
import { hideLoader, showLoader } from "../../Store/Actions/commonAction";
import { _modifyCarrierBookings } from "../../Services/carrierServices";
import { DATEFORMAT } from "../../config/constant";

const EditBooking = ({ close, booking, updateList }) => {
  const [drivers, setDrivers] = useState();
  const [customers, setCustomers] = useState();
  const [isCustomerDropdownOpen, setCustomerDropdown] = useState(false);
  const [isDriverDropdownOpen, setDriverDropdown] = useState(false);
  const [errors, setErrors] = useState(null);
  const [product, setProduct] = useState(booking);
  const oldData = booking;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);

  useEffect(() => {
    getCustomerOptions();
  }, []);

  const getCustomerOptions = () => {
    dispatch(showLoader());
    _getCustomerList(
      { orgId: "o1", customerStatus: "Active" },
      (res) => {
        setCustomers(res.data);
        getDriverOptions();
      },
      (err) => {
        console.log(err);
        dispatch(hideLoader());
      }
    );
  };

  const getDriverOptions = () => {
    _getDriverList(
      { carrierId: userDetail?.user?.username, driverStatus: "Active" },
      (res) => {
        setDrivers(res.data);
        dispatch(hideLoader());
      },
      (err) => {
        console.log(err);
        dispatch(hideLoader());
      }
    );
  };

  const modifyBooking = () => {
    if (product["originETD"])
      product["originETD"] = moment(product.originETD, DATEFORMAT).format(
        DATEFORMAT
      );
    const result = { bookingId: product.bookingId, action: "MODIFY_BOOKING" };
    for (const key in oldData) {
      if (key === "driverDetails") {
        if (oldData[key]["driverId"] !== product[key]["driverId"]) {
          result[key] = product[key];
        }
      }
      if (key === "customerDetails") {
        if (oldData[key]["customerId"] !== product[key]["customerId"]) {
          result[key] = product[key];
        }
      }
      if (oldData[key] !== product[key]) {
        result[key] = product[key];
      }
    }
    dispatch(
      _modifyCarrierBookings(
        result,
        (res) => {
          const responseData = res.data;
          updateList(responseData[0]);
          showToast(t("EDITING_SUCCESSFUL"), "success");
          close();
        },
        (err) => {
          console.log(err);
          showToast(t("EDITING_FAILED"), "error");
        }
      )
    );
  };

  const onInputChange = (val, key) => {
    const _product = { ...product };
    _product[`${key}`] = val;
    setProduct(_product);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await editFormSchema.isValid(product, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      modifyBooking();
    } else {
      // If form is not valid, check which fields are incorrect:
      editFormSchema.validate(product, { abortEarly: false }).catch((err) => {
        const errors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: error.message,
          };
        }, {});
        setErrors(errors);
      });
    }
  };

  return (
    <Dialog
      visible={true}
      style={{ width: "815px", height: "1000px" }}
      header={t("EDIT_BOOKING")}
      onHide={close}
    >
      <div style={{ width: "300px", marginLeft: "50px", marginTop: "20px" }}>
        <Input label={t("BOOKING_ID")} value={product?.bookingId} disabled />
      </div>
      <div style={{ width: "300px", marginLeft: "400px", marginTop: "-65px" }}>
        <DateInput
          firstDayOfWeek={0}
          displayFormat={DATEFORMAT}
          id="bookigngDateInput"
          label={t("EXPECTED_DEPARTURE_DATE")}
          locale="en"
          onDateChange={(date) =>
            onInputChange(date ? date._d : "", "originETD")
          }
          date={
            product?.originETD ? moment(product?.originETD, DATEFORMAT) : ""
          }
          className="date_Input"
          nextMonthButtonAriaLabel="Move to next month"
          prevMonthButtonAriaLabel="Move to previous month"
          readOnly={true}
          className="license_Number"
        />
      </div>
      <div
        style={{
          width: "300px",
          marginLeft: "50px",
          marginTop: "20px",
          height: "100px",
        }}
      >
        <Dropdown
          isOpen={isCustomerDropdownOpen}
          title={product?.customerDetails.customerName}
          iconColor="#73808C"
          label={t("CUSTOMER_NAME")}
          menuAriaLabel="Customer Name"
          onSelect={(e) => onInputChange(e, "customerDetails")}
          onToggle={() =>
            setCustomerDropdown(
              (isCustomerDropdownOpen) => !isCustomerDropdownOpen
            )
          }
          overflowMenuContainerDirection="right"
          className="editBookingDropdown"
        >
          {customers?.map((item, index) => {
            return (
              <DropdownMenuItem
                allowWrap
                aria-label="Menu item"
                tabIndex={index}
                value={item}
                selected={
                  product?.customerDetails.customerName === item?.customerName
                }
                key={item.customerName}
                className="dropdownMenuItem"
              >
                {item.customerName}
              </DropdownMenuItem>
            );
          })}
        </Dropdown>
      </div>
      <div style={{ width: "300px", marginLeft: "400px", marginTop: "-100px" }}>
        <Dropdown
          isOpen={isDriverDropdownOpen}
          title={product?.driverDetails.driverName}
          iconColor="#73808C"
          label={t("DRIVER_NAME")}
          menuAriaLabel="Driver Name"
          onSelect={(e) => onInputChange(e, "driverDetails")}
          onToggle={() =>
            setDriverDropdown((isDriverDropdownOpen) => !isDriverDropdownOpen)
          }
          overflowMenuContainerDirection="right"
          className="editBookingDropdown"
        >
          {drivers?.map((item, index) => {
            return (
              <DropdownMenuItem
                allowWrap
                aria-label="Menu item"
                tabIndex={index}
                value={item}
                selected={
                  product?.driverDetails.driverName === item?.driverName
                }
                key={item.driverName}
                className="dropdownMenuItem"
              >
                {item.driverName}
              </DropdownMenuItem>
            );
          })}
        </Dropdown>
      </div>
      <div style={{ width: "300px", marginLeft: "50px", marginTop: "20px" }}>
        <Input
          type="text"
          label={t("PO_REF")}
          value={product?.PORef}
          onChange={(e) => onInputChange(e.target.value, "PORef")}
          required
          message={errors && t(errors["PORef"])}
          isInvalid={errors && "PORef" in errors}
        />
      </div>
      <div style={{ width: "300px", marginLeft: "400px", marginTop: "-65px" }}>
        <Input
          type="text"
          label={t("VEHICLE_LICENSE_PLATE_NUMBER")}
          value={product?.vehicleLicensePlateNumber}
          onChange={(e) =>
            onInputChange(e.target.value, "vehicleLicensePlateNumber")
          }
          required
          message={errors && t(errors["vehicleLicensePlateNumber"])}
          isInvalid={errors && "vehicleLicensePlateNumber" in errors}
        />
      </div>
      <div style={{ width: "300px", marginLeft: "50px", marginTop: "20px" }}>
        <Input
          type="text"
          label={t("NUMBER_OF_PALLETS")}
          value={product?.numberOfPallets}
          onChange={(e) => onInputChange(e.target.value, "numberOfPallets")}
          required
          message={errors && t(errors["numberOfPallets"])}
          isInvalid={errors && "numberOfPallets" in errors}
        />
      </div>
      <div style={{ width: "300px", marginLeft: "400px", marginTop: "-65px" }}>
        <Input
          type="text"
          label={t("SHIPPER_INVOICE_REF")}
          value={product?.shipperInvoiceRef}
          onChange={(e) => onInputChange(e.target.value, "shipperInvoiceRef")}
          required
          message={errors && t(errors["shipperInvoiceRef"])}
          isInvalid={errors && "shipperInvoiceRef" in errors}
        />
      </div>
      <div style={{ width: "300px", marginLeft: "50px", marginTop: "20px" }}>
        <Input
          type="text"
          label={t("LICENSE_NUMBER")}
          value={product?.driverDetails.drivingLicenseNo}
          onChange={(e) => onInputChange(e.target.value, "drivingLicenseNo")}
          readOnly={true}
          className="license_Number"
        />
      </div>
      <div style={{ width: "300px", marginLeft: "480px", marginTop: "25px" }}>
        <Button
          style={{
            backgroundColor: "#E84F1E",
            color: "white",
            width: "150px",
            borderRadius: "60px",
            height: "50px",
            fontSize: "20px",
          }}
          onClick={(e) => onSubmit(e)}
        >
          {t("SAVE")}
        </Button>
      </div>
    </Dialog>
  );
};

export default EditBooking;
