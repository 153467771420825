import React, { useEffect, useState } from "react";
import {
  TimePickerGroup,
  Dropdown,
  DropdownMenuItem,
  Loading,
} from "@postidigital/posti-components";
import DashboardContainer from "../components/Dashboard/DashboardContainer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoIcon from "@mui/icons-material/Info";
import { _getFacilityList, _getSlotData } from "../Services/slotServices";
import BookSlotDetail from "../components/BookSlot/BookSlotDetail";
import moment from "moment";
import {
  addZero,
  getCurrentWeekDays,
  getFacilityOpeningClosingTime,
  getNextWeekDays,
  isToday,
  showWarningToast,
} from "../CommomFiles/globalFunctions";

import PageSublink from "../components/Dashboard/PageSublink";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DATETIMEFORMAT } from "../config/constant";
import _ from 'lodash';
import {
  _getUpcomingWeeks
} from "../Services/facilityServices";
import { hideLoader, showLoader } from '../Store/Actions/commonAction';

const SlotBooking = (props) => {
  const [slots, setSlots] = useState(null);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [isFacilityopen, setFacilityOpen] = useState(false);
  const [selectedFacilty, setSelectedFacility] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("CURRENT");
  const [slotLoading, setSlotLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [nextWeek, setNextWeek] = useState(null);
  const [officeTime, setOfficeTime] = useState({});
  const [allNextWeeksSlots, setAllNextWeeksSlots] = useState([]);
  const [upcomingWeek, setupcomingWeek] = useState(null);
  const [isUpcomingWeekOpen, setUpcomingWeekOpen] = useState(false);
  const [upcomingWeekOptions, setUpcomingWeekOptions] = useState([]);
  const [dateRanges, setDateRanges] = useState([]);
  const [tommrowDate, setTommrowDate] = useState(null);
  const [fridayDate, setFridayDate] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getFacilityList();
    getCurrentWeek();
    getNextWeek();
  }, []);

  useEffect(() => {
    if (selectedFacilty && selectedWeek) {
      setSelectedSlot(null);
      setSlotLoading(true);
      getSlotData();

    }
  }, [selectedFacilty, selectedWeek]);

  useEffect(() => {
    if (slots?.length > 0) {
      const time = getFacilityOpeningClosingTime(slots);
      setOfficeTime(time);
    }
  }, [slots]);

  const getFacilityList = () => {
    dispatch(
      _getFacilityList(
        { orgId: "o1", facilityStatus: "Active" },
        (res) => {
          setFacilityOptions(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const getCurrentWeek = () => {
    const weekDays = getCurrentWeekDays();
    setCurrentWeek(weekDays);
  };

  const getNextWeek = () => {
    const days = getNextWeekDays();
    setNextWeek(days);
  };

  const getSlotData = () => {
    let params = {
      facilityId: selectedFacilty?.facilityId,
      startDate: "",
      endDate: "",
    };
    if (selectedWeek === "CURRENT") {
      params.startDate = currentWeek.start;
      params.endDate = currentWeek.end;
    } else {
      params.startDate = nextWeek.start;
      params.endDate = nextWeek.end;
    }
    _getSlotData(
      params,
      (res) => {
        let finalSlots = res.data.map((daySlot) => {
          let temp = daySlot;
          temp.date = daySlot.slotDate;
          let tempslots = temp.slots.map((s) => {
            const obj = {
              from: moment(s.from, DATETIMEFORMAT).toDate(),
              to: moment(s.to, DATETIMEFORMAT).toDate(),
            };
            return obj;
          });
          temp.slots = tempslots;
          return temp;
        });

        const today = new Date();
        let tomorrow = new Date();
        let friday = new Date();
        //returns the tomorrow date
        if (selectedWeek === "CURRENT"){
          tomorrow.setDate(today.getDate() + 1)
          setTommrowDate(tomorrow);
        }
      
        if(new Date().getDay() === 5 && selectedWeek === "NEXT"){
          friday.setDate(today.getDate() + 3);
          setFridayDate(friday);
        }

        //Carrier service provider should be able to book slots for tomorrow latest 14.00
        if ((selectedWeek === "CURRENT") && ((new Date().getHours() < 10 ? '0' : '') + new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes() > '13:59')) {
          let currentlyAvailableSlots = finalSlots.filter((data) => {
            return data.slotDate !== tomorrow.getFullYear() + '-' + (convertDateFormat(tomorrow.getMonth() + 1)) + '-' + convertDateFormat(tomorrow.getDate());
          });

          setSlots(currentlyAvailableSlots);
        }
        else
          if (selectedWeek === "NEXT") {

            setSlotLoading(true);
            setSlots([]);
            setAllNextWeeksSlots([]);

            // Upcoming Weeks Slots
            var upComingAvaiableSlots = getUpcomingWeeksDateSlots(finalSlots);

            //On Friday after 2:00 PM, user can't book slot for upcoming Monday
            if ((new Date().getDay() === 5) && ((new Date().getHours() < 10 ? '0' : '') + new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes() > '13:59')) {
              const today = new Date();
              let tomorrow = new Date();

              //returns upcoming Monday
              tomorrow.setDate(today.getDate() + 3);

              let currentlyAvailableSlots = upComingAvaiableSlots.filter((data) => {
                return data.slotDate !== tomorrow.getFullYear() + '-' + (convertDateFormat(tomorrow.getMonth() + 1)) + '-' + convertDateFormat(tomorrow.getDate());
              });

              setAllNextWeeksSlots(currentlyAvailableSlots);
            }

            //On Saturday, user can't book slot for upcoming Monday
            else if ((new Date().getDay() === 6)) {
              const today = new Date();
              let tomorrow = new Date();

              //returns upcoming Monday
              tomorrow.setDate(today.getDate() + 2);

              let currentlyAvailableSlots = upComingAvaiableSlots.filter((data) => {
                return data.slotDate !== tomorrow.getFullYear() + '-' + (convertDateFormat(tomorrow.getMonth() + 1)) + '-' + convertDateFormat(tomorrow.getDate());
              });

              setAllNextWeeksSlots(currentlyAvailableSlots);
            }

            //On Sunday, user can't book slot for upcoming Monday
            else if ((new Date().getDay() === 7)) {
              const today = new Date();
              let tomorrow = new Date();

              //returns upcoming Monday
              tomorrow.setDate(today.getDate() + 1);

              let currentlyAvailableSlots = upComingAvaiableSlots.filter((data) => {
                return data.slotDate !== tomorrow.getFullYear() + '-' + (convertDateFormat(tomorrow.getMonth() + 1)) + '-' + convertDateFormat(tomorrow.getDate());
              });

              setAllNextWeeksSlots(currentlyAvailableSlots);
            }
            else {
              setAllNextWeeksSlots(upComingAvaiableSlots);
            }
            
          }
          else {
            setSlots(finalSlots);
          }
        setSlotLoading(false);
      },
      (err) => { setSlotLoading(false);}
    );
  };

  const filterChangeHandle = (type, value) => {
    switch (type) {
      case "F":
        setUpcomingWeekOptions([]);
        setupcomingWeek(null);
        getUpcomingWeeksOptions(value,value.facilityId);
       
        break;
      case "W":
        setSelectedWeek(value);
        if (value === "CURRENT") {
          setupcomingWeek(null)
        }

        break;
    }
  };

  const renderSlotHeader = (e) => {
    if (isToday(e.day.date))
      return <span style={{ color: "#0051C2" }}>{e.day.date}</span>;
    else return <span>{e.day.date}</span>;
  };

  //function to add '0' in single digit month (ex:01,02)
  const convertDateFormat = (data) => {
    let newData = 0
    if (data < 10) {
      newData = '0' + data;
      return newData
    }
    else {
      return data
    }
  }

  const upcomingWeekHandler = (value) => {
    setupcomingWeek(value);
    const filteredSlots = allNextWeeksSlots.filter((data) => {
      return data.Week === value;
    });
    setSlots(filteredSlots);
  };

  const getUpcomingWeeksDateSlots = (dataset) => {
    const nextWeekSlots = [];
    dateRanges.map(weekData => {
      dataset.map(slotData => {
        if (new Date(slotData.slotDate) >= new Date(weekData.planStartDate) && new Date(slotData.slotDate) <= new Date(new Date(new Date(weekData.planEndDate).setDate(new Date(weekData.planEndDate).getDate() + 1)))) {
          nextWeekSlots.push({
            date: slotData.date,
            slotDate: slotData.slotDate,
            slots: slotData.slots,
            Week: "Week " + "(" + changeDateFormat(new Date(weekData.planStartDate)) + " to " + changeDateFormat(new Date(weekData.planEndDate)) + ")"
          })
        }
      })
    })
   
    return nextWeekSlots;
  }

  const getUpcomingWeeksOptions = (facility,facilityId) => {
    dispatch(showLoader());
    setDateRanges([]);
    const options = [];
    if (facilityId) {
      _getUpcomingWeeks(
        { facilityId: facilityId },
        (res) => {
          setDateRanges(res.data);
          setSelectedFacility(facility);
          if (res.data.length > 0) {
            res.data.map((data) => {
              options.push({ label: data.weekDates, value: data.weekDates });
            })
            setUpcomingWeekOptions(options);
            dispatch(hideLoader());
          }
          else {
            dispatch(hideLoader());
          }
        },
        (err) => {
          console.log(err);
          dispatch(hideLoader());
        }
      );
    }

  };

  const changeDateFormat = (date) => {
    var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
  }

  const getSlotInformation = (userSelectedSlot) => {
    if (tommrowDate.getFullYear() + '-' + (convertDateFormat(tommrowDate.getMonth() + 1)) + '-' + convertDateFormat(tommrowDate).getDate() === (userSelectedSlot.from).getFullYear() + '-' + (convertDateFormat((userSelectedSlot.from).getMonth() + 1)) + '-' + convertDateFormat((userSelectedSlot.from).getDate()) &&
      ((selectedWeek === "CURRENT") && ((new Date().getHours() < 10 ? '0' : '') + new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes() > '13:59'))) {
      showWarningToast(t("BOOKING_ISSUE"));
      setSelectedSlot(null);
    }
    else if((selectedWeek === "NEXT") 
    &&(new Date().getDay() === 5) && ((new Date().getHours() < 10 ? '0' : '') + new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes() > '13:59')
    && (fridayDate.getFullYear() + '-' + (convertDateFormat(fridayDate.getMonth() + 1)) + '-' + convertDateFormat(fridayDate).getDate() === (userSelectedSlot.from).getFullYear() + '-' + (convertDateFormat((userSelectedSlot.from).getMonth() + 1)) + '-' + convertDateFormat((userSelectedSlot.from).getDate())
     )
    ){
      showWarningToast(t("BOOKING_ISSUE_NXTWEEK"));
      setSelectedSlot(null);
    }
    else {
      setSelectedSlot(userSelectedSlot);
    }

  }

  return (
    <DashboardContainer>
      <div className="slot-booking-page">
        <PageSublink pageName={t("BOOK_A_SLOT_HEADING")} />
        <h2 className="book_slot_title">{t("BOOK_A_SLOT_HEADING")}</h2>
        <div className="slot_row">
          <div className="slot_colom_left">
            <div className="facility_dropdown">
              <Dropdown
                isOpen={isFacilityopen}
                title={selectedFacilty?.siteName}
                iconColor="#73808C"
                label={t("SELECT_FACILITY")}
                menuAriaLabel={t("SELECT_FACILITY")}
                onSelect={(e) => filterChangeHandle("F", e)}
                onToggle={() =>
                  setFacilityOpen((isFacilityopen) => !isFacilityopen)
                }
                overflowMenuContainerDirection="right"
                className="facility_dropdown_btn"
              >
                {facilityOptions?.map((item, index) => {
                  return (
                    <DropdownMenuItem
                      allowWrap
                      aria-label="Menu item"
                      tabIndex={index}
                      value={item}
                      selected={selectedFacilty?.facilityId == item.facilityId}
                      key={item.facilityId}
                      className="dropdownMenuItem"
                    >
                      {item.siteName}
                    </DropdownMenuItem>
                  );
                })}
              </Dropdown>
            </div>

            <div className="weeks">
              <RadioGroup
                aria-label="week"
                name="radio-buttons-group"
                className="weeks_radio"
                onChange={(e) => filterChangeHandle("W", e.target.value)}
                value={selectedWeek}
              >
                <FormControlLabel
                  value={"CURRENT"}
                  control={<Radio className="currentweek_radio" />}
                  label={t("CURRENT_WEEK")}
                  className="radio-1"
                  disabled={selectedFacilty === ""}
                />
                <FormControlLabel
                  value={"NEXT"}
                  control={<Radio className="currentweek_radio" />}
                  label={t("NEXT_WEEK")}
                  disabled={selectedFacilty === ""}
                />
              </RadioGroup>
            </div>
            {
              selectedWeek === "NEXT" ?
                <div className="upcomingWeeks">
                  <Dropdown
                    isOpen={isUpcomingWeekOpen}
                    title={upcomingWeek}
                    iconColor="#73808C"
                    label={t("UPCOMING_WEEK")}
                    menuAriaLabel={t("UPCOMING_WEEK")}
                    message=""
                    onSelect={(e) => upcomingWeekHandler(e)}
                    onToggle={() =>
                      setUpcomingWeekOpen(
                        (isUpcomingWeekOpen) => !isUpcomingWeekOpen
                      )
                    }
                    overflowMenuContainerDirection="right"
                    className="selectedSlotsDropdownButton"
                    disabled={selectedFacilty === ""}
                  >
                    {upcomingWeekOptions.map((item, index) => {
                      return (
                        <DropdownMenuItem
                          allowWrap
                          aria-label="Menu item"
                          tabIndex={index}
                          value={item.value}
                          selected={upcomingWeek === item.value}
                          className="dropdownMenuItem"
                          key={item.value}
                        >
                          {item.label}
                        </DropdownMenuItem>
                      );
                    })}
                  </Dropdown>
                </div> : null
            }
            {slotLoading ? (
              <div className="loading_container">
                <Loading color="#38572B" size="sm" statusText="Loading..." />
              </div>
            ) : slots ? (
              slots.length === 0 ?
                selectedWeek === "NEXT" && upcomingWeek === null ?
                  (
                    <p className="no_data_text">
                      {t("SELECT_UPCOMING_WEEK")}
                    </p>
                  ) :
                  (

                    <p className="no_data_text">{t("NO_SLOTS")}</p>
                  ) : (
                  <>
                    <p className="info_para">
                      <InfoIcon fontSize="small" /> {t("EACH_SLOT_90_MINUTE")}
                    </p>
                    <TimePickerGroup
                      DayHeaderContent={(e) => renderSlotHeader(e)}
                      earliestHour={officeTime?.from}
                      latestHour={officeTime?.to}
                      getHourFromDate={(e) =>
                        `${addZero(e.getHours())}:${addZero(e.getMinutes())}`
                      }
                      selected={selectedSlot ? selectedSlot : null}
                      timeSlots={slots}
                      className="slot_groups"
                      TableItemComponent={(e) => {
                        if (e.disabled) return null;
                        else {
                          return (
                            <div className="slot_item_outer">
                              <button
                                className={
                                  e.selected ? "slot_item selected" : "slot_item"
                                }
                                onClick={() => {
                                  const slot = {
                                    from: e.children.props.fromDate,
                                    to: e.children.props.toDate,
                                  };
                                  getSlotInformation(slot);
                                }}
                              >
                                {e.children.props.from}
                              </button>
                            </div>
                          );
                        }
                      }}
                    />
                  </>
                )
            ) : (
              <p className="no_data_text">
                {t("SELECT_FACILITY_AND_WEEK_TO_SHOW_SLOT")}
              </p>
            )}
          </div>
          {selectedSlot && (
            <div className="slot_colom_right">
              <BookSlotDetail
                bookedSlot={selectedSlot}
                facilityId={selectedFacilty?.facilityId}
              />
            </div>
          )}
        </div>
      </div>
    </DashboardContainer>
  );
};

export default SlotBooking;
