const endPoints = {
  slotURL: "slot",
  carrierURL: "carrier",
  facilityURL: "facility",
  getSlotBookingURL: "getslotbooking",
  driverURL: "carrier/driver",
  customerURL: "customer",
  facilityDockURL: "facility/dock",
  slotBookingURL: "slot/booking",
  eventSourceURL: "./unsecure/getWHMNotificationDataStream",
  slotPlanningURL: "slot/planning",
  slotBookingDockDataURL: "dev/slot/booking",
  // slotBookingDockDataURL: "slot/booking",
  masterDataDriverURL: "carrier/driver",
  masterDataCarrierURL: "carrier",
  masterDataCustomerURL: "customer",
  masterDataFacilityURL: "facility",
  masterDataFacilityDocURL: "facility/dock",
  slotReplanningURL: "slot/dock",
  slotPrebookingURL: "slot/dock/booking",
  slotDeletingURL: "slot/dock",
  rollingScreenURL: "slot/booking",
  manageManagersURL: "facility/manager",
  alreadyPlannedDated: "slot/slotRange",
  getUpcomingWeeks: "slot/slotWeekRange",
  getAutoGeneratedUserId:"/getcognitoid",
  getUserGroups:"/groups",
  removeCarriers:"/carrier/deleteCarrier",
  removeCustomers:"/customer/deleteCustomer",
  getCustomerAutoGeneratedUserId:"/customer/getcognitoid",
};

export default endPoints;
