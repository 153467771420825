import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import DashboardContainer from "../../components/Dashboard/DashboardContainer";
import { _getBookingsData, _modifyBookings } from "../../Services/wHMServices";
import { _getFacilityList } from "../../Services/slotServices";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenu } from "primereact/contextmenu";
import { Dialog } from "primereact/dialog";
import { formateDate, showToast } from "../../CommomFiles/globalFunctions";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import GeneratePdf from "../../components/QRcode/GeneratePdf";
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import { DATEFORMAT, DATETIMEFORMAT } from "../../config/constant";
import {
  Dropdown as PostiDropdown,
  DropdownMenuItem,
} from "@postidigital/posti-components";
import PageSublink from "../../components/Dashboard/PageSublink";

const status = [
  "Booked",
  "Cancelled",
  "Checked In",
  "Delayed Check In",
  "Delayed",
  "Departed",
  "Dock Unavailable",
  "Released",
];

const typeValue = ["Container", "Truck", "Special Booking"];

const AllBookings = () => {
  const today = new Date();
  const month = today.getMonth();

  const minDate = new Date();
  minDate.setMonth(month);

  const [bookings, setBookings] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [delayProductsDialog, setDelayProductsDialog] = useState(false);
  const [cancelProductsDialog, setCancelProductsDialog] = useState(false);
  const [departProductsDialog, setDepartProductsDialog] = useState(false);
  const [extendBookingDialog, setExtendBookingDialog] = useState(false);
  const [releasedBookingDialog, setReleasedBookingDialog] = useState(false);
  const [warningDialog, setWarningDialog] = useState(false);
  const [product, setProduct] = useState();
  const [isFacilityopen, setFacilityOpen] = useState(false);
  const [selectedFacilty, setSelectedFacility] = useState("");
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [viewTable, setViewTable] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format(DATEFORMAT));
  const [pdfData, setPdfData] = useState(null);
  const userDetail = useSelector((state) => state.user.userDetail);
  const childRef = useRef();

  const cm = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let menuModel = [];
  if (selectedProduct != null) {
    switch (selectedProduct.shipmentStatus) {
      case "Booked":
        if (moment().diff(selectedProduct?.originETD) < 0) {
          menuModel = [
            {
              label: t("DOWNLOAD_PDF"),
              icon: "pi pi-fw pi-file-pdf",
              command: () => createPDFData(selectedProduct),
            },
            {
              label: t("CANCEL_BOOKING"),
              icon: "pi pi-fw pi-times-circle",
              command: () => confirmCancelSelected(selectedProduct),
            },
          ];
        } else {
          menuModel = [
            {
              label: t("DOWNLOAD_PDF"),
              icon: "pi pi-fw pi-file-pdf",
              command: () => createPDFData(selectedProduct),
            },
            {
              label: t("DEPARTED"),
              icon: "pi pi-fw pi-check",
              command: () => confirmDepartSelected(selectedProduct),
            },
            {
              label: t("CANCEL_BOOKING"),
              icon: "pi pi-fw pi-times-circle",
              command: () => confirmCancelSelected(selectedProduct),
            },
          ];
        }
        break;
      case "Departed":
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
          {
            label: t("REPORT_DELAY"),
            icon: "pi pi-fw pi-clock",
            command: () => confirmDelaySelected(selectedProduct),
          },
        ];
        break;
      case "Delayed":
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
          {
            label: t("CANCEL_BOOKING"),
            icon: "pi pi-fw pi-times-circle",
            command: () => confirmCancelSelected(selectedProduct),
          },
        ];
        break;
      case "Checked In":
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
          {
            label: t("EXTEND_BOOKING"),
            icon: "pi pi-fw pi-th-large",
            command: () => confirmExtendSelected(selectedProduct),
          },
          {
            label: t("RELEASE"),
            icon: "pi pi-fw pi-check-square",
            command: () => confirmReleasedSelected(selectedProduct),
          },
        ];
        break;
      default:
        menuModel = [
          {
            label: t("DOWNLOAD_PDF"),
            icon: "pi pi-fw pi-file-pdf",
            command: () => createPDFData(selectedProduct),
          },
        ];
        break;
    }
  }

  useEffect(() => {
    getFacilityList();
  }, []);

  const getAllBookings = (value) => {
    const date = moment().format(DATEFORMAT);
    setSelectedDate(date);
    dispatch(
      _getBookingsData(
        { facilityId: value.facilityId, slotBookedDate: date },
        (res) => {
          const updatedData = res.data.map((product) => {
            product["originETD"] = moment(
              product["originETD"],
              DATEFORMAT
            ).toDate();
            product["ETA"] = moment(product["ETA"], DATETIMEFORMAT).toDate();
            return product;
          });
          setBookings(updatedData);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const getFacilityList = () => {
    const params = {
      facilityId:
        userDetail?.facilities?.length > 0
          ? userDetail?.facilities.toString()
          : "",
    };
    dispatch(
      _getFacilityList(
        params,
        (res) => {
          setFacilityOptions(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const filterChangeHandle = (value) => {
    setSelectedFacility(value);
    getAllBookings(value);
    setViewTable(true);
  };

  const selectedDateBooking = (date) => {
    dispatch(
      _getBookingsData(
        { facilityId: selectedFacilty.facilityId, slotBookedDate: date },
        (res) => {
          const updatedData = res.data.map((product) => {
            product["originETD"] = moment(
              product["originETD"],
              DATEFORMAT
            ).toDate();
            product["ETA"] = moment(product["ETA"], DATETIMEFORMAT).toDate();
            return product;
          });
          setBookings(updatedData);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const cancelBooking = (data) => {
    dispatch(
      _modifyBookings(
        {
          bookingId: data.bookingId,
          action: "MODIFY_BOOKING",
          shipmentStatus: "CANCELLED",
        },
        (res) => {
          const responseData = res.data;
          const index = bookings.findIndex(
            (item) => item.bookingId === responseData[0].bookingId
          );
          const tempArray = [...bookings];

          tempArray.splice(index, 1, responseData[0]);
          responseData[0]["originETD"] = moment(
            responseData[0]["originETD"],
            DATEFORMAT
          ).toDate();
          responseData[0]["ETA"] = moment(
            responseData[0]["ETA"],
            DATETIMEFORMAT
          ).toDate();
          tempArray.splice(index, 1, responseData[0]);
          setBookings(tempArray);
          showToast(t("BOOKING_CANCELLED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setCancelProductsDialog(false);
  };

  const delayBooking = (data) => {
    dispatch(
      _modifyBookings(
        {
          bookingId: data.bookingId,
          action: "MODIFY_BOOKING",
          shipmentStatus: "DELAYED",
        },
        (res) => {
          const responseData = res.data;
          const index = bookings.findIndex(
            (item) => item.bookingId === responseData[0].bookingId
          );
          const tempArray = [...bookings];
          responseData[0]["originETD"] = moment(
            responseData[0]["originETD"],
            DATEFORMAT
          ).toDate();

          responseData[0]["ETA"] = moment(
            responseData[0]["ETA"],
            DATETIMEFORMAT
          ).toDate();
          tempArray.splice(index, 1, responseData[0]);
          setBookings(tempArray);
          showToast(t("BOOKING_DELAYED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setDelayProductsDialog(false);
  };

  const departBooking = (data) => {
    dispatch(
      _modifyBookings(
        {
          bookingId: data.bookingId,
          action: "MODIFY_BOOKING",
          shipmentStatus: "DEPARTED",
        },
        (res) => {
          const responseData = res.data;
          const index = bookings.findIndex(
            (item) => item.bookingId === responseData[0].bookingId
          );
          const tempArray = [...bookings];
          tempArray.splice(index, 1, responseData[0]);
          const updatedData = tempArray.map((product) => {
            product["originETD"] = moment(
              product["originETD"],
              DATEFORMAT
            ).toDate();
            product["ETA"] = moment(product["ETA"], DATETIMEFORMAT).toDate();
            return product;
          });
          setBookings(updatedData);
          showToast(t("DEPARTED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setDepartProductsDialog(false);
  };

  const releaseBooking = (data) => {
    dispatch(
      _modifyBookings(
        {
          bookingId: data.bookingId,
          action: "MODIFY_BOOKING",
          shipmentStatus: "RELEASED",
        },
        (res) => {
          const responseData = res.data;
          const index = bookings.findIndex(
            (item) => item.bookingId === responseData[0].bookingId
          );
          const tempArray = [...bookings];
          responseData[0]["originETD"] = moment(
            responseData[0]["originETD"],
            DATEFORMAT
          ).toDate();

          responseData[0]["ETA"] = moment(
            responseData[0]["ETA"],
            DATETIMEFORMAT
          ).toDate();
          tempArray.splice(index, 1, responseData[0]);
          setBookings(tempArray);
          showToast(t("RELEASED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setReleasedBookingDialog(false);
  };

  const extendBooking = (data) => {
    dispatch(
      _modifyBookings(
        { bookingId: data.bookingId, action: "EXTEND_SLOT" },
        (res) => {
          // const responseData = res.data
          // const index = bookings.findIndex(item => item.bookingId == responseData[0].bookingId)
          // let tempArray = [...bookings];
          // tempArray.splice(index, 1, responseData[0])
          // setBookings(tempArray);
          showToast(t("EXTENDED"), "info");
        },
        (err) => {
          console.log(err);
        }
      )
    );
    setExtendBookingDialog(false);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.shipmentStatus === "CHECKED_IN") {
      rowData.shipmentStatus = "Checked In";
    } else if (rowData.shipmentStatus === "BOOKED") {
      rowData.shipmentStatus = "Booked";
    } else if (rowData.shipmentStatus === "DEPARTED") {
      rowData.shipmentStatus = "Departed";
    } else if (rowData.shipmentStatus === "RELEASED") {
      rowData.shipmentStatus = "Released";
    } else if (rowData.shipmentStatus === "CANCELLED") {
      rowData.shipmentStatus = "Cancelled";
    } else if (rowData.shipmentStatus === "DELAYED") {
      rowData.shipmentStatus = "Delayed";
    } else if (rowData.shipmentStatus === "DOCK_UNAVAILABLE") {
      rowData.shipmentStatus = "Dock Unavailable";
    } else if (rowData.shipmentStatus === "CHECK_IN_DELAYED") {
      rowData.shipmentStatus = "Delayed Check In";
    }
    const statusClassName = classNames({
      booked: rowData.shipmentStatus === "Booked",
      departed: rowData.shipmentStatus === "Departed",
      delayed: rowData.shipmentStatus === "Delayed",
      checked_In: rowData.shipmentStatus === "Checked In",
      completed: rowData.shipmentStatus === "Released",
      cancelled: rowData.shipmentStatus === "Cancelled",
      dock_Unavailable: rowData.shipmentStatus === "Dock Unavailable",
      delayed_Check_In: rowData.shipmentStatus === "Delayed Check In",
    });

    return <div className={statusClassName}>{rowData.shipmentStatus}</div>;
  };

  const bookingTypeRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={typeValue}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_BOOKING_TYPE")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const bookingTypeBodyTemplate = (rowData) => {
    if (rowData.bookingType === "CNTR") {
      rowData.bookingType = "Container";
    } else if (rowData.bookingType === "TRUCK") {
      rowData.bookingType = "Truck";
    } else if (rowData.bookingType === "SPCL_BKNG") {
      rowData.bookingType = "Special Booking";
    }
    return <div>{rowData.bookingType}</div>;
  };

  const confirmDelaySelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "DELAYED") {
      setWarningDialog(true);
    } else if (product.shipmentStatus === "CANCELLED") {
      setWarningDialog(true);
    } else {
      setDelayProductsDialog(true);
    }
  };

  const confirmCancelSelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "CANCELLED") {
      setWarningDialog(true);
    } else {
      setCancelProductsDialog(true);
    }
  };

  const confirmDepartSelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "DEPARTED") {
      setWarningDialog(true);
    } else if (product.shipmentStatus === "CANCELLED") {
      setWarningDialog(true);
    } else {
      setDepartProductsDialog(true);
    }
  };

  const confirmExtendSelected = (product) => {
    setProduct(product);
    if (product.shipmentStatus === "CANCELLED") {
      setWarningDialog(true);
    } else {
      setExtendBookingDialog(true);
    }
  };

  const confirmReleasedSelected = (product) => {
    setProduct(product);
    setReleasedBookingDialog(true);
  };

  const handleDateChange = (value) => {
    setSelectedDate(formateDate(value));
    selectedDateBooking(formateDate(value));
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder={DATEFORMAT}
        mask="99/99/9999"
      />
    );
  };

  // const timeFilterTemplate = (options) => {
  //   return (
  //     <Calendar
  //       value={options.value}
  //       onChange={(e) => options.filterCallback(e.value, options.index)}
  //       placeholder="mm/dd/yyyy"
  //     />
  //   );
  // };
  const ETDBodyTemplate = (rowData) => {
    return formatDate(rowData.originETD);
  };
  const ETABodyTemplate = (rowData) => {
    return formatDateandTime(rowData.ETA);
  };

  const formatDateandTime = (value) => {
    // return value.toLocaleString("en-US", { hour12: false });
    return moment(value).format(DATETIMEFORMAT);
  };

  const formatDate = (value) => {
    return moment(value).format(DATEFORMAT);
    // return value.toLocaleString("en-US", {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    // });
  };

  const createPDFData = (selectedProduct) => {
    const singleRow = { ...selectedProduct };
    singleRow["originETD"] = formateDate(singleRow["originETD"]);
    singleRow["ETA"] = moment(singleRow["ETA"]).format(DATETIMEFORMAT);
    setPdfData(singleRow);
  };

  return (
    <DashboardContainer>
      <div>
        {pdfData && (
          <GeneratePdf
            rowData={pdfData}
            ref={childRef}
            hidePdf={() => setPdfData(null)}
          />
        )}
        <ContextMenu
          model={menuModel}
          ref={cm}
          onHide={() => setSelectedProduct(null)}
        />
        <PageSublink pageName={t("VIEW_BOOKINGS_TITLE")} />
        <h1 className="book_slot_title">{t("VIEW_BOOKINGS_TITLE")}</h1>
        <div style={{ width: "325px", marginTop: "20px" }}>
          <PostiDropdown
            isOpen={isFacilityopen}
            title={selectedFacilty?.siteName}
            iconColor="#73808C"
            label={t("SELECT_FACILITY")}
            menuAriaLabel={t("SELECT_FACILITY")}
            onSelect={(e) => filterChangeHandle(e)}
            onToggle={() =>
              setFacilityOpen((isFacilityopen) => !isFacilityopen)
            }
            overflowMenuContainerDirection="right"
            className="facility_dropdown_btn"
          >
            {facilityOptions?.map((item, index) => {
              return (
                <DropdownMenuItem
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={selectedFacilty?.facilityId === item.facilityId}
                  key={item.facilityId}
                  className="dropdownMenuItem"
                >
                  {item.siteName}
                </DropdownMenuItem>
              );
            })}
          </PostiDropdown>
        </div>
        {viewTable ? (
          <div>
            <div
              style={{
                width: "325px",
                marginLeft: "auto",
                marginTop: "-75px",
              }}
            >
              <DateInput
                firstDayOfWeek={0}
                displayFormat={DATEFORMAT}
                id="bookigngDateInput"
                label="ETA"
                locale="en"
                nextMonthButtonAriaLabel="Move to next month"
                prevMonthButtonAriaLabel="Move to previous month"
                onDateChange={(date) => handleDateChange(date ? date._d : "")}
                className="date_Input"
                date={selectedDate ? moment(selectedDate, DATEFORMAT) : ""}
                isOutsideRange={() => {
                  return false;
                }}
              />
            </div>
            <div className="boxAnimation">
              <div>
                <DataTable
                  value={bookings}
                  stripedRows
                  paginator={true}
                  rows={10}
                  rowsPerPageOptions={[5, 10, 20]}
                  contextMenuSelection={selectedProduct}
                  onContextMenuSelectionChange={(e) =>
                    setSelectedProduct(e.value)
                  }
                  onContextMenu={(e) => cm.current.show(e.originalEvent)}
                  rowHover
                  responsiveLayout="scroll"
                >
                  <Column
                    field="bookingId"
                    header={t("BOOKING_ID")}
                    filter
                    showFilterOperator={false}
                    showAddButton={false}
                    dataType="numeric"
                    filterPlaceholder={t("BOOKING_ID")}
                  ></Column>
                  <Column
                    field="PORef"
                    header={t("PO_REF")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("PO_REF")}
                  ></Column>
                  <Column
                    field="customerDetails.customerName"
                    header={t("CUSTOMER_NAME")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("CUSTOMER_NAME")}
                  ></Column>
                  <Column
                    field="vehicleLicensePlateNumber"
                    header={t("VEHICLE_LICENSE_PLATE_NUMBER")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("VEHICLE_LICENSE_PLATE_NUMBER")}
                  ></Column>
                  <Column
                    field="numberOfPallets"
                    header={t("NUMBER_OF_PALLETS_UNITS")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    dataType="numeric"
                    filterPlaceholder={t("NUMBER_OF_PALLETS")}
                  ></Column>
                  <Column
                    field="shipmentStatus"
                    header={t("STATUS")}
                    filterPlaceholder={t("STATUS")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMenuOptions={false}
                    filterElement={statusRowFilterTemplate}
                    body={statusBodyTemplate}
                  ></Column>
                  <Column
                    // filter
                    // filterElement={timeFilterTemplate}
                    body={ETABodyTemplate}
                    field="ETA"
                    showAddButton={false}
                    showFilterOperator={false}
                    dataType="date"
                    filterField="ETA"
                    header={t("EXPECTED_DATE_AND_TIME")}
                    sortable
                    className="ETA_column"
                  ></Column>
                  <Column
                    body={(e) => {
                      return (
                        <span>
                          {e?.ATA
                            ? moment(e.ATA, DATETIMEFORMAT).format("HH:mm")
                            : "-"}
                        </span>
                      );
                    }}
                    field="ATA"
                    header={t("TIME_OF_ARRIVAL")}
                    sortable
                  ></Column>
                  <Column
                    field="assignedDockNo"
                    header={t("DOCK")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("DOCK")}
                  ></Column>
                  <Column
                    field="bookingType"
                    header={t("BOOKING_TYPE")}
                    filterPlaceholder={t("STATUS")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMenuOptions={false}
                    filterElement={bookingTypeRowFilterTemplate}
                    body={bookingTypeBodyTemplate}
                  ></Column>
                  <Column
                    field="driverDetails.driverName"
                    header={t("DRIVER_NAME")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("DRIVER_NAME")}
                  ></Column>
                  <Column
                    field="driverDetails.drivingLicenseNo"
                    header={t("LICENSE_NUMBER")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("LICENSE_NUMBER")}
                  ></Column>
                  <Column
                    field="shipperInvoiceRef"
                    header={t("SHIPPER_INVOICE_REF")}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder={t("SHIPPER_INVOICE_REF")}
                    body={(e) => {
                      return (
                        <span>
                          {e?.shipperInvoiceRef ? e?.shipperInvoiceRef : "-"}
                        </span>
                      );
                    }}
                  ></Column>
                  <Column
                    sortable
                    showAddButton={false}
                    showFilterOperator={false}
                    field="originETD"
                    dataType="date"
                    filterField="originETD"
                    body={ETDBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    header={t("ETD")}
                    className="ETD_column"
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <h1>{t("PLEASE_SELECT_FACILITY")}</h1>
          </div>
        )}
        <Dialog
          visible={delayProductsDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setDelayProductsDialog(false)}
        >
          <div>
            <span style={{ color: "red", fontSize: "18px" }}>
              {t("ARE_YOU_SURE_DELAY")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setDelayProductsDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => delayBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={cancelProductsDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setCancelProductsDialog(false)}
        >
          <div>
            <span style={{ color: "red", fontSize: "18px" }}>
              {t("ARE_YOU_SURE_CANCEL")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setCancelProductsDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => cancelBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={departProductsDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setDepartProductsDialog(false)}
        >
          <div>
            <span
              style={{ color: "red", fontSize: "18px", marginLeft: "110px" }}
            >
              {t("ARE_YOU_SURE_DEPART")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setDepartProductsDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => departBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={extendBookingDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setExtendBookingDialog(false)}
        >
          <div>
            <span style={{ color: "red", fontSize: "18px" }}>
              {t("ARE_YOU_SURE_EXTEND")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setExtendBookingDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => extendBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={warningDialog}
          style={{ width: "450px", height: "205px" }}
          header={t("WARNING")}
          onHide={() => setWarningDialog(false)}
        >
          <div>
            <span
              style={{ color: "red", fontSize: "18px", marginLeft: "83px" }}
            >
              {t("BOOKING_ALREADY")} {t(product?.shipmentStatus)}
            </span>
          </div>
          <div>
            <Button
              onClick={() => setWarningDialog(false)}
              style={{
                color: "blue",
                fontSize: "18px",
                marginLeft: "170px",
                marginTop: "25px",
              }}
            >
              {t("OK")}
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={releasedBookingDialog}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setReleasedBookingDialog(false)}
        >
          <div>
            <span
              style={{ color: "red", fontSize: "18px", marginLeft: "110px" }}
            >
              {t("ARE_YOU_SURE_DEPART")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => setReleasedBookingDialog(false)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "70px",
                  }}
                >
                  {t("NO")}
                </Button>
                <Button
                  onClick={() => releaseBooking(product)}
                  style={{
                    color: "blue",
                    fontSize: "18px",
                    marginLeft: "130px",
                  }}
                >
                  {t("YES")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </DashboardContainer>
  );
};

export default AllBookings;
