import moment from "moment";
import { status } from "../CommomFiles/slotPlanningFunction";
import { DATETIMEFORMAT } from "../config/constant";

export const replanningStatus = ["AVAILABLE", "ALLOTED", "ONHOLD"];

//Function for converting backend data into required format for plotting slots
export const getOneDayData = (replanningData) => {
  const dockDataArray = [];
  const singleDock = {};

  // adding available capacity data
  const availableSlotsObject = {};
  let availableSlotNumber = 0;
  availableSlotsObject["id"] = 0;
  availableSlotsObject["dock"] = "Available Slots";
  availableSlotsObject["dockId"] = "0000";

  for (let n = 0; n < replanningData.slots.length; n++) {
    availableSlotNumber++;
    let availableSingleSlot = {};
    const availableSlotName = "slot" + availableSlotNumber.toString();
    const newSlot = JSON.parse(JSON.stringify(availableSingleSlot));
    newSlot["from"] = replanningData.slots[n].availableCapacity;
    newSlot["status"] = replanningStatus[1];

    availableSingleSlot = newSlot;
    availableSlotsObject[availableSlotName] = availableSingleSlot;
    // console.log("Available capacity", replanningData.slots[n].availableCapacity);
  }
  // console.log("Available slot object", availableSlotsObject);
  const newAvailableDock = JSON.parse(JSON.stringify(availableSlotsObject));
  dockDataArray.push(newAvailableDock);

  // Looping around slots
  for (let i = 0; i < replanningData.slots.length; i++) {
    // console.log("data in array", replanningData.slots[i].slotDocks);

    // Looping around slot docks
    if (i === 0) {
      for (let j = 0; j < replanningData.slots[i].slotDocks.length; j++) {
        // console.log("Docks", replanningData.slots[i].slotDocks[j]);

        const newDock = JSON.parse(JSON.stringify(singleDock));
        newDock["dock"] = replanningData.slots[i].slotDocks[j].dockNo;
        newDock["dockId"] = replanningData.slots[i].slotDocks[j].dockId;
        newDock["id"] = j + 1;

        dockDataArray.push(newDock);
      }
    }
  }

  // Looping through dockDataArray to add alots in dock
  for (let k = 0; k < dockDataArray.length; k++) {
    // console.log("Dock data :", dockDataArray[k]);
    // Looping around slots
    let slotNumber = 0;
    for (let l = 0; l < replanningData.slots.length; l++) {
      //   console.log("Inside main array", replanningData.slots[l]);
      // Looping around slot docks
      for (let m = 0; m < replanningData.slots[l].slotDocks.length; m++) {
        // console.log("Inside slot docks ", replanningData.slots[l].slotDocks[m]);
        // adding slots if dock name is same
        if (
          dockDataArray[k].dock === replanningData.slots[l].slotDocks[m].dockNo
        ) {
          slotNumber++;
          const slotName = "slot" + slotNumber.toString();
          let singleSlot = {};

          // Making single slot object
          const newSlot = JSON.parse(JSON.stringify(singleSlot));
          newSlot["from"] = moment(
            replanningData.slots[l].fromTime,
            DATETIMEFORMAT
          ).format("HH:mm");
          newSlot["to"] = moment(
            replanningData.slots[l].toTime,
            DATETIMEFORMAT
          ).format("HH:mm");
          newSlot["status"] = replanningData.slots[l].slotDocks[m].dockStatus;

          singleSlot = newSlot;
          dockDataArray[k][slotName] = singleSlot;
        }
      }
    }
  }

  // Adding Prebooking details
  for (let m = 0; m < replanningData.preBookings.length; m++) {
    const dockId = replanningData.preBookings[m].dockId;
    // console.log("pre booked docks", replanningData.preBookings[m]);
    for (let n = 0; n < dockDataArray.length; n++) {
      if (dockId === dockDataArray[n].dockId) {
        // console.log("Dock array data", dockDataArray[n]);
        for (let o = 0; o < Object.keys(dockDataArray[n]).length; o++) {
          if (
            Object.keys(dockDataArray[n])[o] !== "dock" &&
            Object.keys(dockDataArray[n])[o] !== "dockId" &&
            Object.keys(dockDataArray[n])[o] !== "id"
          ) {
            for (
              let p = 0;
              p < replanningData.preBookings[m].slots.length;
              p++
            ) {
              if (
                dockDataArray[n][Object.keys(dockDataArray[n])[o]] ===
                replanningData.preBookings[m].slots[p].fromTime
              ) {
                // console.log("From time",replanningData.preBookings[m].slots[p].fromTime)
              }
              const prebookingDetails = JSON.parse(
                JSON.stringify(replanningData.preBookings[m])
              );
              if (
                dockDataArray[n][Object.keys(dockDataArray[n])[o]].status ===
                replanningStatus[1]
              ) {
                // console.log(
                //   "Wants to prebook",
                //   dockDataArray[n][Object.keys(dockDataArray[n])[o]].from
                // );
                const slotTime =
                  dockDataArray[n][Object.keys(dockDataArray[n])[o]].from;
                for (
                  let slot = 0;
                  slot < prebookingDetails.slots.length;
                  slot++
                ) {
                  // console.log(
                  //   "Slots",
                  //   moment(
                  //     prebookingDetails.slots[slot].fromTime,
                  //     DATETIMEFORMAT
                  //   ).format("HH:mm")
                  // );
                  const prebookedSlotTime = moment(
                    prebookingDetails.slots[slot].fromTime,
                    DATETIMEFORMAT
                  ).format("HH:mm");
                  if (slotTime === prebookedSlotTime) {
                    // console.log("Prebooking detail", prebookingDetails);
                    dockDataArray[n][Object.keys(dockDataArray[n])[o]][
                      "preBookingDetails"
                    ] = prebookingDetails;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // console.log("Dock data final array", dockDataArray);
  return dockDataArray;
};

// Function for creating delete slot JSON
export const slotDelete = (
  activeDate,
  uniqueId,
  selectedCellData,
  inboundSlotsData,
  checkBoxFlag,
  checkBoxRowId,
  selectedFacilty
) => {
  const deleteJson = {};
  const slots = [];
  let row = null;
  if (checkBoxFlag === true) {
    row = checkBoxRowId;
  } else {
    row = uniqueId;
  }
  deleteJson["planningDate"] = activeDate;
  deleteJson["facilityId"] = selectedFacilty;

  const dock = inboundSlotsData[row];
  if (checkBoxFlag === true) {
    const allKeys = Object.keys(dock);
    for (let sl = 0; sl < allKeys.length; sl++) {
      if (
        allKeys[sl] !== "dock" &&
        allKeys[sl] !== "dockId" &&
        allKeys[sl] !== "break" &&
        allKeys[sl] !== "id"
      ) {
        if (dock[allKeys[sl]].status === status[0]) {
          const singleSlotData = {};
          const slotDocks = [];
          const dockData = {};
          singleSlotData["fromTime"] =
            activeDate + " " + dock[allKeys[sl]].from;
          singleSlotData["toTime"] = activeDate + " " + dock[allKeys[sl]].to;
          dockData["dockId"] = dock.dockId;
          dockData["dockStatus"] = replanningStatus[2];
          slotDocks.push(dockData);
          singleSlotData["slotDocks"] = slotDocks;
          slots.push(singleSlotData);
        }
      }
    }
  } else {
    for (let sl = 0; sl < selectedCellData.length; sl++) {
      const singleSlotData = {};
      const slotDocks = [];
      const dockData = {};
      singleSlotData["fromTime"] =
        activeDate + " " + dock[selectedCellData[sl]].from;
      singleSlotData["toTime"] =
        activeDate + " " + dock[selectedCellData[sl]].to;
      dockData["dockId"] = dock.dockId;
      dockData["dockStatus"] = replanningStatus[2];
      slotDocks.push(dockData);
      singleSlotData["slotDocks"] = slotDocks;
      slots.push(singleSlotData);
      // console.log("Slot", dock[selectedCellData[sl]]);
    }
  }

  deleteJson["slots"] = slots;
  // console.log("Dock", dock);
  return deleteJson;
};

// Function for creating slot available JSON
export const slotAvailable = (
  activeDate,
  uniqueId,
  selectedCellData,
  inboundSlotsData,
  checkBoxFlag,
  checkBoxRowId,
  selectedFacilty
) => {
  const deleteJson = {};
  const slots = [];
  let row = null;
  if (checkBoxFlag === true) {
    row = checkBoxRowId;
  } else {
    row = uniqueId;
  }
  deleteJson["planningDate"] = activeDate;
  deleteJson["facilityId"] = selectedFacilty;

  const dock = inboundSlotsData[row];
  if (checkBoxFlag === true) {
    console.log("In check box");
    const allKeys = Object.keys(dock);
    for (let sl = 0; sl < allKeys.length; sl++) {
      if (
        allKeys[sl] !== "dock" &&
        allKeys[sl] !== "dockId" &&
        allKeys[sl] !== "break" &&
        allKeys[sl] !== "id"
      ) {
        if (dock[allKeys[sl]].status === status[2]) {
          const singleSlotData = {};
          const slotDocks = [];
          const dockData = {};
          singleSlotData["fromTime"] =
            activeDate + " " + dock[allKeys[sl]].from;
          singleSlotData["toTime"] = activeDate + " " + dock[allKeys[sl]].to;
          dockData["dockId"] = dock.dockId;
          dockData["dockStatus"] = replanningStatus[0];
          slotDocks.push(dockData);
          singleSlotData["slotDocks"] = slotDocks;
          slots.push(singleSlotData);
        }
      }
    }
  } else {
    for (let sl = 0; sl < selectedCellData.length; sl++) {
      const singleSlotData = {};
      const slotDocks = [];
      const dockData = {};
      singleSlotData["fromTime"] =
        activeDate + " " + dock[selectedCellData[sl]].from;
      singleSlotData["toTime"] =
        activeDate + " " + dock[selectedCellData[sl]].to;
      dockData["dockId"] = dock.dockId;
      dockData["dockStatus"] = replanningStatus[0];
      slotDocks.push(dockData);
      singleSlotData["slotDocks"] = slotDocks;
      slots.push(singleSlotData);
      // console.log("Slot", dock[selectedCellData[sl]]);
    }
  }

  deleteJson["slots"] = slots;
  // console.log("Dock", dock);
  return deleteJson;
};
