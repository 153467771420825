import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Styles/index.scss";
import { Loading } from "@postidigital/posti-components";
// Redux
import { Provider } from "react-redux";
import store from "./Store/store";
// Theme setting
import { PostiTheme, XyzTheme } from "@postidigital/posti-theme";
import { ThemeProvider } from "styled-components";
import "./i18n";
import { Suspense } from "react";
const combinedTheme = { ...PostiTheme, xyz: XyzTheme };

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={combinedTheme}>
        <Suspense
          fallback={
            <>
              <div className="loader_page">
                <Loading
                  preset="brandPetrol"
                  size="xl"
                  statusText="Loading..."
                />
              </div>
            </>
          }
        >
          <App />
        </Suspense>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
