import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import AddMDCarrier from "../masterDataCarrier/AddMDCarrier";
import { _getMDCarrierList, _removeUser } from "../../../Services/masterDataServices";
import EditMDCarriers from "../masterDataCarrier/EditMDCarriers";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";
import { isAdmin } from "../../../CommomFiles/globalFunctions";
import {
  Button
} from "@postidigital/posti-components";
import { Dialog } from "primereact/dialog";
import { showToast } from "../../../CommomFiles/globalFunctions";
import { hideLoader, showLoader } from '../../../Store/Actions/commonAction';

const status = ["Active", "Inactive"];

const MDCarrier = () => {
  const [bookings, setBookings] = useState(null);
  const [addCarrierDialog, setAddCarrierDialog] = useState(false);
  const [editProductsDialog, setEditProductsDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [product, setProduct] = useState();
  const [selectedCarriers, setSelectedCarriers] = useState(null);
  const cm = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);
  const [cancelDelete, setcancelDelete] = useState(false);

  const menuModel = [
    {
      label: t("EDIT_CARRIER"),
      icon: "pi pi-fw pi-user-edit",
      command: () => confirmEditSelected(selectedProduct),
    },
  ];

  const isAdmin = () => {
    return (
      userDetail &&
      Object.keys(userDetail).length > 0 &&
      userDetail?.groups?.includes("SMS_Admin")
    );
  };

  useEffect(() => {
    getCarrierDetails();
  }, []);

  const getCarrierDetails = () => {
    dispatch(
      _getMDCarrierList(
        { orgId: "o1" },
        (res) => {
          setBookings(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const confirmEditSelected = (product) => {
    setProduct(product);
    setEditProductsDialog(true);
  };

  const addBookingList = (data) => {
    const tempArray = [...bookings];
    tempArray.splice(0, 0, data);
    setBookings(tempArray);
  };

  const updateBookingList = (data) => {
    const index = bookings.findIndex(
      (item) => item.carrierId === data.carrierId
    );
    const tempArray = [...bookings];
    tempArray.splice(index, 1, data);
    setBookings(tempArray);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.status === "ACTIVE") {
      rowData.status = "Active";
    } else if (rowData.status === "INACTIVE") {
      rowData.status = "Inactive";
    }
    const statusClassName = classNames({
      in_active: rowData.status === "Inactive",
      active: rowData.status === "Active",
    });
    return <div className={statusClassName}>{rowData.status}</div>;
  };

  const onEditCarrierHide = () => {
    setEditProductsDialog(false);
    getCarrierDetails();
  }

  const removeCarrier=()=>{
    setcancelDelete(true);
  }

  const confirmDelete = () => {
    const selectedCarrierIds = [];
    selectedCarriers.map(carrier=>{
      selectedCarrierIds.push(carrier.carrierId)
    })
    const params = { cognitoId:selectedCarrierIds,userType:"carrier", orgId: "o1"};
    dispatch(
       _removeUser(
        params,
        (res) => {
          dispatch(showLoader());
          setSelectedCarriers(null) ;
          selectedCarriers.length = 0;
          setBookings(res.data.carrier);
          showToast(t("CARRIER_DELETE_SUCCESS"), "success");
          dispatch(hideLoader());
        },
        (err) => {
          console.log("Carrier delete error", err);
          // showToast(t("CARRIER_DELETE_FAILURE"), "error");
        }
      )
     );
     setcancelDelete(false);
  };

  const closeAddCarrierPopup =()=>{
    setAddCarrierDialog(false);
    getCarrierDetails();
  }

  return (
    <div>
      <ContextMenu
        model={menuModel}
        ref={cm}
        onHide={() => setSelectedProduct(null)}
      />
      <div className="box">
        <div>
          {
            isAdmin() && (selectedCarriers !== null && selectedCarriers.length > 0) ?
              <Button
                className="manageManagersButton"
                iconPosition="right"
                type="submit"
                size="sm"
                style={{ margin: "12px" }}
              onClick={removeCarrier}
              > {t("REMOVE_CARRIER")}</Button> : null
          }

        </div>
        <div>
          <DataTable
            value={bookings}
            stripedRows
            paginator={true}
            rows={5}
            contextMenuSelection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onContextMenu={(e) => isAdmin(userDetail) ? cm.current.show(e.originalEvent) : null}
            rowsPerPageOptions={[5, 10, 20]}
            rowHover
            responsiveLayout="scroll"
            selection={selectedCarriers}
            onSelectionChange={e => setSelectedCarriers(e.value)}
          >
            {
              isAdmin() ? <Column selectionMode="multiple" className="datatableHeaderWidth50" frozen={true} style={{ height: '35.5px', textAlign: 'center' }} />
                : null
            }

            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CARRIER_CODE")}
              field="carrierCode"
              header={t("CARRIER_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CARRIER_NAME")}
              field="carrierName"
              header={t("CARRIER_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STATUS")}
              field="status"
              body={statusBodyTemplate}
              filterElement={statusRowFilterTemplate}
              header={t("STATUS")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NAME")}
              field="streetName"
              header={t("STREET_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("STREET_NUMBER")}
              field="streetNo"
              header={t("STREET_NUMBER")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("POST_CODE")}
              field="postCode"
              header={t("POST_CODE")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("CITY")}
              field="city"
              header={t("CITY")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_NAME")}
              field="spocName"
              header={t("SPOC_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_EMAIL")}
              field="spocEmail"
              header={t("SPOC_EMAIL")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("SPOC_PHONE_NUMBER")}
              field="spocPhNo"
              header={t("SPOC_PHONE_NUMBER")}
            ></Column>
          </DataTable>
        </div>
        <MasterDataAddButton
          title={"ADD_NEW_CARRIER"}
          onClick={(e) => setAddCarrierDialog(true)}
        />

      </div>
      {editProductsDialog && (
        <EditMDCarriers
          close={() => onEditCarrierHide()}
          booking={product}
          updateList={(data) => updateBookingList(data)}
        />
      )}
      {addCarrierDialog && (
        <AddMDCarrier
          close={() => closeAddCarrierPopup()}
          updateList={(data) => addBookingList(data)}
        />
      )}
      <Dialog
          visible={cancelDelete}
          style={{ width: "450px", height: "215px" }}
          header={t("CONFIRM")}
          onHide={() => setcancelDelete(false)}
        >
          <div>
            <span style={{ color: "red", fontSize: "18px" }}>
              {t("DELETE_CARRIER")}
            </span>
            <div className="delayAndCancel">
              <div>
                <Button
                  onClick={() => confirmDelete()}
                  style={{
                    color: "#fff",
                    fontSize: "18px"
                  }}
                >
                  {t("YES")}
                </Button>
                <Button
                  onClick={() => setcancelDelete(false)}
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    marginLeft: "120px",
                    marginTop: "-62px"
                  }}
                >
                  {t("NO")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
    </div>
  );
};

export default MDCarrier;
