import React, { useState, useEffect } from "react";
import {
  Dialog,
  Body,
  Input,
  Dropdown,
  DropdownMenuItem,
} from "@postidigital/posti-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../CommomFiles/globalFunctions";
import { addMDDriverFormSchema } from "../../../CommomFiles/FormsValidationSchema";
import {
  _getMDCarrierList,
  _modifyDriverData,
  _getUserGroups
} from "../../../Services/masterDataServices";
import MasterDataAddButton from "../../../components/MasterData/MasterDataAddButton";
import { hideLoader, showLoader } from '../../../Store/Actions/commonAction';

const Status = ["Active", "Inactive"];

const EditMDDriver = ({ close, booking, updateList }) => {
  const [isStatusDropdownOpen, setStatusDropdown] = useState(false);
  const [isCarrierDropdownOpen, setCarrierDropdown] = useState(false);
  const [errors, setErrors] = useState(null);
  const [product, setProduct] = useState({ ...booking });
  const [isGroupDropdownOpen, setGroupDropdown] = useState(false);
  const [userGroupOptions, setUserGroupOptions] = useState([""]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [carrierOptions, setCarrierOptions] = useState([]);

  useEffect(() => {
    getCarrierList();
    getUserGroup();
    if(product.groups === undefined){
      const _product = { ...product };
      _product[`${'groups'}`] = [];
      setProduct(_product);
    }
  }, []);

  const modifyBooking = () => {
    product["driverStatus"] = product?.status;
    delete product["status"];
    delete product["carrierName"];
    dispatch(
      _modifyDriverData(
        product,
        (res) => {
          const responseData = res.data;
          updateList(responseData[0]);
          showToast(t("EDITING_SUCCESSFUL"), "success");
          close();
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const getCarrierList = () => {
    dispatch(
      _getMDCarrierList(
        { orgId: "o1" },
        (res) => {
          setCarrierOptions(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const onInputChange = (val, key) => {
    const _product = { ...product };

    if (key === "carrierId") {
      _product["carrierId"] = val.carrierId;
      _product["carrierName"] = val.carrierName;
      setProduct(_product);
    }
    else if (key === "groups" && _product[`${key}`] !== undefined? !(_product[`${key}`].includes(val)):false) {
      _product[`${key}`].push(val);
    }
    else if (key === "groups" && _product[`${key}`] !== undefined? (_product[`${key}`].includes(val)):false) {
      const index = (_product[`${key}`].indexOf(val));
      if (index > -1) { // only splice array when item is found
        (_product[`${key}`]).splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    else {
      _product[`${key}`] = val;
    }

    setProduct(_product);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await addMDDriverFormSchema.isValid(product, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      modifyBooking();
    } else {
      // If form is not valid, check which fields are incorrect:
      addMDDriverFormSchema
        .validate(product, { abortEarly: false })
        .catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(errors);
        });
    }
  };

  //get list of groups
  const getUserGroup = (driver) => {
    dispatch(showLoader());
    _getUserGroups(
      (res) => {
        if (res.data.length > 0 && res.data !== undefined) {
          res.data.forEach((i) => {
            setUserGroupOptions(userGroupOptions => userGroupOptions.concat(i.group_name));
          })
        }
        dispatch(hideLoader());
      },
      (err) => {
        dispatch(hideLoader());
      }
    )
  };

  return (
    <Dialog
      bottomButtons={
        <MasterDataAddButton title={"SAVE"} onClick={(e) => onSubmit(e)} />
      }
      closeText="Close dialog"
      disableOutsideClick={true}
      headerContent={t("EDIT_DRIVER")}
      onClose={() => {
        close();
      }}
      style={{ width: "600px" }}
    >
      <Body as="div" className="driver_input_container" size="Four">
        <Input
          type="text"
          label={t("DRIVER_ID")}
          value={product?.driverId}
          onChange={(e) => onInputChange(e.target.value, "driverId")}
          required
          readOnly
          className="driver_disable_input_control"
          message={errors && t(errors["driverId"])}
          isInvalid={errors && "driverId" in errors}
        />
        <div className="m_d_dropdown_container">
          <Dropdown
            isOpen={isCarrierDropdownOpen}
            title={product?.carrierName}
            iconColor="#73808C"
            label={t("CARRIER_NAME")}
            className="m_d_dropdown masterDataDropdown"
            menuAriaLabel={t("CARRIER_NAME")}
            onSelect={(e) => {
              onInputChange(e, "carrierId");
            }}
            onToggle={() =>
              setCarrierDropdown(
                (isCarrierDropdownOpen) => !isCarrierDropdownOpen
              )
            }
            overflowMenuContainerDirection="right"
          >
            {carrierOptions?.map((item, index) => {
              return (
                <DropdownMenuItem
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  className="m_d_dropdown_item"
                  selected={product?.carrierId === item.carrierId}
                  key={item.carrierId}
                >
                  {item.carrierName}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div>

        <Input
          type="text"
          label={t("DRIVER_NAME")}
          value={product?.driverName}
          onChange={(e) => onInputChange(e.target.value, "driverName")}
          required
          className="driver_input_control"
          message={errors && t(errors["driverName"])}
          isInvalid={errors && "driverName" in errors}
        />
        <Input
          type="text"
          label={t("LICENSE_NUMBER")}
          value={product?.drivingLicenseNo}
          onChange={(e) => onInputChange(e.target.value, "drivingLicenseNo")}
          required
          className="driver_input_control"
          message={errors && t(errors["drivingLicenseNo"])}
          isInvalid={errors && "drivingLicenseNo" in errors}
        />
        <Input
          type="text"
          label={t("EMAIL")}
          value={product?.driverEmail}
          onChange={(e) => onInputChange(e.target.value, "driverEmail")}
          required
          className="driver_input_control"
          message={errors && t(errors["driverEmail"])}
          isInvalid={errors && "driverEmail" in errors}
        />
        <Input
          type="text"
          label={t("PHONE_NO")}
          value={product?.driverPhNo}
          onChange={(e) => onInputChange(e.target.value, "driverPhNo")}
          required
          className="driver_input_control"
          message={errors && t(errors["driverPhNo"])}
          isInvalid={errors && "driverPhNo" in errors}
        />
        <div className="m_d_carrier_dropdown_container">
          <Dropdown
            isOpen={isStatusDropdownOpen}
            title={product?.status}
            iconColor="#73808C"
            label={t("STATUS")}
            menuAriaLabel="status"
            className="m_d_carrier_dropdown"
            onSelect={(e) => onInputChange(e, "status")}
            onToggle={() =>
              setStatusDropdown((isStatusDropdownOpen) => !isStatusDropdownOpen)
            }
            isValid={!(errors && "status" in errors)}
            message={errors && t(errors["status"])}
            overflowMenuContainerDirection="right"
          >
            {Status?.map((item, index) => {
              return (
                <DropdownMenuItem
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={product?.status === item}
                  key={item}
                  className="m_d_carrier_dropdown_item"
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div>
        <div className="m_d_carrier_dropdown_container drpGroup">
          <Dropdown
            isOpen={isGroupDropdownOpen}
            // title={product?.groups.toString().replace('SMS', " SMS")}
            title={product?.groups}
            iconColor="#73808C"
            label={t("GROUP")}
            menuAriaLabel="groups"
            className="m_d_carrier_dropdown"
            onSelect={(e) => onInputChange(e, "groups")}
            onToggle={() =>
              setGroupDropdown((isGroupDropdownOpen) => !isGroupDropdownOpen)
            }
            isValid={!(errors && "groups" in errors)}
            message={errors && t(errors["groups"])}
            overflowMenuContainerDirection="right"
            isMulti
          >
            {userGroupOptions?.map((item, index) => {
              return (
                <DropdownMenuItem
                  id={index}
                  allowWrap
                  aria-label="Menu item"
                  tabIndex={index}
                  value={item}
                  selected={product?.groups !== undefined ? product?.groups.includes(item) : null}
                  key={item}
                  className="m_d_carrier_dropdown_item"
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </Dropdown>
        </div>
      </Body>
    </Dialog>
  );
};

export default EditMDDriver;
