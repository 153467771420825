import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownMenuItem,
  Switch,
  Button,
} from "@postidigital/posti-components";
import { useTranslation } from "react-i18next";
import { DateInput } from "@postidigital/posti-components/build/datepicker";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { classNames } from "primereact/utils";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  getOneDayData,
  slotDelete,
  slotAvailable,
  replanningStatus,
} from "../../CommomFiles/slotReplanningFunction";
import {
  showToast,
  showWarningToast,
  isToday,
  formateDate,
} from "../../CommomFiles/globalFunctions";
import ReplanningPreBookSlotDetails from "../BookSlot/ReplanningPrebookSlotDetails";
import {
  _getReplanningSlotsData,
  _deleteSlots,
} from "../../Services/slotServices";
import { DATEFORMAT } from "../../config/constant";
import ShiftingSlots from "../BookSlot/ShiftingSlots";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { bookingTypeCode } from "../../components/BookSlot/ReplanningPrebookSlotDetails";
import {
  _getPlannedDatesByFacility
} from "../../Services/facilityServices";
import { hideLoader, showLoader } from '../../Store/Actions/commonAction';

const SlotReplanning = (props) => {
  const { facility } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isFacilityopen, setFacilityOpen] = useState(false);
  const [checkedFlag, setCheckedFlag] = useState(false);
  const [selectedFaciltyName, setSelectedFaciltyName] = useState(null);
  const [selectedFacilty, setSelectedFacility] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inboundSlotsData, setInboundSlotsData] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [showInboundSlots, setShowInboundSlots] = useState(true);
  const [showOutboundSlots, setShowOutboundSlots] = useState(false);
  const [inboundPreBookToggle, setInboundPreBookToggle] = useState(false);
  const [inboundDeleteToggle, setInboundDeleteToggle] = useState(false);
  const [selectedCellData, setSelectedCellData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [cellSelectCount, setCellSelectCount] = useState(0);
  const [cellSelectFlag, setCellSelectFlag] = useState(false);
  const [checkBoxFlag, setCheckBoxFlag] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const [unselectedRowData, setUnselectedRowData] = useState([]);
  const [preBookModal, setPreBookModal] = useState(false);
  const [checkBoxRowId, setCheckBoxRowId] = useState(null);
  const [unCheckedFlag, setUnCheckedFlag] = useState(false);
  const [selectedCheckBoxId, setSelectedCheckBoxId] = useState(null);
  const [clearSlotSelection, setClearSlotSelection] = useState(false);
  const [pastSlotsFlag, setPastSlotsFlag] = useState(false);
  const [shiftingSlot, setShiftingSlot] = useState(false);
  const [dataPlanned, setDataPlanned] = useState(true);
  const[enabledDates,setEnabledDates] = useState([]);
  const [selectedFromDate, setselectedFromDate] = useState(null);

  useEffect(() => {
    if (selectedFacilty === null || selectedDate === null) {
      setShowInboundSlots(false);
    }
  }, [activeDate, selectedFacilty, selectedDate]);

  // Function handling on cell select action
  const onCellSelect = (event) => {
    if (isToday(activeDate)) {
      const slotTime = moment(event.value, "HH:mm");
      const currentTime = moment(moment(), "HH:mm");
      const timeDifference = slotTime.diff(currentTime, "minutes");
      if (timeDifference < 0) {
        // showWarningToast(t("PAST_SLOTS_WARNING"));
        setPastSlotsFlag(true);
      }
    }

    // console.log("Difference", dateDifference);
    if (
      event.field.split(".")[0] === "id" ||
      event.field.split(".")[0] === "dock" ||
      event.field.split(".")[0] === "break"
    ) {
      // console.log("Event", event.field.split(".")[0])
    } else {
      const selectedSlotsArray = selectedCellData;
      const selectedRowsArray = selectedRowData;
      setCellSelectCount(cellSelectCount + 1);
      setCellSelectFlag(true);
      const slotName = event.field.split(".")[0];
      setCheckBoxFlag(false);
      if (event.rowData[slotName]["status"] === replanningStatus[1]) {
        setInboundPreBookToggle(true);
      } else {
        setInboundPreBookToggle(false);
      }
      if (event.rowData[slotName]["status"] === replanningStatus[2]) {
        setInboundDeleteToggle(true);
      } else {
        setInboundDeleteToggle(false);
      }
      // console.log(
      //   "Cell selected",
      //   event,
      //   event.field.split(".")[0],
      //   event.rowIndex,
      //   event.rowData[slotName]["status"]
      // );

      setUniqueId(event.rowIndex);
      const selectedSlotName = event.field.split(".")[0];
      selectedSlotsArray.push(selectedSlotName);
      setSelectedCellData(selectedSlotsArray);

      const selectedRowIndex = event.rowIndex;
      selectedRowsArray.push(selectedRowIndex);
      setSelectedRowData(selectedRowsArray);

      // console.log("selected row data", selectedRowData);
      // console.log("selected cell data", selectedCellData);
    }
  };

  // Function handling on cell unselect action
  const onCellUnselect = (event) => {
    if (
      event.field.substring(0, 5) === "dock" ||
      event.field.substring(0, 5) === "id.fr" ||
      event.field.substring(0, 5) === "id" ||
      event.field.substring(0, 5) === "break"
    ) {
      // console.log("Event", event.field.substring(0, 5));
    } else {
      const unselectedRow = event.rowIndex;
      const unselectedSlot = event.field.substring(0, 5);
      let unselectSameCellCount = 0;
      // console.log("Unselect", event.rowIndex);
      // console.log("Unselect cell", event.field.substring(0, 5));

      unselectedRowData.push(event.field.substring(0, 5));
      // console.log("Unselect cell rowdata", unselectedRowData);

      for (let j = 0; j < selectedRowData.length; j++) {
        if (selectedRowData[j] === unselectedRow) {
          selectedRowData.splice(j, 1);
          setUniqueId(selectedRowData[j - 1]);
        }

        for (let i = 0; i < selectedCellData.length; i++) {
          if (selectedCellData[i] === unselectedSlot) {
            unselectSameCellCount++;
            if (unselectSameCellCount < 1) {
              // console.log("Count", unselectSameCellCount);
              selectedCellData.splice(i, 1);
            }
          }
        }
      }

      let removingSlotCount = 0;
      for (let i = 0; i < selectedCellData.length; i++) {
        if (selectedCellData[i] === unselectedSlot) {
          if (removingSlotCount < 1) {
            selectedCellData.splice(i, 1);
            removingSlotCount++;
          }
        }
      }

      // console.log("selected cell data updated", selectedCellData);
      // console.log("selected row data updated", selectedRowData);
      // console.log("On unselect unique id", uniqueId);
      setUniqueId(selectedRowData[0]);
      // setUniqueId(uniqueId);
    }
  };

  // Plotting checkbox cells
  const checkBoxBody = (rowData) => {
    if (rowData.id === parseInt(checkBoxRowId)) {
      return (
        <div className="checkBoxContainer">
          <input
            className="checkBox"
            id={rowData.id !== 0 ? "checkBoxId" : "hideCheckbox"}
            type="checkbox"
            value={rowData.id}
            checked={checkedFlag}
            onChange={(e) => {
              // console.log("I am first");
              setCheckedFlag(!checkedFlag);
              if (checkedFlag === false) {
                setSelectedCheckBoxId(null);
              }
              if (e.target.checked === false) {
                setUnCheckedFlag(true);
              } else {
                setUnCheckedFlag(false);
              }

              // setUnCheckedFlag(true);
              setSelectedCheckBoxId(e.target.value);
              setCheckBoxRowId(e.target.value);
              setCheckBoxFlag(true);
              // setPreBookToggle(false);
              // setDeleteToggle(false);
              setCheckBoxRowId(e.target.value);
            }}
          ></input>
        </div>
      );
    }
    return (
      <div className="checkBoxContainer">
        <input
          className="checkBox"
          id={rowData.id !== 0 ? "checkBoxId" : "hideCheckbox"}
          type="checkbox"
          value={rowData.id}
          // checked={false}
          onChange={(e) => {
            // console.log("Check box event", e);
            // console.log("I am second", e.target.value, checkBoxRowId - 1);
            // console.log("checkBox event", e.target.value);
            setCheckedFlag(!checkedFlag);
            if (checkedFlag === false) {
              setSelectedCheckBoxId(null);
            }
            if (e.target.checked === false) {
              setUnCheckedFlag(true);
            } else {
              setUnCheckedFlag(false);
            }

            const tempObject = [...inboundSlotsData];

            // console.log("Object", tempObject[e.target.value]);
            // console.log("checked flag", e.target.checked);
            let checkBoxAllowed = true;
            let anyDeleted = false;
            let anyAlloted = false;
            if (tempObject[e.target.value] !== undefined) {
              for (
                let i = 0;
                i < Object.keys(tempObject[e.target.value]).length;
                i++
              ) {
                if (
                  Object.keys(tempObject[e.target.value])[i] !== "id" &&
                  Object.keys(tempObject[e.target.value])[i] !== "dock" &&
                  Object.keys(tempObject[e.target.value])[i] !== "break" &&
                  Object.keys(tempObject[e.target.value])[i] !== "dockId"
                ) {
                  // console.log("Slottime", slotTime);
                  if (isToday(activeDate)) {
                    const slotTime = moment(
                      tempObject[e.target.value][
                        Object.keys(tempObject[e.target.value])[i]
                      ].from,
                      "HH:mm"
                    );
                    const currentTime = moment(moment(), "HH:mm");
                    const timeDifference = slotTime.diff(
                      currentTime,
                      "minutes"
                    );
                    if (timeDifference < 0) {
                      // showWarningToast(t("PAST_SLOTS_WARNING"));
                      setPastSlotsFlag(true);
                    }
                  }

                  if (
                    tempObject[e.target.value][
                      Object.keys(tempObject[e.target.value])[i]
                    ].status === replanningStatus[1]
                  ) {
                    // console.log(
                    //   "Latest status-->",
                    //   tempObject[e.target.value][
                    //     Object.keys(tempObject[e.target.value])[i]
                    //   ].status
                    // );
                    checkBoxAllowed = false;
                    anyAlloted = true;
                    // setInboundPreBookToggle(true);
                  } else if (
                    tempObject[e.target.value][
                      Object.keys(tempObject[e.target.value])[i]
                    ].status === replanningStatus[2]
                  ) {
                    setInboundDeleteToggle(true);
                    checkBoxAllowed = true;
                    anyDeleted = true;
                  } else if (
                    tempObject[e.target.value][
                      Object.keys(tempObject[e.target.value])[i]
                    ].status === replanningStatus[0]
                  ) {
                    checkBoxAllowed = true;
                    setInboundPreBookToggle(false);
                    setInboundDeleteToggle(false);
                  }
                }
              }
              if (checkBoxAllowed === false) {
                if (e.target.checked === true) {
                  e.target.checked = false;
                  showWarningToast(t("CHECKBOX_WARNING"));
                }
                clearingSelection();
              } else {
                if (anyDeleted === true) {
                  setInboundDeleteToggle(true);
                  setInboundPreBookToggle(false);
                } else if (anyAlloted === true) {
                  if (e.target.checked === true) {
                    e.target.checked = false;
                    showWarningToast(t("CHECKBOX_WARNING"));
                    setInboundPreBookToggle(false);
                    setInboundDeleteToggle(false);
                    clearingSelection();
                  }
                  clearingSelection();
                }
              }
            }

            setSelectedCheckBoxId(e.target.value);
            setCheckBoxRowId(e.target.value);
            setCheckBoxFlag(true);
            // setPreBookToggle(false);
            // setDeleteToggle(false);
            setCheckBoxRowId(e.target.value);
            setCheckBoxFlag(true);
          }}
        ></input>
      </div>
    );
  };

  // plotting dock cells
  const dockBody = (rowData) => {
    const stockClassName = classNames({
      dockRow: rowData.dock,
    });

    return <div className={stockClassName}>{rowData.dock}</div>;
  };

  // Plotting slot cells
  const slotBody = (rowData, key) => {
    const value = rowData[key];
    let slotClassName;
    if (value.status === replanningStatus[1]) {
      //Container booking
      if (value?.preBookingDetails?.bookingType === bookingTypeCode[0]) {
        slotClassName = classNames({
          containerSlot: rowData,
        });
        //Special/Truck booking
      } else {
        slotClassName = classNames({
          specialBookingSlot: rowData,
        });
      }
    } else if (value.status === replanningStatus[0]) {
      slotClassName = classNames({
        availableRow: rowData,
      });
    } else {
      slotClassName = classNames({
        deletedRow: rowData,
      });
    }

    const poRef =
      t("TOOLTIP_BOOKING_ID") +
      rowData[key].preBookingDetails?.bookingId.toString() +
      " , ";
    const customerName =
      " " +
      t("TOOLTIP_CUSTOMER_NAME") +
      rowData[key].preBookingDetails?.customerDetails?.customerName.toString();

    const allData = poRef + customerName;
    const tooltipData =
      rowData[key].status === replanningStatus[1]
        ? allData
        : rowData[key].status;

    if (rowData.id === 0) {
      return <div className="availableSlotInfo">{value.from}</div>;
    } else {
      return (
        <Tooltip title={tooltipData} placement="top">
          <div className={slotClassName}>{value.from}</div>
        </Tooltip>
      );
    }
  };

  // dynamic column function
  const dynamicColumns =
    inboundSlotsData &&
    inboundSlotsData.length > 0 &&
    Object.entries(inboundSlotsData[0]).map(([key, value]) => {
      if (key === "id") {
        return (
          <Column
            // selectionMode="multiple"
            className="checkBoxColumn"
            key={`${key}.from`}
            field={`${key}.from`}
            header=""
            onRowClick={(e) => {}}
            // selection={false}
            body={(e) => checkBoxBody(e)}
          ></Column>
        );
      }
      if (key !== "id") {
        if (key === "dock") {
          return (
            <Column
              // selectionMode="multiple"
              onselectionchange={(e) => {}}
              // headerStyle={{ width: "3em" }}
              className="dockColumns"
              key={key}
              field={key}
              header=""
              body={dockBody}
            />
          );
        } else if (key === "break") {
          // console.log("Break",  key)
        } else if (key === "dockId") {
          // console.log("dockId", key)
        } else {
          return (
            <Column
              className="tableColumns2"
              key={`${key}.from`}
              field={`${key}.from`}
              header=""
              // tabIndex="0"
              body={(e) => slotBody(e, key)}
            />
          );
        }
      }
    });

  // Getting data for plotting data table
  const getData = (data) => {
    const dataFromBackend = getOneDayData(data);
    // console.log("Data table data :", dataFromBackend);
    setInboundSlotsData(dataFromBackend);
    // setActiveDate()
  };

  // Function for handling prebook before opening pre-book detail form
  const handleReplanningPrebookCellBeforeForm = () => {
    if (pastSlotsFlag === true) {
      showWarningToast(t("PAST_SLOTS_WARNING"));
      clearingSelection();
    } else {
      if (inboundPreBookToggle === true) {
        // console.log("Prebook")
      } else {
        if (inboundDeleteToggle === true) {
          showWarningToast(t("WARNING_PREBOOKING_DELETED_SLOTS"));
          setSelectedCellData([]);
          setSelectedRowData([]);
          setUnselectedRowData([]);
          setCellSelectCount(0);
          setCheckBoxRowId(null);
        } else {
          let multipleRowFlag = false;
          let nonAdjacentSlotFlag = false;

          // console.log("rows data selected======>", selectedRowData);

          for (let k = 0; k < selectedRowData.length; k++) {
            if (k >= 1) {
              if (
                parseInt(selectedRowData[k]) -
                  parseInt(selectedRowData[k - 1]) !==
                0
              ) {
                multipleRowFlag = true;
              }
            }
          }

          let tempCellSelectedArray = [];
          for (let i = 0; i < selectedCellData.length; i++) {
            // console.log(selectedCellData[i].substring(5, 4));
            tempCellSelectedArray.push(
              parseInt(selectedCellData[i].substring(5, 4))
            );
          }
          tempCellSelectedArray = tempCellSelectedArray.sort();

          for (let j = 0; j < tempCellSelectedArray.length; j++) {
            if (j >= 1) {
              if (
                tempCellSelectedArray[j] - tempCellSelectedArray[j - 1] !==
                1
              ) {
                nonAdjacentSlotFlag = true;
              }
            }
          }

          if (multipleRowFlag === true) {
            showWarningToast(t("SAME_DOCK_WARNING"));
          } else if (nonAdjacentSlotFlag === true) {
            showWarningToast(t("ADJACENT_SLOT_WARNING"));
          } else {
            // console.log("sorted", tempCellSelectedArray);
            if (selectedCellData.length < 1 && checkBoxFlag === false) {
              showWarningToast(t("SELECT_SLOT_WARNING"));
              // showWarningToast(t("SELECT_AT_LEAST_TWO_SLOT_WARNING"));
              // setPreBookModal(true);
            } else {
              if (cellSelectFlag === false && checkBoxFlag === false) {
                showWarningToast(t("SELECT_SLOT_WARNING"));
              } else if (inboundPreBookToggle === true) {
                if (checkedFlag === true) {
                  // handleCheckedAvailableCell();
                  clearingSelection();
                } else {
                  if (unselectedRowData.length > 1) {
                    // handleMultipleAvailableCell();
                    clearingSelection();
                  } else {
                    // handleAvailableCell();
                    clearingSelection();
                  }
                }
              } else {
                setPreBookModal(true);
              }
              // console.log("Cell count", cellSelectCount);
            }
          }
        }
      }
    }
  };

  // Function for highlighting selected row
  const rowclass = (data) => {
    return {
      checkedRow: data.id === parseInt(selectedCheckBoxId),
    };
  };
  // Function for prebooking slot
  const handleReplanningPrebookCell = () => {
    clearingSelection();
    getReplanningData(activeDate);
    showToast(t("SLOT_PREBOOKED"));
    setPreBookModal(false);
    setInboundPreBookToggle(true);
  };

  // Function for deleting slot
  const handleReplanningDeleteCell = () => {
    if (selectedCellData.length < 1 && checkBoxFlag === false) {
      showWarningToast(t("SELECT_SLOT_WARNING"));
    } else {
      if (inboundPreBookToggle === true) {
        showWarningToast(t("WARNING_DELETING_PREBOOKED_SLOTS"));
        setSelectedCellData([]);
        setSelectedRowData([]);
        setUnselectedRowData([]);
        setCheckBoxRowId(null);
        setCellSelectCount(0);
      } else {
        let multipleRowFlag = false;
        let nonAdjacentSlotFlag = false;

        // console.log("rows data selected======>", selectedRowData);

        for (let k = 0; k < selectedRowData.length; k++) {
          if (k >= 1) {
            if (
              parseInt(selectedRowData[k]) -
                parseInt(selectedRowData[k - 1]) !==
              0
            ) {
              multipleRowFlag = true;
            }
          }
        }

        let tempCellSelectedArray = [];
        for (let i = 0; i < selectedCellData.length; i++) {
          // console.log(selectedCellData[i].substring(5, 4));
          tempCellSelectedArray.push(
            parseInt(selectedCellData[i].substring(5, 4))
          );
        }
        tempCellSelectedArray = tempCellSelectedArray.sort();

        for (let j = 0; j < tempCellSelectedArray.length; j++) {
          if (j >= 1) {
            if (tempCellSelectedArray[j] - tempCellSelectedArray[j - 1] !== 1) {
              nonAdjacentSlotFlag = true;
            }
          }
        }

        if (multipleRowFlag === true) {
          showWarningToast(t("SAME_DOCK_WARNING"));
        } else if (nonAdjacentSlotFlag === true) {
          showWarningToast(t("ADJACENT_SLOT_WARNING"));
        } else {
          if (pastSlotsFlag === true) {
            showWarningToast(t("PAST_SLOTS_WARNING"));
            clearingSelection();
          } else {
            if (inboundDeleteToggle !== true && inboundPreBookToggle !== true) {
              const deleteJson = slotDelete(
                activeDate,
                uniqueId,
                selectedCellData,
                inboundSlotsData,
                checkBoxFlag,
                checkBoxRowId,
                selectedFacilty
              );
              // console.log("Delete Json=", deleteJson);

              dispatch(
                _deleteSlots(
                  deleteJson,
                  (res) => {
                    // console.log("Response of post planning api -->", res);
                    getReplanningData(activeDate);
                    showToast(t("SLOT_DELETED"));
                    setInboundDeleteToggle(true);
                    clearingSelection();
                  },
                  (err) => {
                    console.log(err);
                    clearingSelection();
                  }
                )
              );
              //Making deleted slots available
            } else if (inboundDeleteToggle === true) {
              const availableJson = slotAvailable(
                activeDate,
                uniqueId,
                selectedCellData,
                inboundSlotsData,
                checkBoxFlag,
                checkBoxRowId,
                selectedFacilty
              );
              // console.log("Delete Json=", deleteJson);

              dispatch(
                _deleteSlots(
                  availableJson,
                  (res) => {
                    // console.log("Response of post planning api -->", res);
                    getReplanningData(activeDate);
                    showToast(t("SLOT_AVAILABLE"));
                    setInboundDeleteToggle(false);
                    clearingSelection();
                  },
                  (err) => {
                    console.log(err);
                    clearingSelection();
                  }
                )
              );
            }
          }
        }
      }
    }
  };

  // Function for clearing selected data
  const clearingSelection = () => {
    setUniqueId(null);
    setSelectedCellData([]);
    setCheckBoxRowId(null);
    setCheckBoxFlag(false);
    setSelectedRowData([]);
    setUnselectedRowData([]);
    setUnCheckedFlag(true);
    setCellSelectCount(0);
    setCheckedFlag(false);
    setClearSlotSelection(true);
    setPastSlotsFlag(false);
  };

  // Function for calling replanning slots data API
  const getReplanningData = () => {
    const dateInput = moment(selectedDate).format(DATEFORMAT);
    // console.log("Facility ", selectedFacilty);
    // console.log("Date ", moment(selectedDate).format(DATEFORMAT));
    dispatch(
      _getReplanningSlotsData(
        { facilityId: selectedFacilty, slotsDate: dateInput },
        (res) => {
          getData(res.data);
          setActiveDate(res.data.plannedDate);
          setPreBookModal(false);
          setShowInboundSlots(true);
          if (res.data.slots.length <= 0) {
            setDataPlanned(false);
          } else {
            setDataPlanned(true);
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  // Function for handling replanning start button
  const handleStartReplanning = () => {
    if (selectedFacilty === null || selectedDate === null) {
      showWarningToast(t("SELECT_FIELDS_WARNING"));
    } else {
      clearingSelection();
      getReplanningData();
      setInboundPreBookToggle(false);
      setInboundDeleteToggle(false);
    }
  };

  // Function for showing limited dates in date input
 
  const checkOutSideDate = (day) => {
    const today = selectedFromDate !== null ? moment(selectedFromDate) : moment();
    console.log("enabledDates=>",enabledDates)
    if(enabledDates.length !== 0){
    return (
      moment(formateDate(day)).isBefore(formateDate(today), "day") 
      ||  !(enabledDates.includes(new Date(day).getFullYear() + '-' + (convertDateFormat(new Date(day).getMonth() + 1)) + '-' + convertDateFormat(new Date(day).getDate())))
  
    );
      }
      else{
        return (
          moment(formateDate(day)).isSame(formateDate(today), "day") ||
          moment(formateDate(day)).isBefore(formateDate(today), "day") ||
          moment(formateDate(day)).isAfter(formateDate(today), "day")
        );
      }
  
  };

  // Function for handling shifting slots functionality
  const handleShiftingSlots = (e) => {
    if (selectedCellData.length < 1 && checkBoxFlag === false) {
      showWarningToast(t("SELECT_SLOT_WARNING"));
    } else {
      if (inboundPreBookToggle === true) {
        showWarningToast(t("WARNING_SHIFTING_PREBOOKED_SLOTS"));
        setSelectedCellData([]);
        setSelectedRowData([]);
        setUnselectedRowData([]);
        setCheckBoxRowId(null);
        setCellSelectCount(0);
      } else {
        let multipleRowFlag = false;
        let nonAdjacentSlotFlag = false;

        // console.log("rows data selected======>", selectedRowData);

        for (let k = 0; k < selectedRowData.length; k++) {
          if (k >= 1) {
            if (
              parseInt(selectedRowData[k]) -
                parseInt(selectedRowData[k - 1]) !==
              0
            ) {
              multipleRowFlag = true;
            }
          }
        }

        let tempCellSelectedArray = [];
        for (let i = 0; i < selectedCellData.length; i++) {
          // console.log(selectedCellData[i].substring(5, 4));
          tempCellSelectedArray.push(
            parseInt(selectedCellData[i].substring(5, 4))
          );
        }
        tempCellSelectedArray = tempCellSelectedArray.sort();

        for (let j = 0; j < tempCellSelectedArray.length; j++) {
          if (j >= 1) {
            if (tempCellSelectedArray[j] - tempCellSelectedArray[j - 1] !== 1) {
              nonAdjacentSlotFlag = true;
            }
          }
        }

        if (multipleRowFlag === true) {
          showWarningToast(t("SAME_DOCK_WARNING"));
        } else if (nonAdjacentSlotFlag === true) {
          showWarningToast(t("ADJACENT_SLOT_WARNING"));
        } else {
          if (pastSlotsFlag === true) {
            showWarningToast(t("PAST_SLOTS_WARNING"));
            clearingSelection();
          } else {
            if (inboundDeleteToggle !== true && inboundPreBookToggle !== true) {
              setShiftingSlot(true);
            }
          }
        }
      }
    }
  };

  const handleShistSlots = () => {
    clearingSelection();
    getReplanningData(activeDate);
    showToast(t("SLOT_SHIFTED"));
  };

  const getAlreadyPlannedDates =(facilityId)=>{
    dispatch(showLoader());
    if (facilityId) {
      _getPlannedDatesByFacility(
        { facilityId: facilityId},
        (res) => {
          console.log("Replanning can be done for these days=>",res.data)
          if (res.data.length > 0) {
            res.data.map(dateItem =>{
              getAlreadyAllocatedDates(dateItem.planStartDate, dateItem.planEndDate);
            })
            dispatch(hideLoader());
          }
          else{
            dispatch(hideLoader());
          }
        },
        (err) => {
          console.log(err);
          dispatch(hideLoader());
        }
      );
    }
    
  };  

  const getAlreadyAllocatedDates = (startDate, stopDate)=> {
    var currentDate = new Date(startDate);
    while (currentDate <= new Date(stopDate)) {
       var tempDate= "";
       tempDate = currentDate.getFullYear() + '-' + (convertDateFormat(currentDate.getMonth() + 1)) + '-' + convertDateFormat(currentDate.getDate());
        setEnabledDates(enabledDates => enabledDates.concat(tempDate));
        currentDate.setDate(currentDate.getDate() + 1);
        tempDate ="";
    }
  }

  //function to add '0' in single digit month (ex:01,02)
const convertDateFormat = (data) => {
  let newData = 0
  if (data < 10) {
    newData = '0' + data;
    return newData
  }
  else {
    return data
  }
}

  return (
    <div className="replanningSection">
      <div className="replanningUpperSection">
        <div className="inputDevision">
          <div className="facilityDivision">
            <Dropdown
              isOpen={isFacilityopen}
              title={selectedFaciltyName}
              iconColor="#73808C"
              label={t("SLOT_PLANNING_SELECT_FACILITY")}
              menuAriaLabel="Select facility"
              message=""
              onSelect={(e) => {
                // console.log("facility selected", e);
                setSelectedFacility(e.facilityId);
                setSelectedFaciltyName(e.siteName);
                setSelectedDate(null)
                setEnabledDates([]);
                getAlreadyPlannedDates(e.facilityId)
              }}
              onToggle={() =>
                setFacilityOpen((isFacilityopen) => !isFacilityopen)
              }
              overflowMenuContainerDirection="right"
              className="facilityDivisionDropdown"
            >
              {facility?.map((item, index) => {
                return (
                  <DropdownMenuItem
                    allowWrap
                    aria-label="Menu item"
                    tabIndex={index}
                    value={item}
                    selected={selectedFacilty === item.facilityId}
                    key={item.facilityId}
                  >
                    {item.siteName}
                  </DropdownMenuItem>
                );
              })}
            </Dropdown>
          </div>
          <div className="datePickerDivision">
            <DateInput
              // className="datePicker"
              // className="b_s_date_input"
              className="replanningDateInput"
              firstDayOfWeek={0}
              displayFormat={DATEFORMAT}
              //   message={errors && t(errors["originETD"])}
              //   isInvalid={errors && "originETD" in errors}
              id="bookigngDateInput"
              label={t("DATE_PICKER")}
              locale="en"
              nextMonthButtonAriaLabel="Move to next month"
              prevMonthButtonAriaLabel="Move to previous month"
              onDateChange={(date) => {
                // console.log("Date", date);
                setSelectedDate(date);
              }}
              date={selectedDate ? moment(selectedDate) : ""}
              isOutsideRange={checkOutSideDate}
              onTouchCancel={false}
              disabled={selectedFacilty === null}
            />
          </div>
          {/* <div className="startButtonDiv"> */}
          <Button
            className="startButton"
            iconPosition="right"
            size="sm"
            onClick={() => {
              handleStartReplanning();
            }}
          >
            {t("SLOT_PLANNING_START_PLANNING_TITLE")}
            {/* <a className="arrow">&#8594;</a> */}
            <ArrowForwardRoundedIcon
              style={{ marginLeft: "10px", fontSize: "20px" }}
            />
          </Button>
          {/* </div> */}
        </div>
        <div className="legend">
          <div className="containerBooking"> </div>
          <div className="containerBookingText">
            {t("LEGEND_CONTAINER_BOOKING")}{" "}
          </div>
          <div className="specialBooking"> </div>
          <div className="specialBookingText">
            {t("LEGEND_SPECIAL_BOOKING")}{" "}
          </div>
          <div className="deletedSlots"> </div>
          <div className="deletedSlotsText">{t("LEGEND_DELETED_SLOTS")} </div>
        </div>
      </div>

      <div className="replanningLowerSection">
        <div className="inboundSlots">
          <div className="inboundSlotTitleAndButton">
            <div className="inboundSlotTitle">{t("INBOUND_SLOTS")}</div>
            {/* <div className="collapseDiv"> */}
            <button
              className="collapse"
              onClick={() => {
                if (selectedFacilty === null || selectedDate === null) {
                  showWarningToast(t("SELECT_INPUT_WARNING"));
                } else {
                  setShowInboundSlots(!showInboundSlots);
                }
              }}
            >
              {showInboundSlots ? (
                <>
                  {t("COLLAPSE")}
                  <ExpandLessIcon />
                </>
              ) : (
                <>
                  {t("EXPAND")}
                  <ExpandMoreIcon />
                </>
              )}
            </button>
            {/* </div> */}
          </div>
          {showInboundSlots ? (
            <div className="slotReplanningTableAndSwitches">
              {dataPlanned ? (
                <>
                  <DataTable
                    className="inboundSlotsDatatable"
                    value={inboundSlotsData}
                    selectionMode="multiple"
                    cellSelection
                    metaKeySelection={false}
                    selection={clearSlotSelection ? [] : selectedProducts}
                    rowClassName={unCheckedFlag === true ? null : rowclass}
                    // rowHover={false}
                    onSelectionChange={(e) => {
                      setClearSlotSelection(false);
                      setSelectedProducts(e.value);
                    }}
                    onCellSelect={onCellSelect}
                    onCellUnselect={onCellUnselect}
                    dataKey="id"
                    responsiveLayout="scroll"
                    onRowClick={(e) => {}}
                  >
                    {dynamicColumns}
                  </DataTable>
                  <div className="preBookSlotNote">
                    {t("SLOT_PLANNING_NOTE")}
                  </div>
                  <div className="inboundSlotsToggles">
                    <Switch
                      className="preBookToggle"
                      label={t("SLOT_PLANNING_PREBOOK_TOGGLE")}
                      checked={inboundPreBookToggle}
                      onChange={handleReplanningPrebookCellBeforeForm}
                    />
                    <Switch
                      className="deleteToggle"
                      label={t("SLOT_PLANNING_DELETE_TOGGLE")}
                      checked={inboundDeleteToggle}
                      onChange={handleReplanningDeleteCell}
                    />

                    {isToday(activeDate) ? (
                      <Button
                        className="shiftingSlotButton"
                        iconPosition="right"
                        type="submit"
                        // disabled={shiftingSlotButton}
                        size="sm"
                        onClick={() => {
                          handleShiftingSlots();
                        }}
                      >
                        {t("SLOT_SHIFT_BUTTON")}
                        <ArrowForwardRoundedIcon
                          style={{ marginLeft: "10px", fontSize: "20px" }}
                        />
                      </Button>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="dataNotPlanned">
                  {t("SLOTS_NOT_PLANNED")}
                  <SentimentVeryDissatisfiedIcon
                    style={{ marginLeft: "5px" }}
                  />
                </div>
              )}
              {preBookModal && (
                <ReplanningPreBookSlotDetails
                  activeDate={activeDate}
                  closeModal={() => {
                    setPreBookModal(false);
                    // clearingSelection();
                  }}
                  uniqueId={uniqueId}
                  selectedCellData={selectedCellData}
                  inboundSlotsData={inboundSlotsData}
                  checkBoxFlag={checkBoxFlag}
                  checkBoxRowId={checkBoxRowId}
                  selectedFacilty={selectedFacilty}
                  getPrebookingSuccess={() => {
                    handleReplanningPrebookCell();
                  }}
                />
              )}
            </div>
          ) : null}
          {shiftingSlot ? (
            <ShiftingSlots
              closeModal={() => {
                setShiftingSlot(false);
              }}
              checkBoxRowId={checkBoxRowId}
              uniqueId={uniqueId}
              checkBoxFlag={checkBoxFlag}
              inboundSlotsData={inboundSlotsData}
              activeDate={activeDate}
              selectedCellData={selectedCellData}
              getShiftingSuccess={() => {
                handleShistSlots();
                clearingSelection();
              }}
            />
          ) : null}
        </div>
        {/* <div className="outboundSlots">
          <div className="outboundSlotTitleAndButton">
            <div className="outboundSlotTitle">{t("OUTBOUND_SLOTS")}</div>
            <button
              className="collapse"
              onClick={() => {
                setShowOutboundSlots(!showOutboundSlots);
              }}
            >
              {showOutboundSlots ? (
                <>
                  {t("COLLAPSE")}
                  <ExpandLessIcon />
                </>
              ) : (
                <>
                  {t("EXPAND")} <ExpandMoreIcon />
                </>
              )}
            </button>
          </div>
          {showOutboundSlots ? (
            <div className="outBoundTableAndSwitches"></div>
          ) : null}
        </div>
        <div className="addOutboundDockButtonDivision">
          <button className="addOutboundDockButton" onClick={() => {}}>
            {t("ADD_OUTBOUND_DOCK")}
            <AddIcon />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default SlotReplanning;
