import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useTranslation } from "react-i18next";
import { DATEFORMAT, DATETIMEFORMAT } from "../../config/constant";
import moment from "moment";
import RollingHeader from "../../components/Headers/RollingHeader";
import { _getRollingList } from "../../Services/rollingServices";
import { useDispatch } from "react-redux";
import { classNames } from "primereact/utils";
import FacilitySelectionModal from "./FacilitySelectionModal";
import endPoints from "../../Services/setting/endPoints";
import { typesOfPush } from "../../components/Notification/Websocket";
// import EventSource from "eventsource";
// import { Auth } from "aws-amplify";

let today = moment().format(DATEFORMAT);

const Rolling = () => {
  const cm = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [bookings, setBookings] = useState(null);
  const [timer, setTimer] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(2);
  const [facilityModal, setFacilityModal] = useState(true);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [refreshApi, setRefreshApi] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [keepActive, setKeepActive] = useState(true);

  // const getIdToken = async () => {
  //   let token = await Auth.currentSession().then((res) =>
  //     res.getIdToken().getJwtToken()
  //   );
  //   return token;
  // };

  // Notification handling
  useEffect(() => {
    let ws = null;
    if (selectedFacility) {
      ws = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}?facilities=${selectedFacility?.facilityId}`
      );

      // open connection
      ws.addEventListener("open", (e) => {
        console.log("Connection opened!");
        //   ws.send(userDetail);
      });

      // Listen for messages
      ws.addEventListener("message", (event) => {
        console.log("WS Message from server in rolling ", event.data);
        const data = JSON.parse(event.data);
        if (data?.typesOfPush === typesOfPush[0]) {
          // if same facility then refresh the screen
          console.log({ selectedFacility });
          console.log(data?.facilityId, "--", selectedFacility?.facilityId);
          if (data?.facilityId == selectedFacility?.facilityId)
            setRefreshApi(true);
        }
      });

      // When connection get closed
      ws.onclose = (e) => {
        console.log("WS close", e.code, e.reason);
        if (e.code === 1001) setKeepActive((keepActive) => !keepActive);
      };

      // Listen for error
      ws.onerror = (e) => {
        // an error occurred
        console.log("WS On error", e);
      };
    }

    return () => {
      // close on unmount
      if (ws) ws.close();
    };
  }, [selectedFacility, keepActive]);

  // use effect when first changes
  useEffect(() => {
    if (bookings?.length > 0) {
      if (first === 0 && refreshApi) {
        getRollingData();
        setBookings(null);
      } else {
        startTimer();
      }
    }
  }, [first]);

  // When booking change
  useEffect(() => {
    if (bookings?.length > 0) {
      startTimer();
    }
  }, [bookings]);

  useEffect(() => {
    if (pageCount === 1 && refreshApi) {
      getRollingData();
      setBookings(null);
    }
  }, [refreshApi]);

  useEffect(() => {
    if (selectedFacility) getRollingData();
  }, [selectedFacility]);

  const getRollingData = () => {
    dispatch(
      _getRollingList(
        selectedFacility?.facilityId,
        (res) => {
          setBookings(res.data);
          // if (res.data.length > 0) startTimer();
          setRefreshApi(false);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.shipmentStatus === "CHECKED_IN") {
      rowData.shipmentStatus = "Checked In";
    } else if (rowData.shipmentStatus === "BOOKED") {
      rowData.shipmentStatus = "Booked";
    } else if (rowData.shipmentStatus === "DEPARTED") {
      rowData.shipmentStatus = "Departed";
    } else if (rowData.shipmentStatus === "RELEASED") {
      rowData.shipmentStatus = "Released";
    } else if (rowData.shipmentStatus === "CANCELLED") {
      rowData.shipmentStatus = "Cancelled";
    } else if (rowData.shipmentStatus === "DELAYED") {
      rowData.shipmentStatus = "Delayed";
    } else if (rowData.shipmentStatus === "DOCK_UNAVAILABLE") {
      rowData.shipmentStatus = "Dock Unavailable";
    } else if (rowData.shipmentStatus === "CHECK_IN_DELAYED") {
      rowData.shipmentStatus = "Delayed Check In";
    }
    const statusClassName = classNames({
      rolling_booked: rowData.shipmentStatus === "Booked",
      departed: rowData.shipmentStatus === "Departed",
      delayed: rowData.shipmentStatus === "Delayed",
      checked_In: rowData.shipmentStatus === "Checked In",
      completed: rowData.shipmentStatus === "Released",
      cancelled: rowData.shipmentStatus === "Cancelled",
      dock_Unavailable: rowData.shipmentStatus === "Dock Unavailable",
      delayed_Check_In: rowData.shipmentStatus === "Delayed Check In",
    });
    return <div className={statusClassName}>{rowData.shipmentStatus}</div>;
  };

  function startTimer() {
    var date = moment(today).set({ hour: 0, minute: 0, second: 0 });
    const startTimestamp = moment(date).startOf("00:00");
    const length = bookings?.length;

    const totalPage = Math.ceil(length / rows);
    setPageCount(totalPage);
    let lastPageFirst = totalPage * rows - rows;
    const interval = setInterval(function () {
      startTimestamp.add(1, "second");
      setTimer(startTimestamp.format("mm:ss"));
      const tempFirst = first;
      if (startTimestamp.format("ss") === "10") {
        if (lastPageFirst === tempFirst) {
          setFirst(0);
        } else {
          if (length === rows) {
            setFirst(0);
          } else {
            setFirst(first + rows);
          }
        }
        clearInterval(interval);
      }
    }, 1000);
    return startTimestamp.format("HH:mm:ss");
  }

  const bookingTypeBodyTemplate = (rowData) => {
    if (rowData.bookingType === "CNTR") {
      rowData.bookingType = "Container";
    } else if (rowData.bookingType === "TRUCK") {
      rowData.bookingType = "Truck";
    } else if (rowData.bookingType === "SPCL_BKNG") {
      rowData.bookingType = "Special Booking";
    }
    return <div>{rowData.bookingType}</div>;
  };

  return (
    <div className="dashboard-page rolling_black">
      <RollingHeader />
      <div className="rollingDashboard-container  ">
        <div className="rolling_title_container">
          <h1 className="rolling_screen_title">{selectedFacility?.siteName}</h1>
          <div className="arrv">
            <img
              alt="Settings"
              src="/imgs/downArrow.png"
              className="arrow_down"
            />
            <span className="arriv_text" style={{ color: "white" }}>
              {t("ARRIVALS")}
            </span>
          </div>

          <progress value={timer?.substring(3, 5)} max="10">
            {timer?.substring(3, 5)}
          </progress>
        </div>
        <div className="rolling_screen">
          <DataTable
            className="rolling_table"
            value={bookings}
            stripedRows
            paginator={true}
            paginatorClassName="rolling_paginator"
            rows={rows}
            responsiveLayout="scroll"
            onPage={(e) => {
              setRows(e.rows);
            }}
            first={first}
          >
            <Column
              field="customerDetails.customerName"
              header={t("CUSTOMER")}
            />
            <Column field="PORef" header={t("PO_REF")} />
            <Column
              field="numberOfPallets"
              header={t("NUMBER_OF_PALLETS_ROLLING")}
            />
            <Column
              field="ETA"
              header={t("ETA")}
              body={(e) => {
                return (
                  <span>
                    {e?.ETA
                      ? moment(e?.ETA, DATETIMEFORMAT).format("HH:mm")
                      : "-"}
                  </span>
                );
              }}
            />
            <Column field="assignedDockNo" header={t("DOCK")} />
            {/* <Column field="bookingType" header={t("TYPE")} /> */}
            <Column
              field="bookingType"
              header={t("BOOKING_TYPE")}
              body={bookingTypeBodyTemplate}
            ></Column>
            <Column
              field="shipmentStatus"
              header={t("STATUS")}
              body={statusBodyTemplate}
            />
          </DataTable>
          {/* Select facility modal */}
          {facilityModal && (
            <FacilitySelectionModal
              getSelected={(e) => {
                setSelectedFacility(e);
                setFacilityModal(false);
              }}
              getRows={(r) => setRows(r)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Rolling;
