import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import { _getMDDriverList } from "../../Services/masterDataServices";

const status = ["ACTIVE", "INACTIVE"];

const DriverDetails = () => {
  const [bookings, setBookings] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.userDetail);

  useEffect(() => {
    getDriverDetails();
  }, []);

  const getDriverDetails = () => {
    dispatch(
      _getMDDriverList(
        { carrierId: userDetail?.user?.username },
        (res) => {
          setBookings(res.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder={t("SELECT_A_STATUS")}
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.status === "ACTIVE") {
      rowData.status = "Active";
    } else if (rowData.status === "INACTIVE") {
      rowData.status = "Inactive";
    }
    const statusClassName = classNames({
      in_active: rowData.status === "Inactive",
      active: rowData.status === "Active",
    });
    return <div className={statusClassName}>{rowData.status}</div>;
  };

  return (
    <div>
      <div className="box">
        <div>
          <DataTable
            value={bookings}
            stripedRows
            paginator={true}
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            rowHover
            responsiveLayout="scroll"
          >
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("DRIVER_ID")}
              field="driverId"
              header={t("DRIVER_ID")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("DRIVER_NAME")}
              field="driverName"
              header={t("DRIVER_NAME")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("LICENSE_NUMBER")}
              field="drivingLicenseNo"
              header={t("LICENSE_NUMBER")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("EMAIL")}
              field="driverEmail"
              header={t("EMAIL")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterPlaceholder={t("PHONE_NO")}
              field="driverPhNo"
              header={t("PHONE_NO")}
            ></Column>
            <Column
              filter
              showAddButton={false}
              showFilterOperator={false}
              showFilterMenuOptions={false}
              filterPlaceholder={t("STATUS")}
              field="status"
              header={t("STATUS")}
              body={statusBodyTemplate}
              filterElement={statusRowFilterTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default DriverDetails;
