export const showLoader = () => {
  return {
    type: "SHOW_LOADER",
  };
};

export const hideLoader = () => {
  return {
    type: "HIDE_LOADER",
  };
};

export const setLocalLanguage = (value) => {
  return {
    type: "SET_LOCAL",
    payload: value,
  };
};

export const saveEventSource = (value) => {
  return {
    type: "SET_EVENTSOURCE",
    payload: value,
  };
};

export const ClearReducer = () => {
  return {
    type: "USER_LOGOUT",
  };
};
