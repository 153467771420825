import React from "react";
import { Loading } from "@postidigital/posti-components";
import { useSelector } from "react-redux";

const Loader = () => {
  const isLoader = useSelector((state) => state.common.showLoader);
  return (
    <>
      {isLoader && (
        <div className="loader_page">
          <Loading preset="brandPetrol" size="xl" statusText="Loading..." />
        </div>
      )}
    </>
  );
};

export default Loader;
