// all apis related to master data would be here

import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

// get MasterData driver list
export const _getMDDriverList = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.masterDataDriverURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        console.log("Error" + err);
        dispatch(hideLoader());
        error(err);
      });
  };
};

// add MasterData driver
export const _addDriverData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.masterDataDriverURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// get MasterData carrier list
export const _getMDCarrierList = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.masterDataCarrierURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        console.log("Error" + err);
        dispatch(hideLoader());
        error(err);
      });
  };
};

// add MasterData carrier
export const _addCarrierData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.masterDataCarrierURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// modify MasterData carrier
export const _modifyCarrierData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.masterDataCarrierURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// get MasterData customer list
export const _getMDCustomerList = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.masterDataCustomerURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        console.log("Error" + err);
        dispatch(hideLoader());
        error(err);
      });
  };
};

// modify MasterData Customer
export const _modifyCustomerData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.masterDataCustomerURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// modify MasterData Driver
export const _modifyDriverData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.masterDataDriverURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// get MasterData facility list
export const _getMDFacilityList = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.masterDataFacilityURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        console.log("Error" + err);
        dispatch(hideLoader());
        error(err);
      });
  };
};

// add MasterData customer
export const _addCustomerData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.masterDataCustomerURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// add MasterData facility
export const _addFacilityData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.masterDataFacilityURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// modify MasterData facility
export const _modifyFacilityData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.masterDataFacilityURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// get MasterData facility dock list
export const _getMDDockList = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.masterDataFacilityDocURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        console.log("Error" + err);
        dispatch(hideLoader());
        error(err);
      });
  };
};

// add MasterData dock
export const _addDockData = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.masterDataFacilityDocURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// modify dock master data

export const _modifyDock = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.masterDataFacilityDocURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// Add Managers

export const _addManagers = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.manageManagersURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// Remove Managers

export const _removeManagers = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .delete(endPoints.manageManagersURL, { data })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// get auto generated user Id
export const _getAutoGeneratedUserId = (params, success, error) => {
  var urlString = "";
  if(params.userType === "customer"){
    urlString = endPoints.getCustomerAutoGeneratedUserId;
  }
  else if(params.userType === "carrier" || params.userType === "driver"){
    urlString = endPoints.getAutoGeneratedUserId;
  }
  instance
    .get(urlString, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};

export const _getUserGroups = (success, error)=>{
  instance
    .get(endPoints.getUserGroups)
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
}

//remove carriers

export const _removeUser = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    var urlString = "";
    if(params.userType === "customer"){
      urlString = endPoints.removeCustomers;
    }
    else if(params.userType === "carrier" || params.userType === "driver"){
      urlString = endPoints.removeCarriers;
    }
    instance
     .post(urlString, params)
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        console.log("ErrorTesss" + err);
        error(err);
        dispatch(hideLoader());
      });
  };
};
