// All Apis related to facilitya and dock will be here

import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

// get facility data
export const _getFacilityData = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
  instance
    .get(endPoints.facilityURL, { params })
    .then((res) => {
      dispatch(hideLoader());
      success(res);

    })
    .catch((err) => {
      dispatch(hideLoader());
      error(err);
    });
  }
} ;

// get dock by facility
export const _getDockByFacility = (params, success, error) => {
  instance
    .get(endPoints.facilityDockURL, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};

//modify facility data
export const _modifyFacilityBookings = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.slotBookingURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// get already planned slot dates
export const _getPlannedDatesByFacility = (params, success, error) => {
  instance
    .get(endPoints.alreadyPlannedDated, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};

// get upcoming Weeks
export const _getUpcomingWeeks = (params, success, error) => {
  instance
    .get(endPoints.getUpcomingWeeks, { params })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      error(err);
    });
};
