// All apis related to ware house manager will be here

import { hideLoader, showLoader } from "../Store/Actions/commonAction";
import { instance } from "./setting/axios";
import endPoints from "./setting/endPoints";

// Get Booking data
export const _getBookingsData = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.slotBookingURL, { params })
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// Modified Booking Data
export const _modifyBookings = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .put(endPoints.slotBookingURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

// Slot Planning
export const _slotPlanning = (data, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .post(endPoints.slotPlanningURL, data)
      .then((res) => {
        dispatch(hideLoader());
        success(res);
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};

//Checking status of week planing
export const _getStatusOfPlanningWeek = (params, success, error) => {
  return (dispatch) => {
    dispatch(showLoader());
    instance
      .get(endPoints.slotPlanningURL, { params })
      .then((res) => {
        success(res);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        error(err);
      });
  };
};
